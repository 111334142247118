<ng-container *ngIf='variableMap'>
  <ng-container *ngIf='workflowAdmin'>
    <shared-ui-information-element
      *ngIf="this.process.ended"
      [information]='getInformationElementProcessEnded()'
      type='date'
      format='dd.MM.yyyy HH:mm'
    ></shared-ui-information-element>
  </ng-container>

  <shared-ui-information-element
    [information]='getInformationElement(fields.reisenderFullname)'
  ></shared-ui-information-element>
  <shared-ui-information-element
    [information]='getInformationElement(fields.kostenubernahme_LABEL)'
  ></shared-ui-information-element>
  <shared-ui-information-element
    [information]='getInformationElement(fields.reiseziel)'></shared-ui-information-element>
  <shared-ui-information-element
    [information]='getInformationElement(fields.beginn_dienstreise)'
    type='date'
    format='dd.MM.yyyy'
  ></shared-ui-information-element>
  <shared-ui-information-element
    [information]='getInformationElement(fields.ende_dienstreise)'
    type='date'
    format='dd.MM.yyyy'
  ></shared-ui-information-element>

  <ng-container *ngIf='workflowAdmin'>
    <shared-ui-information-element [information]='getInformationElementZusaetzlich()'>
    </shared-ui-information-element>

    <shared-ui-information-element [information]='getInformationElementVorgesetzterFullname()'>
    </shared-ui-information-element>

    <shared-ui-information-element [information]='getInformationElementKostenverantwortlicher()'>
    </shared-ui-information-element>
  </ng-container>

  <ng-container
    *ngIf="workflowAdmin && variableMap.has(fields.status) && variableMap.get(fields.status) !== 'angenommen'">
    <ng-container *ngIf='variableMap.get(fields.aktuelleFreigabe) === fields.zusaetzlich'>
      <shared-ui-information-element
        [information]="{name: 'REISE-AUF.AKTUELLE_FREIGABE', value: 'REISE-AUF.ZUSATZFULLNAME'}">
        <span>({{getZusatzPersonFullname()}})</span>
      </shared-ui-information-element>
    </ng-container>

    <ng-container *ngIf='variableMap.get(fields.aktuelleFreigabe) === fields.vorgesetzterId'>
      <shared-ui-information-element
        [information]="{name: 'REISE-AUF.AKTUELLE_FREIGABE', value: 'REISE-AUF.VORGESETZTERFULLNAME'}">
        <span>({{getVorgesetzterFullname()}})</span>
      </shared-ui-information-element>
    </ng-container>

    <ng-container *ngIf='variableMap.get(fields.aktuelleFreigabe) === fields.kostenverantwortlicher'>
      <shared-ui-information-element
        [information]="{name: 'REISE-AUF.AKTUELLE_FREIGABE', value: 'REISE-AUF.KOSTENVERANTWORTLICHERFULLNAME'}">
        <span>({{getKostenverantwortlicherFullname()}})</span>
      </shared-ui-information-element>
    </ng-container>
  </ng-container>
</ng-container>

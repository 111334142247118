import { Environment, LogLevel } from '@alf-nx-workspace/shared/interfaces';

export const environment: Environment = {
  production: true,
  logLevel: LogLevel.error,
  hmr: false,
  groupAllowedToStartReiseauftragInRepresentation: 'workflow.reise-auf:process.start.any-staff',
  validateSessionTimer: 60000,
  googleApiKey: 'AIzaSyBjDf-hwLtXp29l_hib8BP0ujpuYB_0eqA',
  showBannerUntil: "2022-11-24 09:29",
  //check `make startup` for a way to specify a different file per deplyoment
  flowProcessConfig: "flowProcessConfig.json"
};

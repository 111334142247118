export enum MasterzulassungEinschreibungVariables {
  vorname = "vorname",
  nachname = "nachname",
  masterstudium = "masterstudium",
  matrikelnummer = "matrikelnummer"
}

export const MasterzulassungEinschreibungVariablesSearchable = [
  MasterzulassungEinschreibungVariables.vorname,
  MasterzulassungEinschreibungVariables.nachname,
  MasterzulassungEinschreibungVariables.masterstudium,
  MasterzulassungEinschreibungVariables.matrikelnummer
]

export enum UrlaubsfreigabeDelegierenVariables {
  "multiSelectUsers"= "multiSelectUsers",
  "urlaubsGenehmigendePerson"= "urlaubsGenehmigendePerson",
  "urlaubsgenehmigerStellvertretungFullname"= "urlaubsgenehmigerStellvertretungFullname",
  "genehmigendePersonFullname"= "genehmigendePersonFullname",
  "urlaubsGenehmigendePersonHatKeineStellvertreter" = "urlaubsGenehmigendePersonHatKeineStellvertreter",
  "urlaubsgenehmigerStellvertretung" = "urlaubsgenehmigerStellvertretung",
  "urlaubsantragstellendePersonenNameList" = "urlaubsantragstellendePersonenNameList"
}

export const UrlaubsfreigabeDelegierenVariablesSearchable = [
  UrlaubsfreigabeDelegierenVariables.genehmigendePersonFullname,
  UrlaubsfreigabeDelegierenVariables.urlaubsgenehmigerStellvertretungFullname,
  UrlaubsfreigabeDelegierenVariables.urlaubsantragstellendePersonenNameList
]

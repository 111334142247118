import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { GroupRepresentation, UserRepresentation } from '@alfresco/js-api';
import { ClearAuthState, LoadUser } from './auth.actions';
import { EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { AuthService } from './auth.service';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export interface AuthStateModel {
  user: UserRepresentation;
  username: string;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    user: null,
    username: ''
  }
})
@Injectable()
export class AuthState {

  @Selector()
  public static user(state: AuthStateModel): UserRepresentation {
    return state.user;
  }

  @Selector()
  public static groups(state: AuthStateModel): GroupRepresentation[] {
    return state.user?.groups;
  }

  @Selector()
  public static groupNames(state: AuthStateModel): string {
    return state.user?.groups.map((group: GroupRepresentation) => group.name).join('\r\n');
  }

  @Selector()
  public static username(state: AuthStateModel): string {
    return state.username;
  }

  @Selector()
  public static fullname(state: AuthStateModel): string {
    return state.user.fullname;
  }

  static hasGroupPermission(groups: string[]) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      if (groups === null || groups === undefined || groups.length === 0) {
        return true;
      }
      if (state.user) {
        return groups.some((group: string) => {
          if (!state.user.groups) return false;
          return state.user.groups.some((groupRepresentation: GroupRepresentation) => {
            // found wildcard in group
            if (group.startsWith('%') && group.endsWith('%')) {
              return new RegExp(group.replace(/%/g, ''), 'g').test(groupRepresentation.name);
            }
            return groupRepresentation.name === group;
          });
        });
      }
      return false;
    });
  }

  static isTaskAssignee(taskDetail: EbenTaskDetail) {
    return createSelector([AuthState], (state: AuthStateModel) => {
      return state.user.id === taskDetail.assignee.id;
    });
  }

  static extractOrgEhViewAnyKennungen() {
    const GROUP_ORGEH_VIEW_ANY_PATTERN = /orgeh.(.+):process.view-any/;
    return createSelector([AuthState], (state: AuthStateModel) => {
      if(state.user?.groups) {
        const groups = state.user.groups
          .filter((group: GroupRepresentation) => GROUP_ORGEH_VIEW_ANY_PATTERN.test(group.name))
          .map((group: GroupRepresentation) => {
            const match = group.name.match(GROUP_ORGEH_VIEW_ANY_PATTERN);
            return match[1];
          });
        return [...new Set(groups)]
      } else {
        return [];
      }
    });
  }

  constructor(public authService: AuthService) {
  }

  @Action(LoadUser)
  protected loadUser(ctx: StateContext<AuthStateModel>, action: LoadUser): Observable<UserRepresentation> {
    return this.authService.loadUser().pipe(
      tap((user: UserRepresentation) => {
          ctx.patchState({
            user: user
          });
        }
      )
    );
  }

  @Action(ClearAuthState)
  protected clearAuthState(ctx: StateContext<AuthStateModel>, action: ClearAuthState) {
    ctx.patchState({
      user: null,
      username: ''
    });
  }
}

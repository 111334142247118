import { Component, inject } from '@angular/core';
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { TranslateModule } from "@ngx-translate/core";
import { RxState, selectSlice } from "@rx-angular/state";
import { RelatedContentRepresentation } from "@alfresco/js-api/typings/src/api/activiti-rest-api/model/relatedContentRepresentation";
import { AsyncPipe, DatePipe, NgIf } from "@angular/common";
import { WorkflowApprover } from "@alf-nx-workspace/flow/interfaces";

interface ResultTemplateComponentState {
  variableMap: Map<string, string>,
  workflowResult: string,
  approved: WorkflowApprover[],
  rejectedBy: string,
  rejectedReason: string,
  contentId: number,
  filename: string,
  relatedContent: RelatedContentRepresentation,
  ausgestelltAm: Date
}

@Component({
  selector: 'masterzulassung-einschreibung-result-template',
  template: `
    <ng-container *ngIf="vm$ | async as vm">
      <ng-container *ngIf="vm.workflowResult === workflowResults.successful">
      </ng-container>
    </ng-container>
  `,
  styles: [``],
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe,
    NgIf,
    DatePipe
  ]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {

  readonly rxState = inject<RxState<ResultTemplateComponentState>>(RxState);
  readonly vm$ = this.rxState.select(selectSlice(["workflowResult"]));

  ngOnInit() {
    this.connectState();
  }

  private connectState() {
    this.rxState.connect("workflowResult", this.workflowResultSubject);
  }
}

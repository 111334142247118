import { Component, inject, Injectable, TemplateRef } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { Dialog, DialogConfig, DialogRef } from "@angular/cdk/dialog";

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  readonly dialog = inject(Dialog);
  readonly overlay = inject(Overlay);

  private dialogRef: DialogRef<TemplateRef<Component>>;
  private isDialogOpen = false;

  openDialog(element: HTMLElement, dialogTemplate: TemplateRef<Component>) {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true;
      const config = new DialogConfig();
      config.positionStrategy = this.overlay
        .position()
        .flexibleConnectedTo(element)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetX: 0,
            offsetY: 0
          }
        ]);
      config.hasBackdrop = true;
      config.backdropClass = 'cdk-overlay-transparent-backdrop';
      config.scrollStrategy = this.overlay.scrollStrategies.block();
      config.panelClass = 'dialog-panel-class';
      this.dialogRef = this.dialog.open(dialogTemplate, config);
      this.dialogRef.closed.subscribe(() => {
        this.isDialogOpen = false;
      });
      this.isDialogOpen = true;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}

import { TranslateLoaderService } from "@alfresco/adf-core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

/***
 * Transform Translations from {"BUTTON": {"OK": "passt"}} to {"BUTTON.OK": "passt"}
 */
export class UnnestingTranslateLoaderService extends TranslateLoaderService {
  getTranslation(lang: string): Observable<{[key: string]: string}> {
    return super.getTranslation(lang).pipe(
      map(flattenObj)
    );
  }
}


const flattenObj = (ob) => {

  // The object which contains the
  // final result
  const result = {};

  // loop through the object "ob"
  for (const i in ob) {

    // We check the type of the i using
    // typeof() function and recursively
    // call the function again
    if ((typeof ob[i]) === 'object' && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {

        // Store temp in result
        result[i + '.' + j] = temp[j];
      }
    }

    // Else store ob[i] in result directly
    else {
      result[i] = ob[i];
    }
  }
  return result;
};

export enum SnackbarType {
  'success' = 'success',
  'error' = 'error',
}

export class SnackbarDataModel {
  type: SnackbarType;
  message: string;
  action: string;
}

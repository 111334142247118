import {QueryBody, TaskQueryRepresentation} from '@alfresco/js-api';

export type EbenQueryBody = QueryBody

export const defaultEbenQueryBody: EbenQueryBody = {
  query: {
    language: 'afts',
    query: '',
  },
  paging: {
    maxItems: 5,
    skipCount: 0
  }
}

import { NgModule } from '@angular/core';
import { FORM_PROCESSOR_MAPPING, WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING } from '@alf-nx-workspace/shared/utils';

import { ReiseAufService } from './reise-auf.service';
import { ReiseAufStartformProcessor } from './form-processors/reise-auf.startform.processor';
import { ReiseAufGrunddatenProcessor } from './form-processors/reise-auf.grunddaten.processor';
import { ReiseAufA1Processor } from './form-processors/reise-auf.a1.processor';
import { ReiseAufFreigabeVorgesetzterProcessor } from './form-processors/reise-auf.freigabe-vorgesetzter.processor';
import { ReiseAufFreigabeReisenderProcessor } from './form-processors/reise-auf.freigabe-reisender.processor';
import { ReiseAufFreigabeProcessor } from './form-processors/reise-auf.freigabe.processor';
import { ReiseAufWeiterleitenProcessor } from './form-processors/reise-auf.weiterleiten.processor';
import { ReiseAufVariablesSearchable } from "./reise-auf.variables";

@NgModule({
  providers: [
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufStartformProcessor, multi: true},
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufGrunddatenProcessor, multi: true},
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufA1Processor, multi: true},
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufFreigabeVorgesetzterProcessor, multi: true},
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufFreigabeReisenderProcessor, multi: true},
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufFreigabeProcessor, multi: true},
    {provide: FORM_PROCESSOR_MAPPING, useValue: ReiseAufWeiterleitenProcessor, multi: true},
    {provide: WORKFLOW_VARIABLE_MAPPING, useValue: ReiseAufVariablesSearchable, multi: true},
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: ReiseAufService, multi: true},
  ],
})
export class ReiseAufModule {
}

import { Component } from '@angular/core';
import { BaseResultTemplateComponent } from "../../base-result-template.component";


@Component({
  selector: 'urlaubsfreigabe-delegieren-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.css'],
  standalone: true,
  imports: [ ]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {

}

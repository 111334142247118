<ng-container *ngIf="information && information.value">
  <span class="name mat-caption" title="{{information.name | translate}}">{{ information.name | translate }}</span>
  <span class="value mat-caption" [ngSwitch]="type">
    <span *ngSwitchCase="'boolean'" class="text">{{ (information.value ? 'INFORMATION_ELEMENT_YES' : 'INFORMATION_ELEMENT_NO') | translate }}</span>
    <span *ngSwitchCase="'text'" class="text">{{ information.value | translate }}</span>
    <span *ngSwitchCase="'date'" class="date">{{ information.value | date: format }}</span>
    <span *ngSwitchCase="'raw'" class="raw">{{ information.value }}</span>
    <span *ngSwitchDefault>{{ information.value }}</span>
  </span>
</ng-container>

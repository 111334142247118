import { FormFieldModel } from '@alfresco/adf-core';
import { Injectable } from '@angular/core';
import { BaseProcessor } from '../../base.processor';
import { UrlaubsfreigabeDelegierenFreigabeVariables } from "../urlaubsfreigabe-delegieren-freigabe.variables";

enum FIELDS { }

@Injectable({ providedIn: 'root' })
export class UrlaubsfreigabeDelegierenFreigabeZuordnungbestaetigenProcesser extends BaseProcessor {
  static FORM_NAME = 'urlaubsfreigabe-delegieren_zuordnungbestaetigen';
  public readonly FORM_NAME = UrlaubsfreigabeDelegierenFreigabeZuordnungbestaetigenProcesser.FORM_NAME;

  public onFieldValueChange(field: FormFieldModel) {
    if (field.id === UrlaubsfreigabeDelegierenFreigabeVariables.isOhneStellvertreter) {
      field.form.validateForm();
    }
  }
}

<div class="flow-list-item header">
  <div class="icon"></div>
  <span class="mat-caption">{{ 'FLOW_LIST.HEADER_WORKFLOW' | translate }}</span>
  <span class="mat-caption">{{ 'FLOW_LIST.HEADER_INFORMATION' | translate }}</span>
  <span class="mat-caption">{{ 'FLOW_LIST.HEADER_TASKS' | translate }}</span>
  <div class="filter">
    <div>
      <span class="mat-caption">{{ 'FLOW_LIST.HEADER_ASSIGNMENT' | translate }}</span>
      <div class="selected-filter" *ngIf="assignment">
        <span class="filter">{{ "HEADER_ASSIGNMENT." + assignment | uppercase | translate }}</span>
        <shared-ui-flow-icon invert="1" icon="cancel.svg" size="xsmall" hoverable="true"
                             (click)="onQueryChipRemove('assignment')"></shared-ui-flow-icon>
      </div>
    </div>
    <button class="filter_list" mat-icon-button [matMenuTriggerFor]="assigneeMenu">
      <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #assigneeMenu="matMenu">
      <button mat-menu-item (click)="onPropertyChange('assignment', AssigneeStates.ASSIGNEE)">
        <span>{{ 'HEADER_ASSIGNMENT.' + AssigneeStates.ASSIGNEE | uppercase | translate }}</span>
      </button>
      <button mat-menu-item (click)="onPropertyChange('assignment', AssigneeStates.CANDIDATE_UNASSIGNED)">
        <span>{{ 'HEADER_ASSIGNMENT.' + AssigneeStates.CANDIDATE_UNASSIGNED | uppercase | translate }}</span>
      </button>
      <button mat-menu-item (click)="onPropertyChange('assignment', AssigneeStates.CANDIDATE_ASSIGNED)">
        <span>{{ 'HEADER_ASSIGNMENT.' + AssigneeStates.CANDIDATE_ASSIGNED | uppercase | translate }}</span>
      </button>
    </mat-menu>
  </div>

</div>
<mat-progress-bar *ngIf="tasks.pending" mode="indeterminate"></mat-progress-bar>
<ng-container>
  <shared-ui-list-no-data noDataMessage="TASKS.NO_DATA_FOUND"
                          *ngIf="!tasks.pending && !tasks.data?.length"></shared-ui-list-no-data>
  <div class="flow-list-item" *rxFor="let task of tasks.data; trackBy: trackItem">
    <shared-ui-process-definition-icon [processDefinitionKey]="task.processDefinitionKey"
    ></shared-ui-process-definition-icon>
    <div class='title'>
      <shared-ui-process-name
        [process]="task.processInstanceRepresentation">
      </shared-ui-process-name>
      <shared-ui-process-priority
        [process]="task.processInstanceRepresentation"
        [task]="task"
      ></shared-ui-process-priority>
    </div>
    <shared-ui-process-information [process]="task.processInstanceRepresentation"></shared-ui-process-information>
    <div class="task">
      <shared-ui-task [task]="task"></shared-ui-task>
      <shared-ui-process-documents
        [processInstance]="task.processInstanceRepresentation"></shared-ui-process-documents>
    </div>
    <shared-ui-flow-icon *ngIf="task.assignee"
                         [icon]="task.assignee.id == user.id ? 'assignment_assignee.svg' : 'assignment_candidate_assigned.svg'"
                         size="normal">
    </shared-ui-flow-icon>
    <a class="flow-list-item-primary-action" [routerLink]="['', { outlets: { dialog: ['task-form', task.id] } }]"
       queryParamsHandling="preserve">
      <mat-icon class="primary-action-icon">keyboard_arrow_right</mat-icon>
    </a>
  </div>

</ng-container>

<mat-paginator
  (page)="onPageChange($event)"
  [length]="tasks.total"
  [pageIndex]="page.pageIndex"
  [pageSizeOptions]="[5, 10, 25, 100]"
  [pageSize]="page.pageSize"
  aria-label="Select page">
</mat-paginator>

import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { inject, Injectable, isDevMode } from '@angular/core';
import { FlowProcessDefinitionModel, PropertyOptionFilterModel, RouterStateParams } from '@alf-nx-workspace/shared/interfaces';
import {
  GetAdminWorkflows,
  GetAdminWorkflowsDefinitionKeys,
  GetFlowProcessDefinitions,
  GetMyDocuments,
  GetMyTasks,
  GetMyTasksTotal,
  GetMyWorkflows,
  GetMyWorkflowsDefinitionKeys,
  GetOrgEhWorkflows,
  GetOrgEhWorkflowsDefinitionKeys,
  GetWorkflowDocuments,
  ReloadScope,
  SetLanguage,
  SetShowBanner,
} from './flow.actions';
import { FlowService } from './flow.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ProcessService, TaskQueryRequestRepresentationModel } from '@alfresco/adf-process-services';
import {
  EbenPagination,
  EbenProcessInstance,
  EbenProcessInstanceQueryRepresentation,
  EbenResultSetPaging,
  EbenTaskDetail,
  EbenTaskQueryRepresentation,
  EMPTY_EBEN_PAGINATION,
  EMPTY_EBEN_RESULT_SET_PAGING,
} from '@alf-nx-workspace/eben/interfaces';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { BpmService, SnackbarService } from '@alf-nx-workspace/shared/utils';
import { AppDefinitionRepresentation, ProcessDefinitionRepresentation, QueryBody, ResultSetPaging } from '@alfresco/js-api';
import { SearchFacetFiltersService, SearchQueryBuilderService } from '@alfresco/adf-content-services';
import { RouterState, RouterStateModel } from '@ngxs/router-plugin';
import { FLOW_MY_TASKS_ROOT, FLOW_MY_WORKFLOWS_ROOT, FLOW_OE_WORKFLOWS_ROOT, FLOW_WORKFLOW_ADMIN_ROOT, } from '../routing-constants';
import { AppsProcessService } from "@alfresco/adf-core";
import { QueryError } from "@alf-nx-workspace/shared/utils";
import { RouterStateSnapshot } from "@angular/router";


export interface FlowStateModel {
  language: string;
  showBanner: boolean;
  flowDefinitions: FlowProcessDefinitionModel[];

  workflowStates: PropertyOptionFilterModel[];

  myWorkflows: EbenPagination<EbenProcessInstance>;
  myWorkflowsQuery: EbenProcessInstanceQueryRepresentation;
  myWorkflowsDefinitionKeys: string[];

  orgEhWorkflows: EbenPagination<EbenProcessInstance>;
  orgEhWorkflowQuery: EbenProcessInstanceQueryRepresentation;
  orgEhWorkflowDefinitionKeys: string[];

  adminWorkflows: EbenPagination<EbenProcessInstance>;
  adminWorkflowsQuery: EbenProcessInstanceQueryRepresentation;
  adminWorkflowsDefinitionKeys: string[];

  myTasks: EbenPagination<EbenTaskDetail>;
  myTasksQuery: EbenTaskQueryRepresentation;

  workflowDocuments: EbenResultSetPaging;

  myDocuments: EbenResultSetPaging;
  myDocumentsQuery: QueryBody;
  taskTotal: number;
}

@State<FlowStateModel>({
  name: 'flow',
  defaults: {
    language: '',
    showBanner: false,
    flowDefinitions: [],

    myTasks: {...EMPTY_EBEN_PAGINATION},
    myTasksQuery: null,

    myWorkflows: {...EMPTY_EBEN_PAGINATION},
    myWorkflowsQuery: null,
    myWorkflowsDefinitionKeys: [],

    orgEhWorkflows: {...EMPTY_EBEN_RESULT_SET_PAGING},
    orgEhWorkflowQuery: null,
    orgEhWorkflowDefinitionKeys: [],

    adminWorkflows: {...EMPTY_EBEN_PAGINATION},
    adminWorkflowsQuery: null,
    adminWorkflowsDefinitionKeys: [],

    workflowDocuments: null,

    myDocuments: {...EMPTY_EBEN_RESULT_SET_PAGING},
    myDocumentsQuery: null,

    taskTotal: 0,
  } as FlowStateModel,
})
@Injectable()
export class FlowState {

  readonly appsProcessService = inject(AppsProcessService);
  readonly store = inject(Store)
  readonly flowService = inject(FlowService)
  readonly processService = inject(ProcessService)

  readonly bpmService = inject(BpmService)
  readonly searchBuilderQueryService = inject(SearchQueryBuilderService)
  readonly searchFacetFiltersService = inject(SearchFacetFiltersService)

  @Selector()
  public static language(state: FlowStateModel): string {
    return state.language;
  }

  @Selector()
  public static showBanner(state: FlowStateModel): boolean {
    return state.showBanner;
  }

  @Selector()
  public static flowProcessDefinitions(state: FlowStateModel): FlowProcessDefinitionModel[] {
    return state.flowDefinitions;
  }

  /**
   * The InformationDialog provides more detailed information about a workflow
   * In addition you have the possibility to switch to the previous and next workflow
   * so we need the scoped workflows to know in which area we are to find out which workflow
   * is the correct workflow to go to
   * @param state
   * @param router
   */
  @Selector([RouterState])
  public static scopeWorkflows(state: FlowStateModel, router: {state: RouterStateParams}): EbenPagination<EbenProcessInstance> {
    switch (router.state.flowRoute) {
      case FLOW_WORKFLOW_ADMIN_ROOT:
        return state.adminWorkflows;
      case FLOW_OE_WORKFLOWS_ROOT:
        return state.orgEhWorkflows;
      case FLOW_MY_WORKFLOWS_ROOT:
        return state.myWorkflows;
      default:
        return null;
    }
  }

  @Selector()
  public static myTasks(state: FlowStateModel): EbenPagination<EbenTaskDetail> {
    return state.myTasks;
  }

  @Selector()
  public static myWorkflowsQuery(state: FlowStateModel): EbenPagination<EbenProcessInstance> {
    return state.myWorkflowsQuery;
  }

  @Selector()
  public static myWorkflows(state: FlowStateModel): EbenPagination<EbenProcessInstance> {
    return state.myWorkflows;
  }

  @Selector()
  public static myWorkflowsDefinitionKeys(state: FlowStateModel): string[] {
    return state.myWorkflowsDefinitionKeys;
  }

  @Selector()
  public static orgEhWorkflows(state: FlowStateModel): EbenPagination<EbenProcessInstance> {
    return state.orgEhWorkflows;
  }

  @Selector()
  public static orgEhWorkflowsDefinitionKeys(state: FlowStateModel): string[] {
    return state.orgEhWorkflowDefinitionKeys;
  }

  @Selector()
  public static adminWorkflows(state: FlowStateModel): EbenPagination<EbenProcessInstance> {
    return state.adminWorkflows;
  }

  @Selector()
  public static adminWorkflowsDefinitionKeys(state: FlowStateModel): string[] {
    return state.adminWorkflowsDefinitionKeys;
  }

  @Selector()
  public static workflowDocuments(state: FlowStateModel): EbenResultSetPaging {
    return state.workflowDocuments;
  }

  static workflowDocumentsByProcessInstanceIds(processInstanceIds: string[]) {
    return createSelector([FlowState], (state: FlowStateModel) => {
      return state.workflowDocuments.list.entries.filter((entry) => {
        if (!entry.entry || !entry.entry.properties) {
          return null;
        }
        return processInstanceIds.indexOf(entry.entry.properties['boku:processInstanceId']) > -1;
      });
    });
  }

  @Selector()
  public static myDocuments(state: FlowStateModel): EbenResultSetPaging {
    return state.myDocuments;
  }

  @Selector()
  public static myTasksTotal(state: FlowStateModel): number {
    return state.taskTotal ? state.taskTotal : 0;
  }

  @Selector()
  static myTasksBadge(state: FlowStateModel): string {
    return state.taskTotal.toString();
  }

  static flowDefinitionByKey(key: string) {
    return createSelector([FlowState], (state: FlowStateModel) => {
      return state.flowDefinitions.find((flowProcessDefinition) => flowProcessDefinition.processDefinitionKey === key);
    });
  }

  @Action(SetLanguage)
  protected setLanguage(ctx: StateContext<FlowStateModel>, {language}: SetLanguage) {
    return ctx.patchState({language});
  }

  @Action(SetShowBanner)
  protected setBannerDismissed(ctx: StateContext<FlowStateModel>, {showBanner}: SetShowBanner) {
    return ctx.patchState({showBanner});
  }

  @Action(GetFlowProcessDefinitions)
  protected loadFlowProcessDefinitions(
    ctx: StateContext<FlowStateModel>,
    action: GetFlowProcessDefinitions
  ): Observable<FlowProcessDefinitionModel[]> {
    return combineLatest([
      this.appsProcessService.getDeployedApplications(),
      this.processService.getProcessDefinitions(),
      this.flowService.getFlowProcessDefinitions()
    ])
      .pipe(
        map(([applications, processDefinitions, flowProcessDefinitions]) => {
          const flowDefinitions: FlowProcessDefinitionModel[] = [];

          flowProcessDefinitions.forEach((flowDefinition: FlowProcessDefinitionModel) => {
            const processDefinition = processDefinitions.find((p: ProcessDefinitionRepresentation) => p.key === flowDefinition.processDefinitionKey);
            if (processDefinition) {
              const application = applications.find((a: AppDefinitionRepresentation) => a.deploymentId === processDefinition.deploymentId);
              flowDefinitions.push({
                ...flowDefinition,
                appId: application.id,
                appName: application.name,
                processDefinitionKey: processDefinition.key,
                processDefinitionId: processDefinition.id,
                hasStartForm: processDefinition.hasStartForm,
                isDeployedInAps: true
              });
            } else {
              flowDefinitions.push({
                ...flowDefinition,
                canBeStarted: isDevMode(),
                isDeployedInAps: isDevMode(),
                hasNoProcessDefinition: true,
                hasNoFlowProcessDefinition: false
              });
            }
          })

          if (isDevMode()) {
            processDefinitions.forEach((processDefinition: ProcessDefinitionRepresentation) => {
              const application = applications.find((a: AppDefinitionRepresentation) => a.deploymentId === processDefinition.deploymentId);
              const flowDefinition = flowDefinitions.find((f: FlowProcessDefinitionModel) => f.processDefinitionKey === processDefinition.key);
              if (!flowDefinition) {
                flowDefinitions.push({
                  ...processDefinition,
                  appId: application.id,
                  appName: application.name,
                  processDefinitionKey: processDefinition.key,
                  processDefinitionId: processDefinition.id,
                  hasStartForm: processDefinition.hasStartForm,
                  icon: 'workflow-no-definition.svg',
                  backgroundColorRGB: '255, 96, 0',
                  isDeployedInAps: true,
                  canBeStarted: true,
                  hasNoProcessDefinition: false,
                  hasNoFlowProcessDefinition: true
                })
              }
            })
          }

          return flowDefinitions;
        }),
        tap((flowProcessDefinitions: FlowProcessDefinitionModel[]) => {
          ctx.patchState({
            ...ctx.getState(),
            flowDefinitions: flowProcessDefinitions,
          });
        })
      )
  }

  @Action(GetMyTasks, { cancelUncompleted: true })
  protected loadMyTasks(ctx: StateContext<FlowStateModel>, action: GetMyTasks): Observable<EbenPagination<EbenTaskDetail>> {
    if (action.query) {
      ctx.patchState({
        myTasksQuery: action.query,
      });
    }
    ctx.patchState({
      myTasks: {...ctx.getState().myTasks, pending: true},
    });
    return this.bpmService.queryTasks(ctx.getState().myTasksQuery).pipe(
      tap((list: EbenPagination<EbenTaskDetail>) => {
        ctx.patchState({
          myTasks: list,
        });
      }),
      catchError((error) => {
        ctx.patchState({myTasks: null});
          return throwError(error);
        })
      )
  }

  @Action(GetMyWorkflows, { cancelUncompleted: true })
  protected loadMyWorkflows(ctx: StateContext<FlowStateModel>, action: GetMyWorkflows) {
    if (action.query) {
      ctx.patchState({
        myWorkflowsQuery: action.query,
      });
    }

    ctx.patchState({
      myWorkflows: {...ctx.getState().myWorkflows, pending: true},
    });
    return this.bpmService.loadMyWorkflows(ctx.getState().myWorkflowsQuery).pipe(
      tap((list: EbenPagination<EbenProcessInstance>) => {
        ctx.patchState({
          myWorkflows: list,
        });
        this.store.dispatch(new GetWorkflowDocuments(list.data));
      }),
      catchError((error) => {
        ctx.patchState({myWorkflows: null});
        return throwError(error);
      })
    );
  }

  @Action(GetMyWorkflowsDefinitionKeys, { cancelUncompleted: true })
  protected loadMyWorkflowsNames(ctx: StateContext<FlowStateModel>) {
    return this.bpmService.loadMyWorkflowsNames().pipe(
      tap((result: string[]) => {
        ctx.patchState({
          myWorkflowsDefinitionKeys: result,
        });
      }),
      catchError((error) => {
        ctx.patchState({myWorkflowsDefinitionKeys: []});
        return throwError(error);
      })
    );
  }

  @Action(GetOrgEhWorkflows, { cancelUncompleted: true })
  protected loadOrgEhWorkflows(ctx: StateContext<FlowStateModel>, action: GetOrgEhWorkflows) {
    if (action.query) {
      ctx.patchState({
        orgEhWorkflowQuery: action.query,
      });
    }
    ctx.patchState({
      orgEhWorkflows: {
        ...ctx.getState().orgEhWorkflows,
        pending: true,
      },
    });
    return this.bpmService.loadOrgEhWorkflows(ctx.getState().orgEhWorkflowQuery).pipe(
      tap((list: EbenPagination<EbenProcessInstance>) => {
        ctx.patchState({
          orgEhWorkflows: list,
        });
        this.store.dispatch(new GetWorkflowDocuments(list.data));
      }),
      catchError((error) => {
        ctx.patchState({orgEhWorkflows: null});
        return throwError(error);
      })
    );
  }

  @Action(GetOrgEhWorkflowsDefinitionKeys, { cancelUncompleted: true })
  protected loadOrgEhWorkflowsNames(ctx: StateContext<FlowStateModel>) {
    return this.bpmService.loadOrgEhWorkflowsNames().pipe(
      tap((result: string[]) => {
        ctx.patchState({
          orgEhWorkflowDefinitionKeys: result,
        });
      }),
      catchError((error) => {
        ctx.patchState({orgEhWorkflowDefinitionKeys: null});
        return throwError(error);
      })
    );
  }

  @Action(GetAdminWorkflows, { cancelUncompleted: true })
  protected loadAdminWorkflows(ctx: StateContext<FlowStateModel>, action: GetAdminWorkflows) {
    if (action.query) {
      ctx.patchState({
        adminWorkflowsQuery: action.query,
      });
    }
    ctx.patchState({
      adminWorkflows: {
        ...ctx.getState().adminWorkflows,
        pending: true,
      },
    });
    return this.bpmService.loadAdminWorkflows(ctx.getState().adminWorkflowsQuery).pipe(
      tap((list: EbenPagination<EbenProcessInstance>) => {
        ctx.patchState({
          adminWorkflows: list,
        });
        this.store.dispatch(new GetWorkflowDocuments(list.data));
      }),
      catchError((error) => {
        ctx.patchState({adminWorkflows: null});
        return throwError(error);
      })
    );
  }

  @Action(GetAdminWorkflowsDefinitionKeys, { cancelUncompleted: true })
  protected loadAdminWorkflowsNames(ctx: StateContext<FlowStateModel>) {
    return this.bpmService.loadAdminWorkflowsNames().pipe(
      tap((result: string[]) => {
        ctx.patchState({
          adminWorkflowsDefinitionKeys: result,
        });
      }),
      catchError((error) => {
        ctx.patchState({adminWorkflowsDefinitionKeys: null});
        return throwError(error);
      })
    );
  }

  @Action(GetWorkflowDocuments, { cancelUncompleted: true })
  protected getWorkflowDocuments(ctx: StateContext<FlowStateModel>, action: GetWorkflowDocuments) {

    if (action.processInstanceIds.length === 0) {
      return;
    }

    const flowDefinitionQueries: string = ctx
      .getState()
      .flowDefinitions.filter(
        (flowProcessDefinition) => flowProcessDefinition.acsQuery && flowProcessDefinition.acsQuery.documentType
      )
      .map((flowProcessDefinition) => flowProcessDefinition.acsQuery.documentType)
      .join(' OR ');

    const workflowIdsQuery: string = action.processInstanceIds
      .map((processInstanceId: string) => `boku:processInstanceId:${processInstanceId}`)
      .join(' OR ');

    const charsToEscape = /[-]/g;
    const query = `(${flowDefinitionQueries}) AND (${workflowIdsQuery})`.replace(charsToEscape, '\\$&');

    return this.searchBuilderQueryService
      .search({
        query: {query},
        include: ['properties', 'aspectNames', 'path'],
        sort: [{type: 'FIELD', field: 'cm:created', ascending: false}],
      })
      .pipe(
        tap((documents: EbenResultSetPaging) => {
          ctx.patchState({
            workflowDocuments: documents,
          });
        }), catchError((error) => {
          const ebenError = QueryError.createFromError(error, 'ACS', ctx.getState().myDocumentsQuery);
          return throwError(ebenError);
        })
      );
  }

  @Action(GetMyDocuments, { cancelUncompleted: true })
  protected loadMyDocuments(ctx: StateContext<FlowStateModel>, action: GetMyDocuments) {
    if (action.query) {
      // since we have properties with the - char we need to escape it
      const charsToEscape = /[-]/g;
      const query = {
        ...action.query,
        query: {
          ...action.query.query,
          query: action.query.query.query.replace(charsToEscape, '\\-')
        }
      }
      ctx.patchState({
        myDocumentsQuery: query,
      });
    }
    ctx.patchState({
      myDocuments: {list: ctx.getState().myDocuments.list, pending: true},
    });

    return this.searchBuilderQueryService.search(ctx.getState().myDocumentsQuery).pipe(
      tap((result: ResultSetPaging) => {
        this.searchFacetFiltersService.onDataLoaded(result);
        ctx.patchState({
          myDocuments: result,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          myDocuments: new ResultSetPaging(),
        });
        const ebenError: unknown = QueryError.createFromError(error, 'ACS', ctx.getState().myDocumentsQuery);
        return throwError(ebenError);
      })
    );
  }

  @Action(GetMyTasksTotal, { cancelUncompleted: true })
  protected loadAssignedTaskTotals(ctx: StateContext<FlowStateModel>, action: GetMyTasksTotal) {
    const taskQuery: TaskQueryRequestRepresentationModel = {
      assignment: 'assignee',
      state: 'open'
    };
    return this.flowService.getTaskTotal(taskQuery).pipe(
      tap((tasks: EbenPagination<EbenTaskDetail>) => {
        ctx.patchState({
          taskTotal: tasks.total,
        });
      })
    );
  }

  @Action(ReloadScope)
  protected reloadScope(ctx: StateContext<FlowStateModel>, action: ReloadScope) {
    const routerState: unknown = this.store.selectSnapshot(RouterState.state);
    const flowRoute = (<RouterStateParams>routerState).flowRoute;
    this.store.dispatch(new GetMyTasksTotal(null));
    switch (flowRoute) {
      case FLOW_MY_TASKS_ROOT:
        this.store.dispatch(new GetMyTasks(null));
        break;
      case FLOW_MY_WORKFLOWS_ROOT:
        this.store.dispatch(new GetMyWorkflows(null));
        break;
      case FLOW_OE_WORKFLOWS_ROOT:
        this.store.dispatch(new GetOrgEhWorkflows(null));
        break;
      case FLOW_WORKFLOW_ADMIN_ROOT:
        this.store.dispatch(new GetAdminWorkflows(null));
        break;
      default:
        break;
    }
  }
}

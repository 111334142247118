import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { FlowState } from './+store/flow.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([FlowState])],
})
export class FlowDataAccessModule {
  static forFeature(): ModuleWithProviders<FlowDataAccessModule> {
    return {
      ngModule: FlowDataAccessModule,
    };
  }
}

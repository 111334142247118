import { inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";

import { SnackbarType } from "@alf-nx-workspace/shared/interfaces";
import { TranslationService } from "@alfresco/adf-core";
import { EbenGenericError } from "@alf-nx-workspace/eben/interfaces";
import { ErrorMessage } from "./error.service";
import { SnackbarComponent } from "./snackbar/snackbar.component";


@Injectable({
  providedIn: "root"
})
export class SnackbarService {

  private snackbar = inject(MatSnackBar)
  private translationService = inject(TranslationService);

  public info(message: string) {
    this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        type: SnackbarType.success,
        message,
      },
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'snackbar-success',
    });
  }

  public error(error: EbenGenericError, withSendReport = false) {
    const snackbar = this.snackbar.openFromComponent(SnackbarComponent, {
      data: {
        type: SnackbarType.error,
        message: ErrorMessage.createMessageFromError(error),
        action: withSendReport ? 'SNACKBAR.SERVER_ERROR_SUPPORT' : null
      },
      duration: 0,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: 'snackbar-error',
    });
    snackbar.afterDismissed().subscribe((result) => {
      if(result.dismissedByAction) {

        const newline = "%0D%0A";
        const mailTo = "boku-it@boku.ac.at";
        const subject = "BOKUflow";

        const body: string[] = [
          "Liebe Hotline,",
          "ich habe folgende die Meldung in BOKUflow erhalten:",
          "",
          '"' + this.translationService.instant(ErrorMessage.createMessageFromError(error)) + '"',
          "",
          "---",
          'Timestamp: ' + error.timestamp.toISOString(),
          ErrorMessage.createSupportRequestInformationBlockFromError(error)
        ]

        const mail = `mailto:${mailTo}?subject=${subject}&body=${body.join(newline)}`;
        window.open(mail, '_blank');
      }
    })
  }
}

import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  ClearFormModel,
  ClearTaskFromState,
  GetTaskDetails,
  SetFormErrorFields,
  SetFormModel,
  SetFormModelDirty,
  SetFormValues,
  SetFormWarningFields,
  SetOutcomesExemptFromValidation,
  SetOutcomesToDisable,
  SetSelectedFormFieldHelp,
  ToggleShowFormHelp,
} from './eben.actions';
import { FormFieldModel, FormModel, FormOutcomeModel, FormValues } from '@alfresco/adf-core';
import { EbenTaskDetail, FormFieldWarning } from '@alf-nx-workspace/eben/interfaces';
import { tap } from 'rxjs/operators';
import { EbenService } from './eben.service';
import { of } from 'rxjs';
import { BpmService } from "@alf-nx-workspace/shared/utils";

export interface EbenStateModel {
  formModel?: string;
  loadedTasks?: EbenTaskDetail[];
  errorFields?: string[];
  warningsFields?: FormFieldWarning[];
  formValues?: FormValues;
  isDirty?: boolean;
  showFormHelp?: boolean;
  selectedFormFieldHelpId?: string;
  outcomesExemptFromValidation?: string[];
  outcomesToDisable?: string[];
}

@State<EbenStateModel>({
  name: 'eben',
  defaults: {
    formModel: '',
    loadedTasks: [],
    errorFields: [],
    warningsFields: [],
    isDirty: false,
    showFormHelp: false,
    selectedFormFieldHelpId: null,
    outcomesExemptFromValidation: [],
    outcomesToDisable: [],
  },
})
@Injectable()
export class EbenState {
  @Selector()
  static form(state: EbenStateModel): FormModel {
    const form: FormModel = new FormModel(state.formModel, state.formValues);
    form.values = state.formValues;
    return form;
  }

  @Selector()
  static formModel(state: EbenStateModel): string {
    return state.formModel;
  }

  @Selector()
  static formValues(state: EbenStateModel): FormValues {
    return state.formValues;
  }

  @Selector()
  static isFormDirty(state: EbenStateModel): boolean {
    return state.isDirty;
  }

  @Selector()
  static isFormValid(state: EbenStateModel): boolean {
    return !state.errorFields || state.errorFields.length === 0;
  }

  @Selector()
  static showFormHelp(state: EbenStateModel) {
    return state.showFormHelp;
  }

  @Selector()
  static selectedFormFieldHelpId(state: EbenStateModel) {
    return state.selectedFormFieldHelpId;
  }

  @Selector()
  static formErrorFields(state: EbenStateModel): FormFieldModel[] {
    if (state.errorFields) {
      return state.errorFields.map((json: string) => new FormFieldModel(null, json));
    } else {
      return [];
    }
  }

  @Selector()
  static formWarningFields(state: EbenStateModel): FormFieldWarning[] {
    return state.warningsFields;
  }

  static getTaskDetails(taskId: string) {
    return createSelector([EbenState], (state: EbenStateModel) => {
      return state.loadedTasks[taskId];
    });
  }

  static isOutcomeDisabled(outcome: FormOutcomeModel) {
    return createSelector([EbenState], (state: EbenStateModel) => {
      const isExempted: boolean = state.outcomesExemptFromValidation.includes(outcome.name);
      if (isExempted) {
        return false;
      }
      const isDisabled: boolean = state.outcomesToDisable.includes(outcome.name);
      if (isDisabled) {
        return true;
      }
      return state.errorFields && state.errorFields.length > 0;
    });
  }

  constructor(public bpmService: BpmService) {}

  @Action(GetTaskDetails)
  protected getTaskDetails(ctx: StateContext<EbenStateModel>, action: GetTaskDetails) {
    if (ctx.getState().loadedTasks[action.taskId]) {
      return of(true);
    }
    return this.bpmService.getTaskById(action.taskId).pipe(
      tap((taskDetail: EbenTaskDetail) => {
        ctx.setState({
          ...ctx.getState(),
          loadedTasks: {
            ...ctx.getState().loadedTasks,
            [action.taskId]: taskDetail,
          },
        })
      })
    );
  }

  @Action(ClearTaskFromState)
  protected removeTask(ctx: StateContext<EbenStateModel>, action: ClearTaskFromState) {
    delete ctx.getState().loadedTasks[action.taskId];
  }

  @Action(SetFormModel)
  protected setFormModel(ctx: StateContext<EbenStateModel>, action: SetFormModel) {
    return ctx.patchState({
      formModel: action.json,
    });
  }

  @Action(SetFormValues)
  protected setFormValues(ctx: StateContext<EbenStateModel>, action: SetFormValues) {
    return ctx.setState({
      ...ctx.getState(),
      formValues: action.values,
    });
  }

  @Action(SetFormErrorFields)
  protected setFormErrorFieldsJson(ctx: StateContext<EbenStateModel>, action: SetFormErrorFields) {
    return ctx.patchState({
      errorFields: action.json,
    });
  }

  @Action(SetFormWarningFields)
  protected setFormWarningFields(ctx: StateContext<EbenStateModel>, action: SetFormWarningFields) {
    return ctx.patchState({
      warningsFields: action.warningFields,
    });
  }

  @Action(SetSelectedFormFieldHelp)
  protected setSelectedFormFieldHelp(ctx: StateContext<EbenStateModel>, action: SetSelectedFormFieldHelp) {
    return ctx.patchState({
      selectedFormFieldHelpId: action.formFieldId,
    });
  }

  @Action(SetFormModelDirty)
  protected setFormModelDirty(ctx: StateContext<EbenStateModel>, action: SetFormModelDirty) {
    return ctx.patchState({
      isDirty: action.dirty,
    });
  }

  @Action(ClearFormModel)
  protected clearFormModel(ctx: StateContext<EbenStateModel>) {
    return ctx.patchState({
      formModel: null,
      loadedTasks: [],
      errorFields: [],
      warningsFields: [],
      isDirty: false,
      showFormHelp: false,
      outcomesExemptFromValidation: [],
      outcomesToDisable: [],
      formValues: [],
      selectedFormFieldHelpId: ""
    });
  }

  @Action(SetOutcomesExemptFromValidation)
  protected setOutcomesExemptFromValidation(
    ctx: StateContext<EbenStateModel>,
    action: SetOutcomesExemptFromValidation
  ) {
    return ctx.patchState({
      outcomesExemptFromValidation: action.outcomes,
    });
  }

  @Action(SetOutcomesToDisable)
  protected setOutcomesToDisable(ctx: StateContext<EbenStateModel>, action: SetOutcomesToDisable) {
    return ctx.patchState({
      outcomesToDisable: action.outcomes,
    });
  }

  @Action(ToggleShowFormHelp)
  protected toggleShowFormHelp(ctx: StateContext<EbenStateModel>, action: ToggleShowFormHelp) {
    return ctx.patchState({
      showFormHelp: !ctx.getState().showFormHelp,
    });
  }
}

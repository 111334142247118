import { EbenProcessInstance } from "../eben-process-instance.model";

export class RedirectError {
  constructor(public processInstance: EbenProcessInstance) {
  }
}

export class ProcessHasNoTaskError extends RedirectError {
}

export class ProcessHasBeenEndedError extends RedirectError {
}

export class ProcessHasNoTaskInTimeError extends RedirectError {
}

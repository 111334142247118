import { Component, inject, Input, OnChanges } from '@angular/core';
import { EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { ProcessVariableUtils } from "@alf-nx-workspace/shared/utils";
import { WorkflowVariables } from "@alf-nx-workspace/shared/interfaces";
import { FullNamePipe, UserProcessModel } from "@alfresco/adf-core";
import { AuthFacade } from "@alf-nx-workspace/auth/data-access";

@Component({
  selector: 'shared-ui-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  providers: [FullNamePipe]
})
export class TaskComponent implements OnChanges {

  readonly fullNamePipe = inject(FullNamePipe);
  readonly authFacade = inject(AuthFacade);

  @Input()
  task: EbenTaskDetail;

  public assigneeLabel: string;
  public assignedToUser: string;

  ngOnChanges() {

    this.setAssigneeLabel(this.task);
  }

  private setAssigneeLabel(task: EbenTaskDetail): void {
    this.assigneeLabel = null;
    this.assignedToUser = null;

    const assignee = this.task.assignee;
    const assigneeTerm = ProcessVariableUtils.getVariable(this.task.variables, WorkflowVariables._bokuFlow_assigneeTerm);
    if (!assigneeTerm && !assignee) {
      return;
    }

    this.assigneeLabel = this.getAssignee(this.task.assignee);

    if (!assigneeTerm) {
      return;
    }
    if (this.assigneeLabel && (this.authFacade.isTaskAssignee(this.task) || this.authFacade.isTaskCandidate(this.task) || this.authFacade.isTaskAdmin(this.task))) {
      this.assignedToUser = this.assigneeLabel;
    }
    this.assigneeLabel = assigneeTerm.toUpperCase();
  }

  private getAssignee(assignee: UserProcessModel): string {
    if (assignee == null) {
      return null;
    }
    return (this.authFacade.getUser().externalId == assignee.externalId)
      ? 'AVATAR.MYSELF'
      : this.fullNamePipe.transform(assignee);
  }
}

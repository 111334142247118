import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';
import { Store } from '@ngxs/store';
import { catchError } from "rxjs/operators";
import { TaskProcessVariableModel } from "@alfresco/adf-core/lib/form/models/task-process-variable.model";

@Injectable({
  providedIn: 'root',
})
export class EbenTaskFormVariablesResolver implements Resolve<Observable<TaskProcessVariableModel[]>> {
  constructor(public store: Store, private ebenfacade: EbenFacade) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaskProcessVariableModel[]> {
    return this.ebenfacade.getTaskFormVariables(route.params['taskId'])
      .pipe(
        catchError((error) => {
          return of(null);
        })
      )
  }
}

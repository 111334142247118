import { Injectable, Type } from '@angular/core';
import { InformationTemplateComponent } from './information-template/information-template.component';
import { ResultTemplateComponent } from "./result-template/result-template.component";
import { BaseService } from "../base.service";

import { BaseInformationTemplateComponent } from "../base-information-template.component";
import { BaseResultTemplateComponent } from "../base-result-template.component";

@Injectable({providedIn: 'root'})
export class EntsorgungAnfordernService extends BaseService {

  WORKFLOW_NAME = 'entsorgung-anfordern';
  static WORKFLOW_NAME = 'entsorgung-anfordern';

  getInformationTemplate(): Type<BaseInformationTemplateComponent> {
    return InformationTemplateComponent;
  }

  getResultTemplate(): Type<BaseResultTemplateComponent> {
    return ResultTemplateComponent;
  }
}

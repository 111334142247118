import { defer, Observable, of, throwError } from 'rxjs';
import { TaskDetailsModel } from '@alfresco/adf-process-services';
import { catchError, delay, map, mergeMap, retryWhen, tap } from 'rxjs/operators';
import { inject, Injectable } from '@angular/core';
import { EbenProcessInstance, ProcessHasBeenEndedError, ProcessHasNoTaskError, ProcessInstanceNotFoundError, ProcessHasNoTaskInTimeError } from '@alf-nx-workspace/eben/interfaces';
import { BpmService } from "@alf-nx-workspace/shared/utils";

@Injectable({
  providedIn: 'root',
})
export class EbenRedirectService {

  readonly bpmService = inject(BpmService);

  public redirectToTaskByInstanceIdIfExists(
    processInstanceId: string,
    maxRetries = 8
  ): Observable<TaskDetailsModel> {
    let retriesLeft= maxRetries;
    return defer(() => this.bpmService.getProcessInstanceById(processInstanceId))
      .pipe(
        tap((processInstance: EbenProcessInstance) => {
          if(processInstance.ended != null && retriesLeft > 2) {
            retriesLeft = 2;
          }
        }),
        map((processInstance: EbenProcessInstance) => {
          const tasks = processInstance.taskRepresentations;
          if (tasks.length === 0) {
            throw new ProcessHasNoTaskError(processInstance);
          }
          return tasks[0];
        }
      ),
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, i) => {
            retriesLeft--;
            // retry if the process has no tasks
            if (error instanceof ProcessHasNoTaskError) {
              if (retriesLeft < 0) {
                if(error.processInstance.ended) {
                  throw new ProcessHasBeenEndedError(error.processInstance);
                }
                throw new ProcessHasNoTaskInTimeError(error.processInstance);
              }
              return of(error).pipe(delay(2000));
            } else {
              throw error;
            }
          })
        )
      )
    );
  }
}

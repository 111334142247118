import { Component } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'dialog-authentication-error',
  templateUrl: './dialog-authentication-error.component.html',
  styleUrls: ['./dialog-authentication-error.component.scss'],
  standalone: true,
  imports: [
    TranslateModule,
  ]
})
export class DialogAuthenticationErrorComponent {

}

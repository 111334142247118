import { AuthenticationService } from '@alfresco/adf-core';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { UserRepresentation } from '@alfresco/js-api';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(public authenticationService: AuthenticationService) {}

  public loadUser(): Observable<UserRepresentation> {
    return this.authenticationService.getBpmLoggedUser();
  }

  public logout(): void {
    this.authenticationService.logout();
  }
}

import { FormFieldModel, FormModel } from '@alfresco/adf-core';
import { Injectable } from '@angular/core';
import moment from 'moment-es6';



import { VorschussBetragValidator } from '../reise-auf.validators';
import { BaseProcessor } from '../../base.processor';
import { ReiseAufVariables } from "../reise-auf.variables";
import { FormFieldWarning } from "@alf-nx-workspace/eben/interfaces";

@Injectable({ providedIn: 'root' })
export class ReiseAufA1Processor extends BaseProcessor {
  static FORM_NAME = 'reiseAufA1';
  public readonly FORM_NAME = ReiseAufA1Processor.FORM_NAME;

  public onLoaded(form: FormModel) {
    super.onLoaded(form);
    this.addFormFieldValidator(new VorschussBetragValidator());
    this.updateEndeDienstreise(form);
  }

  public onFieldValueChange(field: FormFieldModel) {
    if (field.id === ReiseAufVariables.a1_beginn || field.id === ReiseAufVariables.a1_ende) {
      this.updateBeginnUndEndeDienstreise(field);
    }

    if (field.id === ReiseAufVariables.land) {
      const fields: FormFieldModel[] = field.form.getFormFields();
      const a1Field = fields.find((f) => f.id === ReiseAufVariables.a1);
      this.falseIfNotVisible(a1Field);
    }
  }

  public getFormFieldWarnings(form: FormModel): FormFieldWarning[] {
    let warnings: FormFieldWarning[] = [];
    const fields: FormFieldModel[] = form.getFormFields();
    const a1_beginnField = fields.find((f) => f.id === ReiseAufVariables.a1_beginn);

    warnings = [...warnings, ...this.validateBeginnDienstreise(a1_beginnField)];
    return warnings;
  }

  private validateBeginnDienstreise(field: FormFieldModel) {
    if (moment(field.value, field.dateDisplayFormat).startOf('day').isBefore(moment().startOf('day'))) {
      return [
        {
          fieldId: field.id,
          warningSummary: 'REISE-AUF.WARNING.BEGINN_DIENSTREISE_IN_THE_PAST',
        },
      ];
    }
    return [];
  }

  private updateEndeDienstreise(form: FormModel) {
    const fields: FormFieldModel[] = form.getFormFields();
    const a1_beginnField = fields.find((f) => f.id === ReiseAufVariables.a1_beginn);
    const a1_endeField = fields.find((f) => f.id === ReiseAufVariables.a1_ende);
    const a1_beginnValue = a1_beginnField.value;

    if (a1_beginnValue) {
      a1_endeField.minValue = a1_beginnValue;
      this.updateField(a1_endeField);
    }
  }

  private updateBeginnUndEndeDienstreise(field: FormFieldModel) {
    const fields: FormFieldModel[] = field.form.getFormFields();
    const a1_beginnField = fields.find((f) => f.id === ReiseAufVariables.a1_beginn);
    const a1_endeField = fields.find((f) => f.id === ReiseAufVariables.a1_ende);

    const a1_beginnValue = a1_beginnField.value;
    const a1_endeValue = a1_endeField.value;

    if (field.id === ReiseAufVariables.a1_beginn && a1_beginnValue) {
      if (!a1_endeValue) {
        a1_endeField.value = a1_beginnField;
        this.updateField(a1_endeField);
      }
      a1_endeField.minValue = a1_beginnValue;
      this.updateField(a1_endeField);
    } else if (field.id === ReiseAufVariables.a1_ende && a1_endeValue) {
      if (!a1_beginnValue) {
        a1_beginnField.value = a1_endeValue;
        this.updateField(a1_beginnField);
      }
    }
  }
}

import { inject, Injectable, Injector } from '@angular/core';
import { Store } from '@ngxs/store';
import { Navigate } from '@ngxs/router-plugin';

import {
  ClearFormModel,
  ClearTaskFromState,
  FormSaved,
  GetTaskDetails,
  SetFormErrorFields,
  SetFormModel,
  SetFormModelDirty,
  SetFormValues,
  SetFormWarningFields,
  SetOutcomesExemptFromValidation,
  SetOutcomesToDisable,
  SetSelectedFormFieldHelp,
  TaskCompleted,
  ToggleShowFormHelp,
  WorkflowStarted
} from './eben.actions';
import { EbenState } from './eben.state';
import { Observable } from 'rxjs';
import { PROCESS_ENDED_URL, WORKFLOW_NOT_FOUND_URL, TASK_NOT_FOUND_IN_TIME_URL } from '../routing-constants';
import { FormFieldModel, FormModel, FormOutcomeModel, FormValues, WidgetVisibilityService } from '@alfresco/adf-core';
import { EbenService } from './eben.service';
import {
  EbenDialogResultModel,
  FormFieldWarning,
  ProcessHasBeenEndedError,
  ProcessInstanceNotFoundError,
  ProcessHasNoTaskInTimeError, ProcessHasNoTaskError, EbenTaskDetail
} from '@alf-nx-workspace/eben/interfaces';
import { TaskQueryRequestRepresentationModel } from '@alfresco/adf-process-services';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { SnackbarService } from "@alf-nx-workspace/shared/utils";

@Injectable({
  providedIn: 'root',
})
export class EbenFacade {

  private injector = inject(Injector);
  private store = inject(Store);
  private ebenService = inject(EbenService);

  private snackService = inject(SnackbarService)

  public readonly formErrorFields$: Observable<FormFieldModel[]> = this.store.select(EbenState.formErrorFields);
  public readonly formModel$: Observable<FormModel> = this.store.select(EbenState.formModel).pipe(
    map((json) => {
      return new FormModel(json);
    })
  );
  public readonly formWarningFields$: Observable<FormFieldWarning[]> = this.store
    .select(EbenState.formWarningFields)
    .pipe(filter((warnings: FormFieldWarning[]) => !!warnings));
  public readonly isFormDirty$: Observable<boolean> = this.store.select(EbenState.isFormDirty);
  public readonly showFormHelp$: Observable<boolean> = this.store.select(EbenState.showFormHelp);
  public readonly selectedFormFieldHelpId$: Observable<string> = this.store.select(EbenState.selectedFormFieldHelpId);
  private visibilityService: WidgetVisibilityService;
  public readonly form$: Observable<FormModel> = this.store.select(EbenState.form).pipe(
    debounceTime(50),
    map((form: FormModel) => {
      this.visibilityService.refreshVisibility(form);
      return form;
    })
  );

  constructor() {
    this.visibilityService = this.injector.get(WidgetVisibilityService);
  }


  public getTaskDetails(taskId: string): Observable<EbenTaskDetail> {
    return this.store.dispatch(new GetTaskDetails(taskId))
      .pipe(
        switchMap(() => this.store.select(EbenState.getTaskDetails(taskId)),
        ));
  }

  public getTaskFormVariables(taskId: string) {
    return this.visibilityService.getTaskProcessVariable(taskId);
  }

  public getTaskForm(taskId: string): Observable<FormModel> {
    return this.ebenService.formService.getTaskForm(taskId);
  }

  public getTotalTasks(requestNode: TaskQueryRequestRepresentationModel) {
    return this.ebenService.taskListService.getTotalTasks(requestNode);
  }

  public setFormModel(form: FormModel): void {
    this.store.dispatch(new SetFormValues(null, form.values));
    this.store.dispatch(new SetFormModel(form.json));
  }

  public setFormValues(fieldId: string, values: FormValues): void {
    this.store.dispatch(new SetFormValues(fieldId, {...values}));
  }

  public setFormErrorFields(errorsField: FormFieldModel[]) {
    this.store.dispatch(new SetFormErrorFields(errorsField.map((field: FormFieldModel) => field.json)));
  }

  public setFormFieldWarnings(warningsFields: FormFieldWarning[]) {
    this.store.dispatch(new SetFormWarningFields(warningsFields));
  }

  public toggleShowFormHelp(): void {
    this.store.dispatch(new ToggleShowFormHelp());
  }

  public setSelectedFormFieldHelp(formFieldModel: FormFieldModel): void {
    this.store.dispatch(new SetSelectedFormFieldHelp(formFieldModel.id));
  }

  public setFormModelDirty(dirty = false): void {
    this.store.dispatch(new SetFormModelDirty(dirty));
  }

  public workflowStarted(): void {
    this.store.dispatch(new WorkflowStarted());
  }

  public completeTask(): void {
    this.store.dispatch(new TaskCompleted());
  }

  public saveForm(form: FormModel): void {
    this.store.dispatch(new FormSaved(form));
  }

  public clearFormState(): void {
    this.store.dispatch(new ClearFormModel());
  }

  public setOutcomesExemptFromValidation(outcomes: string[]): void {
    this.store.dispatch(new SetOutcomesExemptFromValidation(outcomes));
  }

  public setOutcomesToDisable(outcomes: string[]): void {
    this.store.dispatch(new SetOutcomesToDisable(outcomes));
  }

  public isOutcomeDisabled(outcome: FormOutcomeModel) {
    return this.store.select(EbenState.isOutcomeDisabled(outcome));
  }

  public clearDialogOutlet(): Observable<EbenState> {
    return this.store.dispatch(new Navigate([{outlets: {dialog: null}}], null, {queryParamsHandling: 'preserve'}));
  }

  public scrollToFormField(field: FormFieldModel): void {
    if (field) {
      const scrollTo: HTMLElement = document.documentElement.querySelector(`#field-${field.id}-container`);
      if (scrollTo) {
        const className = scrollTo.className;
        scrollTo.scrollIntoView({behavior: 'smooth'});
        scrollTo.className = scrollTo.className + ' blink';
        setTimeout(() => {
          scrollTo.className = className;
        }, 1500);
      }
    }
  }

  public handleRedirectError(error: unknown): EbenDialogResultModel {
    const dialogResult: EbenDialogResultModel = {
      triggerReload: false,
      redirect: ""
    };

    if (error instanceof ProcessInstanceNotFoundError) {
      dialogResult.redirect = WORKFLOW_NOT_FOUND_URL.replace(':processInstanceId', error.id);
    } else if (error instanceof ProcessHasBeenEndedError) {
      dialogResult.triggerReload = true;
      dialogResult.redirect = PROCESS_ENDED_URL.replace(':processInstanceId', error.processInstance.id);
    } else if (error instanceof ProcessHasNoTaskInTimeError) {
      dialogResult.redirect = TASK_NOT_FOUND_IN_TIME_URL.replace(':processInstanceId', error.processInstance.id);
    }

    return dialogResult;
  }

  public navigateToOutlet(dialogPath: string, skipLocationChange = false): void {
    this.store.dispatch(new Navigate([{outlets: {dialog: dialogPath}}], null, {queryParamsHandling: 'preserve', skipLocationChange}));
  }

  public clearTaskFromState(taskId: string): void {
    this.store.dispatch(new ClearTaskFromState(taskId));
  }

}

import { NgModule } from '@angular/core';
import { HomeofficeVereinbarungGrunddatenProcesser } from './form-processors/homeoffice-vereinbarung.grunddaten.processer';
import { FORM_PROCESSOR_MAPPING, WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING, } from '@alf-nx-workspace/shared/utils';
import { HomeofficeVereinbarungService } from './homeoffice-vereinbarung.service';
import { HomeOfficeVereinbarungVariablesSearchable } from './homeoffice-vereinbarung.variables';

@NgModule({
  providers: [
    { provide: FORM_PROCESSOR_MAPPING, useValue: HomeofficeVereinbarungGrunddatenProcesser, multi: true },
    { provide: WORKFLOW_SERVICE_MAPPING, useValue: HomeofficeVereinbarungService, multi: true },
    { provide: WORKFLOW_VARIABLE_MAPPING, useValue: HomeOfficeVereinbarungVariablesSearchable, multi: true },
  ]

})
export class HomeofficeVereinbarungModule {}

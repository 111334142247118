<div class="thumbnail-container">
  <img *ngIf="this.thumbnailBlobUrl" [src]="this.thumbnailBlobUrl" class="thumbnail"/>
  <div *ngIf="!this.thumbnailBlobUrl" class="thumbnail spinner">
    <mat-progress-spinner *ngIf="this.showSpinner" diameter="24" mode="indeterminate"></mat-progress-spinner>
  </div>
</div>
<div class="document-info">
  <div class="document-name" title="{{document.name}}">
    {{document.name}}
  </div>
  <span class="mat-caption document-date">
    <img width='24' height='24' alt="{{ document.mimeType }}"
         src="{{ document.mimeType | adfMimeTypeIcon }}"/>
    {{document.created | date: "dd.MM.yyyy HH:mm" }}
  </span>
</div>
<div class="download-icon-button" #download_button>
  <button mat-icon-button tabindex="-1" (click)="downloadContent($event, document.id, document.name)" title="{{'TOOLTIP.DOWNLOAD' | translate}}">
    <mat-icon>download</mat-icon>
  </button>
</div>

<ng-template #dialogTemplate>
  <mat-dialog-actions class="dialog-actions" align='start'>
    <div class="document-info-full">
      <div class="document-name" title="{{document.name}}">
        {{document.name}}
      </div>
      <span class="mat-caption document-date">
        <img width='24' height='24' alt="{{ document.mimeType }}"
             src="{{ document.mimeType | adfMimeTypeIcon }}"/>
        {{document.created | date: "dd.MM.yyyy HH:mm" }}
      </span>
    </div>
    <button mat-icon-button class="dialog-header-button mat-icon-button-modal" tabindex="-1" (click)="downloadContent($event, document.id, document.name)" title="{{'TOOLTIP.DOWNLOAD' | translate}}">
      <mat-icon>download</mat-icon>
    </button>
    <button mat-icon-button class="pull-right-lg mat-icon-button-modal" tabindex="-2" (click)='closeViewer()'>
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
  <mat-dialog-content>
    <div class="thumbnail-container-large">
      <img [src]="this.thumbnailBlobUrl" class="thumbnail"/>
    </div>
    <pdf-viewer [@fadeInOut]="isPDFLoadComplete ? 'visible' : 'hidden'"
                *ngIf="previewBlobUrl"
                [src]="previewBlobUrl"
                [original-size]="false"
                [render-text]='true'
                [show-all]="true"
                [fit-to-page]="false"
                (after-load-complete)='afterLoadComplete()'>
    </pdf-viewer>
  </mat-dialog-content>
</ng-template>

import { Navigate } from '@ngxs/router-plugin';
import { FLOW_MY_DOCUMENTS_ROOT, FLOW_MY_TASKS_ROOT, FLOW_MY_WORKFLOWS_ROOT, FLOW_OE_WORKFLOWS_ROOT, FLOW_ROOT, FLOW_START_WORKFLOW_ROOT, FLOW_WORKFLOW_ADMIN_ROOT } from '../routing-constants';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RouteModel } from '@alf-nx-workspace/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';
import { FlowFacade } from './flow.facade';

@Injectable({
  providedIn: 'root'
})
export class NavigationFacade {
  public routes: RouteModel[] = [

    {
      route: FLOW_START_WORKFLOW_ROOT,
      icon: 'tile-start-workflow.svg'
    },
    {
      route: FLOW_MY_TASKS_ROOT,
      icon: 'tile-my-todos.svg',
      data: {
        myTasksBadge$: this.flowFacade.myTasksTotals$
      }
    },
    {
      route: FLOW_MY_WORKFLOWS_ROOT,
      icon: 'tile-my-workflows.svg'
    },
    {
      route: FLOW_MY_DOCUMENTS_ROOT,
      icon: 'tile-my-documents.svg'
    },
    {
      route: FLOW_OE_WORKFLOWS_ROOT,
      icon: 'tile-orgeh-workflows.svg'
    },
    {
      route: FLOW_WORKFLOW_ADMIN_ROOT,
      icon: 'tile-admin-workflows.svg'
    }
  ];

  constructor(
    public store: Store,
    public translateService: TranslateService,
    public flowFacade: FlowFacade
  ) {
  }

  public getAbsoluteRoute(route: string): string[] {
    return ['/', this.translateService.currentLang, FLOW_ROOT, route];
  }

  public navigateToRoute(route: string) {
    this.store.dispatch(new Navigate([this.translateService.currentLang, FLOW_ROOT, route]));
  }
}

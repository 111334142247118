import { Inject, Injectable } from '@angular/core';
import { Environment, FlowProcessDefinitionModel } from '@alf-nx-workspace/shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ProcessService, TaskQueryRequestRepresentationModel } from '@alfresco/adf-process-services';
import { AlfrescoApiService, FormService } from '@alfresco/adf-core';
import { EbenPagination, EbenTaskDetail, EbenTaskQueryRepresentation } from '@alf-nx-workspace/eben/interfaces';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';

@Injectable({
  providedIn: 'root',
})
export class FlowService {
  constructor(
    public ebenFacade: EbenFacade,
    protected formService: FormService,
    private http: HttpClient,
    @Inject('environment')
    public environment: Environment
  ) {}

  public getFlowProcessDefinitions(): Observable<FlowProcessDefinitionModel[]> {
    return this.http.get<FlowProcessDefinitionModel[]>(`assets/${this.environment.flowProcessConfig}?v=` + Date.now());
  }

  public getTaskTotal(requestNode: TaskQueryRequestRepresentationModel): Observable<EbenPagination<EbenTaskDetail>> {
    return this.ebenFacade.getTotalTasks(requestNode);
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from './+store/auth.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([AuthState])],
})
export class AuthDataAccessModule {
  static forFeature(): ModuleWithProviders<AuthDataAccessModule> {
    return {
      ngModule: AuthDataAccessModule,
    };
  }
}

<div class="snackbar">
  <div class="content">
    <mat-icon *ngIf="data.type === success">check_circle</mat-icon>
    <mat-icon *ngIf="data.type === error">error</mat-icon>
    <span class="margin-left-8">{{ data.message | translate }}</span>
  </div>
  <div class="actions">
    <ng-container *ngIf="data.action">
      <button mat-button (click)="snackBarRef.dismissWithAction()">{{data.action | translate}}</button>
    </ng-container>
    <button mat-button (click)="snackBarRef.dismiss()">{{'SNACKBAR.DISMISS' | translate}}</button>
  </div>
</div>

import { FormFieldModel } from '@alfresco/adf-core';
import { Injectable } from '@angular/core';
import { BaseProcessor } from '../../base.processor';
import { ReiseAufVariables } from "../reise-auf.variables";

@Injectable({ providedIn: 'root' })
export class ReiseAufFreigabeVorgesetzterProcessor extends BaseProcessor {
  static FORM_NAME = 'reiseAufFreigabeVorgesetzter';
  public readonly FORM_NAME = ReiseAufFreigabeVorgesetzterProcessor.FORM_NAME;

  public onFieldValueChange(field: FormFieldModel) {
    if (field.id === ReiseAufVariables.freigabe) {
      field.form.validateForm();
    }
  }
}

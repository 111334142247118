import { FormFieldMetadata, FormFieldModel, FormModel } from '@alfresco/adf-core';
import { EbenFormFieldModel } from '@alf-nx-workspace/eben/interfaces';
import { PropertyOptionFilterModel } from '@alf-nx-workspace/shared/interfaces';
import { map } from 'rxjs/operators';

export function getFormStepFieldsForFormModel(form: FormModel): FormFieldModel[] {
  if (!form) {
    return [];
  }
  return form
    .getFormFields()
    .filter((formField: FormFieldModel) => {
      return formField.fieldType === 'ContainerRepresentation';
    })
    .filter((formField: FormFieldModel) => {
      const params: FormFieldMetadata = formField.params;
      if ('customProperties' in params && 'FORM-BUILDER.COMPONENT.HEADER.STEPPER' in params['customProperties']) {
        if (params['customProperties']['FORM-BUILDER.COMPONENT.HEADER.STEPPER'] === 'true') {
          return true;
        }
      }
      return false;
    });
}

export function getFormFieldsWithinContainer(field: FormFieldModel): FormFieldModel[] {
  let formFields: FormFieldModel[] = [];
  if (field.fieldType === 'ContainerRepresentation') {
    for (const index in field.fields) {
      const value: unknown = field.fields[index];
      if (value) {
        (value as EbenFormFieldModel[]).forEach((f: EbenFormFieldModel) => {
          formFields = [...formFields, ...getFormFieldsWithinContainer(f)];
        });
      }
    }
  } else {
    formFields.push(field);
  }
  return formFields;
}

export function getFormFieldsWithInfoTextFromFormModel(form: FormModel): FormFieldModel[] {
  return form.getFormFields().filter((field: FormFieldModel) => getInfoTextForFormField(field) !== null);
}

export function getInfoTextForFormField(field: FormFieldModel): string {
  const regExp = /FORM-BUILDER\.COMPONENT\.([-A-Z.]*)\.INFOTEXT/i;
  if (field.params['customProperties']) {
    for (const key in field.params['customProperties']) {
      if (regExp.test(key) && field.params.customProperties[key] !== '') {
        return field.params['customProperties'][key];
      }
    }
  }
  return null;
}

export function processDefinitionIdsToProcessDefinitionKeys(processDefinitionIds: string[]): string[] {
  if(processDefinitionIds.length == 0) {
    return [];
  }
  return processDefinitionIds
    .map((definition: string) => definition.substr(0, definition.indexOf(":")))
    .filter((key, index, self) => {
      return self.indexOf(key) === index
    });
}


export function processDefinitionKeysToPropertyOption(processDefinitionKeys: string[]): PropertyOptionFilterModel[] {
  if(processDefinitionKeys.length == 0) {
    return [];
  }

  return processDefinitionKeys
    .map((key: string) => ({
      id: key,
      name: key
    }));
}

import { Component, Input, OnInit } from '@angular/core';
import { EbenProcessInstance, EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { WorkflowService } from '@alf-nx-workspace/shared/utils';
import { WorkflowServiceModel } from "@alf-nx-workspace/flow/interfaces";

@Component({
  selector: 'shared-ui-process-priority',
  templateUrl: './process-priority.component.html',
  styleUrls: ['./process-priority.component.scss'],
})
export class ProcessPriorityComponent implements OnInit {
  @Input()
  process: EbenProcessInstance;

  @Input()
  task: EbenTaskDetail;

  public highPriority: string;
  public highPriorityTooltip: string;
  public longRunning: string;
  public longRunninTootip: string;

  constructor(public workflowService: WorkflowService) {}

  ngOnInit(): void {
    if (!this.process.ended) {
      const workflowServiceModel: WorkflowServiceModel = this.workflowService.getServiceByProcessDefinitionKey(
        this.process.processDefinitionKey
      );

      if (workflowServiceModel) {
        this.highPriority = this.highPriorityTooltip = workflowServiceModel.isHighPriority(this.process);
        this.longRunning = this.longRunninTootip = workflowServiceModel.isLongRunning(this.process, this.task);
      }
    }
  }
}

import { Inject, Injectable } from '@angular/core';
import { FormModel } from '@alfresco/adf-core';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';
import { AuthFacade } from '@alf-nx-workspace/auth/data-access';
import { UserRepresentation } from '@alfresco/js-api/src/api/activiti-rest-api/model/userRepresentation';
import { GroupRepresentation } from '@alfresco/js-api/src/api/activiti-rest-api/model/groupRepresentation';
import { Environment } from '@alf-nx-workspace/shared/interfaces';
import { BaseProcessor } from '../../base.processor';
import { take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ReiseAufStartformProcessor extends BaseProcessor {
  static FORM_NAME = 'reise-auf'; // As this is a start form we use the processDefinitionKey to identify it

  public readonly FORM_NAME = ReiseAufStartformProcessor.FORM_NAME;

  public constructor(
    private ebenFacade: EbenFacade,
    private authFacade: AuthFacade,
    @Inject('environment')
    public environment: Environment
  ) {
    super();
  }

  public onLoaded(form: FormModel): void {
    super.onLoaded(form);
    this.authFacade.user$.pipe(take(1)).subscribe((user: UserRepresentation) => {
      if (
        !user.groups.some(
          (group: GroupRepresentation) =>
            group.name === this.environment.groupAllowedToStartReiseauftragInRepresentation
        )
      ) {
        this.ebenFacade.setOutcomesToDisable(['START_IN_REPRESENTATION']);
      }
    });
  }
}

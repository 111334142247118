export enum HomeofficeVereinbarungVariables {
  'antStellName' = 'antStellName',
  'bvAntragsart' = 'bvAntragsart',
  'bvAb' = 'bvAb',
  'bvBis' = 'bvBis',
  'hasBeenCancelledComment' = 'hasBeenCancelledComment',
  'hasBeenCancelledByFullname' = 'hasBeenCancelledByFullname',
  'bvUnbefristet' = 'bvUnbefristet',
  'homeofficetageunderreichbarkeit' = 'homeofficetageunderreichbarkeit',
  'anzahltage' = 'anzahltage',
  'textanzahltage' = 'textanzahltage',
  'auswahlhomeofficetage' = 'auswahlhomeofficetage',
  'taskCreationDate' = 'taskCreationDate',
  'homeoffice_antrag_angenommen' = 'homeoffice_antrag_angenommen',
  'currentLeiterFullname' = 'currentLeiterFullname',
  'freigegebenVonJson' = 'freigegebenVonJson',
  'abgelehntVon' = 'abgelehntVon',
  'abgelehntAm' = 'abgelehntAm',
  'kommentarAblehnen' = 'kommentarAblehnen',
  'homeofficearbeitsplatz'= 'homeofficearbeitsplatz'

}

export const HomeOfficeVereinbarungVariablesSearchable = [
  HomeofficeVereinbarungVariables.antStellName,
  HomeofficeVereinbarungVariables.bvAntragsart,
  HomeofficeVereinbarungVariables.bvAb,
  HomeofficeVereinbarungVariables.bvBis,
]


import { Component, Directive, Inject, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { switchMap, takeUntil } from 'rxjs/operators';
import { AuthFacade } from '@alf-nx-workspace/auth/data-access';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { PERMISSION_MAPPING, PermissionMapping } from '@alf-nx-workspace/shared/interfaces';

@Directive({
  selector: '[hasRoutePermission]',
  standalone: true,
})
export class HasRoutePermissionDirective implements OnDestroy {

  @Input() set hasRoutePermission(route: string) {
    this.entitlementsSubject.next(this.permissions[route]);
  }

  protected readonly entitlementsSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(null);
  readonly entitlements$: Observable<string[]> = this.entitlementsSubject.asObservable();

  private hasView = false;
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public templateRef: TemplateRef<Component>,
    public viewContainer: ViewContainerRef,
    public authFacade: AuthFacade,
    @Inject(PERMISSION_MAPPING) public permissions: PermissionMapping
  ) {
  }

  ngOnInit() {
    this.entitlements$
      .pipe(
        takeUntil(this.destroy$),
        switchMap((entitlements: string[]) => this.authFacade.hasGroupPermission(entitlements))
      )
      .subscribe((hasPermission: boolean) => {
        this.behaviour(hasPermission);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
  }

  private behaviour(hasPermission: boolean) {
    this.viewContainer.clear();
    if (hasPermission) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}

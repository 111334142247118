import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { DialogSessionErrorComponent } from "../dialog-session-error/dialog-session-error.component";
import Timer = NodeJS.Timer;

@Injectable({ providedIn: 'root' })
export class FlowSessionService {

  readonly matDialog = inject(MatDialog);
  public readonly alfrescoApi = inject(AlfrescoApiService);

  public isSessionValid: boolean;
  public timer: Timer;

  public start() {
    if(!this.timer) {
      this.isSessionValid = true;
      if (this.alfrescoApi.getInstance().oauth2Auth.isLoggedIn()) {
        this.validate();
      }
    }
  }

  public stop() {
    if(this.timer) {
      this.isSessionValid = false;
      clearTimeout(this.timer);
    }
  }

  public logout() {
    this.stop();
    this.alfrescoApi.getInstance().oauth2Auth.logOut();
  }

  public refresh() {
    this.alfrescoApi.getInstance().oauth2Auth.destroyIframe();
    this.alfrescoApi.getInstance().oauth2Auth.createIframe();
  }

  private validate() {
    if (this.alfrescoApi.getInstance().oauth2Auth.isValidToken() === false) {
      this.openDialog();
      this.stop();
    }
    if (this.isSessionValid) {
      this.timer = setTimeout(() => {
        this.validate();
      }, environment.validateSessionTimer);
    }
  }

  private openDialog() {
    this.matDialog.open(DialogSessionErrorComponent, {
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: false,
    });
  }
}

<h4 mat-dialog-title>
  {{ 'DIALOG.SESSION_TIMEOUT_TITLE' | translate }}
</h4>
<div mat-dialog-content>
  <div>{{ 'DIALOG.SESSION_TIMEOUT_BODY' | translate }}</div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button [disabled]="disableDialogActions" (click)="logout()">{{ 'DIALOG.SESSION_TIMEOUT_BODY.MORE_TIME' | translate }}</button>
  <button mat-button [disabled]="disableDialogActions" color="primary" (click)="refresh()">{{ 'DIALOG.SESSION_TIMEOUT_BODY.LOGOUT' | translate }}</button>
</div>

import { inject, Injectable } from "@angular/core";
import {  ProcessContentService } from "@alfresco/adf-core";
import { interval, Observable, of, Subject, timer } from "rxjs";
import { catchError, defaultIfEmpty, delay, endWith, filter, flatMap, map, retry, switchMap, take, takeUntil, tap, timeout } from "rxjs/operators";
import { RelatedContentRepresentation } from "@alfresco/js-api";


@Injectable()
export class ApsDocumentLinkElementPreviewService {

  private trigger$: Observable<number> = interval(1000);
  private success$: Subject<boolean> = new Subject<boolean>();
  private readonly processContentService = inject(ProcessContentService);

  public getRenditionBlobUrl(content: RelatedContentRepresentation, renditionType: 'preview' | 'thumbnail' = 'thumbnail', retries = 5): Observable<string> {
     return this.trigger$.pipe(
      take(5),
      takeUntil(this.success$),
      switchMap(() => this.processContentService.getFileContent(content.id)
          .pipe(
            filter((content:RelatedContentRepresentation) => this.isRenditionReady(content, renditionType)),
            switchMap((content:RelatedContentRepresentation) => this.processContentService.contentApi.getRawContent(content.id, renditionType)),
            map((blob: Blob) => {
              this.success$.next(true);
              return URL.createObjectURL(blob);
            })
        )
      ),
      defaultIfEmpty(null)
    )
  }
  private isRenditionReady(content: RelatedContentRepresentation, renditionType: 'preview' | 'thumbnail' = 'thumbnail'): boolean {
    return content[renditionType + "Status"] == "created"
  }
}

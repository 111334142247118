import { ProcessInstance } from '@alfresco/adf-process-services';
import { RestVariable } from '@alfresco/js-api';

export class ProcessVariableUtils {
  static getProcessInstanceVariable(processInstance: ProcessInstance, name: string): string {
    if (processInstance) {
      const variable: RestVariable = processInstance.variables.find(
        (processVariable: RestVariable) => processVariable.name === name
      );
      return variable ? variable.value : null;
    }
    return null;
  }

  static getVariable(variables: RestVariable[], name: string): string {
    if (variables) {
      const variable: RestVariable = variables.find((processVariable: RestVariable) => processVariable.name === name);
      return variable ? variable.value : null;
    }
    return null;
  }

  static getProcessInstanceVariableAsMap(processInstance: ProcessInstance): Map<string, string> {
    const map = new Map<string, string>();
    if (processInstance) {
      processInstance.variables.forEach((processVariable: RestVariable) => {
        map.set(processVariable.name, processVariable.value);
      });
    }
    return map;
  }
}

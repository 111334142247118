import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ContentInfo, ResultNode } from '@alfresco/js-api';
import { ContentService } from '@alfresco/adf-core';
import { Subject } from "rxjs";
import { saveAs } from 'file-saver';
import { take } from "rxjs/operators";

@Component({
  selector: 'shared-ui-document-link',
  templateUrl: './document-link.component.html',
  styleUrls: ['./document-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLinkComponent implements OnInit {

  readonly contentService = inject(ContentService);

  @Input()
  entry: ResultNode;

  public content: ContentInfo;
  public url: string;
  public filename: string;

  ngOnInit(): void {
    this.content = this.entry.content;
    this.filename = this.getFilename(this.entry);
  }

  public download(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.contentService
      .getNodeContent(this.entry.id)
      .pipe(take(1))
      .subscribe((content) => {
        saveAs(content,  this.filename);
      });
  }

  private getFilename(entry: ResultNode): string {
    if (this.hasAspect(entry.aspectNames, 'boku-homeoffice-bv:antrag')) {
      if (entry.properties['boku:status'] === 'genehmigt') {
        const dateString= this.formatDateString(this.entry.properties['boku-homeoffice-bv:giltAb']);
        return `Homeoffice_Vereinbarung_ab_${dateString}.pdf`;
      } else {
        return 'Homeoffice_Antrag_abgelehnt.pdf';
      }
    } else if (this.hasAspect(entry.aspectNames, 'boku-reise:antrag')) {
      const dateString= this.formatDateString(this.entry.properties['boku-reise:beginn']);
      return `Dienstreiseauftrag_nach_${dateString}.pdf`;
    } else if (this.hasAspect(entry.aspectNames, 'bokuUrlaubsfreigabeDelegieren:genehmigung')) {
      const dateString= this.formatDateString(this.entry.createdAt);
      return `Urlaubsfreigabe-Delegieren_Genehmigung_${dateString}.pdf`;
    } else {
      return entry.name;
    }
  }

  private formatDateString(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
  }

  private hasAspect(aspects: string[], aspectToCheckIfExists: string): boolean {
    if (aspects) {
      return aspects.some((aspect: string) => aspect === aspectToCheckIfExists);
    }
    return false;
  }
}

import { Routes } from '@angular/router';
import { LanguageComponent } from "./language/language.component";
import { AuthGuard } from "@alfresco/adf-core";
import { AuthenticationErrorPageComponent } from "./authentication-error-page/authentication-error-page.component";

export const APP_ROUTES: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    outlet: 'dialog',
    loadChildren: () => import('@alf-nx-workspace/eben/feature').then((module) => module.EbenFeatureModule)
  },
  {
    path: ':language/flow',
    canActivate: [AuthGuard],
    loadChildren: () => import('@alf-nx-workspace/flow/feature/shell').then((module) => module.FlowFeatureShellModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: LanguageComponent,
    pathMatch: 'full'
  },
  {
    path: 'authentication-error',
    component: AuthenticationErrorPageComponent,
    pathMatch: 'full'
  },
  {
    path: '**',
    canActivate: [AuthGuard],
    component: LanguageComponent,
    pathMatch: 'full'
  },
];

export enum UrlaubsfreigabeDelegierenFreigabeVariables {
  "genehmigendePersonFullname"= "genehmigendePersonFullname",
  "urlaubsgenehmigerStellvertretungFullname"= "urlaubsgenehmigerStellvertretungFullname",
  "isOhneStellvertreter" = "isOhneStellvertreter",
  "currentPersonenFullnames" = "currentPersonenFullnames",
  "ablehnenbegruendung" = "ablehnenbegruendung",
  "currentLeiterFullname" = "currentLeiterFullname",
  "bestaetgtAmUm" = "bestaetgtAmUm"
}

export const UrlaubsfreigabeDelegierenFreigabeVariablesSearchable = [
  UrlaubsfreigabeDelegierenFreigabeVariables.urlaubsgenehmigerStellvertretungFullname,
  UrlaubsfreigabeDelegierenFreigabeVariables.currentPersonenFullnames

]

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bokuTaskName',
  standalone: true
})
export class BokuTaskNamePipe implements PipeTransform {
  transform(taskName: string): string {
    return taskName || 'TASK.UNDEFINED_TASKNAME';
  }
}

import { Component, Input } from '@angular/core';
import { EbenProcessInstance } from '@alf-nx-workspace/eben/interfaces';
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { NgIf } from "@angular/common";
import { MasterzulassungEinschreibungVariables } from "../masterzulassung-einschreibung.variables";


@Component({
  selector: 'masterzulassung-einschreibung-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent]
})
export class InformationTemplateComponent {

  @Input()
  process: EbenProcessInstance;

  @Input()
  workflowAdmin = false;

  @Input()
  public variableMap: Map<string, string>;
  public readonly fields = MasterzulassungEinschreibungVariables;

  public getInformationElementStudentFullname() {
    if (this.fields.vorname && this.fields.nachname) {
      return {
        name: "MAZUL_EINSCHR.ANTRAGSTELLER",
        value: `${this.variableMap.get(this.fields.vorname)} ${this.variableMap.get(this.fields.nachname)} (${this.variableMap.get(this.fields.matrikelnummer)})`
      };
    }
  }

  getMasterstudium() {
    return {
      name: "Masterstudium",
      value: this.variableMap.get(this.fields.masterstudium)
    };
  }
}

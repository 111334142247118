import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { EbenState } from './+store/eben.state';

@NgModule({
  imports: [CommonModule, NgxsModule.forFeature([EbenState])],
  providers: [],
})
export class EbenDataAccessModule {
  static forRoot(): ModuleWithProviders<EbenDataAccessModule> {
    return {
      ngModule: EbenDataAccessModule,
    };
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InformationElement } from '@alf-nx-workspace/shared/interfaces';
import { DatePipe, NgIf } from '@angular/common';
import { HomeofficeVereinbarungVariables } from '../homeoffice-vereinbarung.variables';
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";

@Component({
  selector: 'homeoffice-vereinbarung-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent],
  providers: [DatePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {
  public readonly fields = HomeofficeVereinbarungVariables;

  public getInformationElementProcessEnded() {
    return { name: 'PROCESS.ENDED', value: this.process.ended.toString() };
  }
  public getInformationElement(name: string): InformationElement {
    return {
      name: 'HOMEOFFICE-VEREINBARUNG.' + name.toUpperCase(),
      value: this.variableMap.get(name),
    };
  }

  public getInformationElementBvBis() {

    const bvBisValue: string = this.variableMap.get(this.fields.bvBis);
    const bvBis: string = bvBisValue ? bvBisValue : 'HOMEOFFICE-VEREINBARUNG.BVUNBEFRISTET';

    return {
      name: 'HOMEOFFICE-VEREINBARUNG.BVBIS',
      value: bvBis,
    };
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationTemplateComponent } from './information-template/information-template.component';
import { FORM_PROCESSOR_MAPPING, WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING } from '@alf-nx-workspace/shared/utils';

import { SharedUiModule } from "@alf-nx-workspace/shared/ui";
import { UrlaubsfreigabeDelegierenFreigabeService } from "./urlaubsfreigabe-delegieren-freigabe.service";
import { UrlaubsfreigabeDelegierenFreigabeZuordnungueberpruefenProcesser } from "./form-processors/urlaubsfreigabe-delegieren.freigabe-zuordnungueberpruefen.processer";
import { UrlaubsfreigabeDelegierenFreigabeZuordnungbestaetigenProcesser } from "./form-processors/urlaubsfreigabe-delegieren.freigabe-zuordnungbestaetigen.processor";
import { UrlaubsfreigabeDelegierenFreigabeVariablesSearchable } from "./urlaubsfreigabe-delegieren-freigabe.variables";


@NgModule({

  providers: [
    { provide: FORM_PROCESSOR_MAPPING, useValue: UrlaubsfreigabeDelegierenFreigabeZuordnungueberpruefenProcesser, multi: true },
    { provide: FORM_PROCESSOR_MAPPING, useValue: UrlaubsfreigabeDelegierenFreigabeZuordnungbestaetigenProcesser, multi: true },
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: UrlaubsfreigabeDelegierenFreigabeService, multi: true},
    {provide: WORKFLOW_VARIABLE_MAPPING, useValue: UrlaubsfreigabeDelegierenFreigabeVariablesSearchable, multi: true}
  ],
  imports: [CommonModule, SharedUiModule, InformationTemplateComponent],
})

export class UrlaubsfreigabeDelegierenFreigabeModule {
}

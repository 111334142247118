import { FormProcessor } from '@alf-nx-workspace/eben/interfaces';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';


export const FORM_PROCESSOR_MAPPING = new InjectionToken('FORM_PROCESSOR_MAPPING');

@Injectable({
  providedIn: 'root',
})
export class FormProcessorService {
  constructor(
    public injector: Injector,
    @Inject(FORM_PROCESSOR_MAPPING)
    public processorMapping: FormProcessor[]
  ) {
  }

  public getFormProcessor(name: string): FormProcessor {
    if (name && this.processorMapping) {
      const className: FormProcessor = this.processorMapping.find(
        (mapping: FormProcessor) => mapping.FORM_NAME === name
      );
      return this.injector.get(className, null);
    }
  }

  public getBaseProcessor() {
    const className: FormProcessor = this.processorMapping.find(
      (mapping: FormProcessor) => mapping.FORM_NAME === 'BaseProcessor'
    );
    return this.injector.get(className, null);
  }
}

import { Component, inject } from '@angular/core';

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { RxState, selectSlice } from "@rx-angular/state";
import { ActivitiContentService } from "@alfresco/adf-core";
import { of } from "rxjs";
import { MatButtonModule } from "@angular/material/button";
import { DokumentenVerteilungVariables } from "../dokumenten-verteilung.variables";
import { fromPromise } from "rxjs/internal-compatibility";
import { switchMap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { WorkflowApprover } from "@alf-nx-workspace/flow/interfaces";
import { WorkflowResultProtocol, WorkflowVariables } from '@alf-nx-workspace/shared/interfaces';
import { TranslateInterpolatePipe } from "@alf-nx-workspace/shared/ui";


interface ResultTemplateComponentState {
  variableMap: Map<string, string>;
  workflowResult: string,
  approved: WorkflowApprover[];
  rejectedBy: string;
  rejectedReason: string;
  contentId: number;
  workflowResultProtocol: WorkflowResultProtocol[];
}

@Component({
  selector: 'dokumenten-verteilung-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule, TranslateInterpolatePipe],
  providers: [RxState]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {

  readonly activitiContentService = inject(ActivitiContentService);
  readonly fields = DokumentenVerteilungVariables;
  readonly rxState = inject<RxState<ResultTemplateComponentState>>(RxState);
  readonly vm$ = this.rxState.select(selectSlice(["workflowResult", "approved", "rejectedBy", "rejectedReason", "workflowResultProtocol"]));

  private filename;
  public reason;
  public hasReviewer;

  ngOnInit() {
    this.connectState();
    this.sideEffects();
    this.checkIfReasonIsExpiration();
  }

  private connectState() {
    this.rxState.connect("workflowResult", this.workflowResultSubject);
    this.rxState.connect("contentId", this.variableMapSubject,
      (oldState, value) => parseInt(value.get(this.fields.document))
    );
    this.rxState.connect("workflowResultProtocol", this.variableMapSubject, (oldState, value) => {
      try {
        return JSON.parse(this.variableMap.get(WorkflowVariables._bokuFlow_workflowResultProtocolJSON));
      } catch (e) {
        return [];
      }
    })
  }

  private sideEffects() { }

  public downloadContent($event: MouseEvent) {
    $event.preventDefault();

    const contentId = this.rxState.get('contentId');
    fromPromise(this.activitiContentService.contentApi.getContent(contentId))
      .pipe(
        switchMap(({ name }) => of(name)),
        switchMap((name) => {
          this.filename = name;
          return this.activitiContentService.contentApi.getRawContent(contentId);
        })
      )
      .subscribe((content) => saveAs(content, this.filename));
  }

  public getContentUrl(): string {
    return this.activitiContentService.contentApi.getRawContentUrl(this.rxState.get('contentId'));
  }

  private checkIfReasonIsExpiration() {
    let protocol= [];
    this.reason = '';

    try {
      protocol = JSON.parse(this.variableMap.get(WorkflowVariables._bokuFlow_workflowResultProtocolJSON));
    } catch (e) {
      return;
    }

    const hasBeenExpired = protocol.every(entry => entry.reason == 'DOKUMENTEN-VERTEILUNG.PROCESS_ENDED.FAELLIGKEITSDATUM-UBERSCHRITTEN');
    if (hasBeenExpired) {
      this.reason = protocol[0].reason;
    }
    this.hasReviewer = protocol.every(entry => entry.by !== '');
  }
}

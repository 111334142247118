import { FormModel, FormValues } from '@alfresco/adf-core';
import { FormFieldWarning } from '@alf-nx-workspace/eben/interfaces';

const PREFIX = 'eben';

export class GetTaskDetails {
  public static readonly type = PREFIX + '.loadTaskToState';

  constructor(public taskId: string) {}
}

export class ClearTaskFromState {
  public static readonly type = PREFIX + '.clearTaskFromState';

  constructor(public taskId: string) {}
}

export class SetFormModel {
  public static readonly type = PREFIX + '.setFormModel';

  constructor(public json: string) {}
}

export class SetFormValues {
  public static readonly type = PREFIX + '.setFormValues';

  constructor(public fieldId: string, public values: FormValues) {}
}

export class SetFormErrorFields {
  public static readonly type = PREFIX + '.setFormErrorFields';

  constructor(public json: string[]) {}
}

export class SetFormWarningFields {
  public static readonly type = PREFIX + '.setFormWarningFields';

  constructor(public warningFields: FormFieldWarning[]) {}
}

export class ToggleShowFormHelp {
  public static readonly type = PREFIX + '.toggleShowFormHelp';
}

export class SetSelectedFormFieldHelp {
  public static readonly type = PREFIX + '.setSelectedFormFieldHelp';

  constructor(public formFieldId: string) {}
}

export class SetFormModelDirty {
  public static readonly type = PREFIX + '.setFormModelDirty';

  constructor(public dirty: boolean) {}
}

export class ClearFormModel {
  public static readonly type = PREFIX + '.clearFormModel';
}

export class SetOutcomesExemptFromValidation {
  public static readonly type = PREFIX + '.setOutcomesExemptFromValidation';

  constructor(public outcomes: string[]) {}
}

export class SetOutcomesToDisable {
  public static readonly type = PREFIX + '.setOutcomesToDisable';

  constructor(public outcomes: string[]) {}
}

export class WorkflowStarted {
  public static readonly type = PREFIX + '.workflowStarted';
}

export class TaskCompleted {
  public static readonly type = PREFIX + '.taskComplete';
}

export class FormSaved {
  public static readonly type = PREFIX + '.formSaved';

  constructor(public form: FormModel) {}
}

<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.workflowResult === workflowResults.successful">
    <div class="mat-caption">{{'APPROVED_BY' | translate}}</div>
    <div class="mat-caption" *ngFor="let approved of vm.approved">
      <div>{{approved['by']}} {{'ON' | translate}} {{approved['on'] | date}}</div>
    </div>
    <a [href]="getContentUrl()"
       (click)="downloadContent($event)">{{ 'LINK_TO_PDF' | translate }}</a>
  </ng-container>

  <ng-container *ngIf="vm.workflowResult === workflowResults.unsuccessful">
    <div class="mat-caption">{{'REJECTED_BY' | translate}}</div>
    <div class="mat-caption">{{vm.rejectedBy}}</div>
    <div class="mat-caption">{{vm.rejectedReason}}</div>
  </ng-container>
</ng-container>

import { inject, Injectable } from "@angular/core";
import { defaultEbenTaskQueryRepresentation, EbenTaskQueryRepresentation } from "@alf-nx-workspace/eben/interfaces";
import { Router } from "@angular/router";

@Injectable({providedIn: 'root'})
export class FlowTaskListService {

  readonly router = inject(Router);

  public onQueryChange(query: EbenTaskQueryRepresentation) {
    // Remove default query params from url
    const modifiedQueryParams = {};
    for (const key in query) {
      if (defaultEbenTaskQueryRepresentation[key] != query[key]) {
        modifiedQueryParams[key] = query[key];
      }
    }

    this.router.navigate([], {queryParams: modifiedQueryParams});
    return query;
  }
}

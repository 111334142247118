import { AlfrescoApiService, AppConfigService } from '@alfresco/adf-core';
import { SearchConfiguration } from '@alfresco/adf-content-services';
import { FlowBaseQueryBuilderService } from './flow-base-query-builder-service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FlowSearchQueryBuilderService extends FlowBaseQueryBuilderService {

  public isFilterServiceActive(): boolean {
    return false;
  }

  constructor(appConfig: AppConfigService, alfrescoApiService: AlfrescoApiService) {
    super(appConfig, alfrescoApiService);
  }

  public loadConfiguration(): SearchConfiguration {
    return this.appConfig.get<SearchConfiguration>('search');
  }
}

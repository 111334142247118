import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetLanguage } from "./language.actions";


export interface LanguageStateModel {
  language: string;
}

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    language: ''
  }
})
@Injectable()
export class LanguageState {

  @Selector()
  public static language(state: LanguageStateModel): string {
    return state.language;
  }


  @Action(SetLanguage)
  protected setLanguage(ctx: StateContext<LanguageStateModel>, action: SetLanguage) {
    ctx.patchState({
      language: action.language
    });
  }
}

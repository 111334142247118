<ng-container *ngIf="workflowResult === workflowResults.successful">
  <div class="mat-caption">{{ 'APPROVED_BY' | translate }}</div>
  <div class="mat-caption" *ngFor="let approver of approvedProtocol">
    <div>{{ approver.by }} {{ 'ON' | translate }} {{ approver.on }}</div>
  </div>
  <a [href]="getContentUrl()"
     (click)="downloadContent($event)">{{ 'LINK_TO_DOCUMENT' | translate }}</a>
</ng-container>

<ng-container *ngIf="workflowResult === workflowResults.unsuccessful">

  <ng-container *ngIf="autoBeendetProtocol">
    <div class="margin-top-4">{{ autoBeendetProtocol.result | translate }}</div>
    <div class="mat-caption" [innerHTML]="autoBeendetProtocol.reason | translateInterpolate:variableMap"></div>
  </ng-container>

  <ng-container *ngIf="abgebrochenProtocols.length">
    <div class="margin-top-4">{{ abgebrochenProtocols[0].result | translate }}</div>
    <div class="mat-caption" *ngFor="let abgebrochen of abgebrochenProtocols">{{ abgebrochen.by }}</div>
  </ng-container>

  <ng-container *ngFor="let declined of declinedProtocol">
    <div class="mat-caption margin-top-4">{{ 'REJECTED_BY' | translate }} {{ declined.by }}</div>
    <div class="mat-caption">{{ 'DOKUMENTEN-FREIGABE.FINALIZE-DOCUMENT.DECLINE-REASON' | translate }}</div>
    <div class="mat-caption rejected-reason"><b>{{ declined.reason }}</b></div>
  </ng-container>
</ng-container>



import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { FlowState } from "./flow.state";
import { map, withLatestFrom } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { FlowProcessDefinitionModel } from "@alf-nx-workspace/shared/interfaces";
import { AuthFacade } from "@alf-nx-workspace/auth/data-access";

const EXTERNAL_ID_PATTERN = /%externalId%/g;

@Injectable({providedIn: 'root'})
export class FlowSearchFacade {

  constructor(public authFacade: AuthFacade, public store: Store) {
  }

  public documentTypeSearchQuery(processDefinitionKey: string, processInstanceId: string): Observable<string> {

    if(!processDefinitionKey || !processInstanceId) {
      throw Error('1674643625713 missing parameter');
    }

    return this.getSearchParamsByProcessDefinitionKey(processDefinitionKey).pipe(
      map((flowProcessDefinitionModels: FlowProcessDefinitionModel[]) => {
        return flowProcessDefinitionModels.map((flowProcessDefinitionModel: FlowProcessDefinitionModel) => {
          return `((${flowProcessDefinitionModel.acsQuery.documentType}) AND (boku:processInstanceId:${processInstanceId}))`
        })
      }),
      map((queries: string[]) => queries.join(' OR ')),
    );
  }

  public myDocumentsSearchQuery(processDefinitionKey: string): Observable<string> {
    return this.getSearchParamsByProcessDefinitionKey(processDefinitionKey).pipe(
      map((flowProcessDefinitionModels: FlowProcessDefinitionModel[]) => {
        return flowProcessDefinitionModels.map((flowProcessDefinitionModel: FlowProcessDefinitionModel) => {
          return `((${flowProcessDefinitionModel.acsQuery.documentType}) AND (${flowProcessDefinitionModel.acsQuery.myDocumentPart}))`
        })
      }),
      map((queries: string[]) => queries.join(' OR ')),
      withLatestFrom(this.authFacade.user$),
      map(([query, user]) =>  query.replace(EXTERNAL_ID_PATTERN, user.externalId))
    )
  }

  private getSearchParamsByProcessDefinitionKey(processDefinitionKey: string): Observable<FlowProcessDefinitionModel[]> {
    return this.store.select(FlowState.flowProcessDefinitions).pipe(
      map((flowProcessDefinitionModels: FlowProcessDefinitionModel[]) => {
        if(processDefinitionKey) {
          return flowProcessDefinitionModels.filter((flowProcessDefinitionModel: FlowProcessDefinitionModel) => flowProcessDefinitionModel.processDefinitionKey === processDefinitionKey)
        }
        return flowProcessDefinitionModels;
      }),
      map((flowProcessDefinitionModels: FlowProcessDefinitionModel[]) => {
        return flowProcessDefinitionModels.filter((flowProcessDefinitionModel: FlowProcessDefinitionModel) => !!flowProcessDefinitionModel.acsQuery)
      })
    )
  }



}

import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, of, pipe } from 'rxjs';
import { Injectable } from '@angular/core';
import { EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';
import { Store } from '@ngxs/store';
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class EbenTaskFormResolver implements Resolve<Observable<EbenTaskDetail>> {
  constructor(public store: Store, private ebenfacade: EbenFacade) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EbenTaskDetail> {
    return this.ebenfacade.getTaskForm(route.params['taskId'])
      .pipe(
        catchError((error) => {
          return of(null);
        })
      );
  }
}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'shared-ui-dialog-flow-information',
  templateUrl: './dialog-flow-information.component.html',
  styleUrls: ['./dialog-flow-information.component.scss']
})
export class DialogFlowInformationComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogFlowInformationComponent>) { }

  ngOnInit(): void {
  }

  onDismiss() {
    this.dialogRef.close();
  }
}

import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { EbenPagination, EbenProcessInstance } from '@alf-nx-workspace/eben/interfaces';
import { FlowFacade } from '@alf-nx-workspace/flow/data-access';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EbenScopedProcessesResolver implements Resolve<Observable<EbenPagination<EbenProcessInstance>>> {
  constructor(private flowFacade: FlowFacade) {
  }

  resolve(route: ActivatedRouteSnapshot): Observable<EbenPagination<EbenProcessInstance>> {
    return this.flowFacade.scopeWorkflows$.pipe(take(1));
  }
}

import { EbenGenericError } from "./eben-error.model";

export class TaskGenericError extends EbenGenericError {
  constructor(public error: Error, public errorKey: string, public taskId: string) {
    super();
  }
}

export class TaskSaveError extends TaskGenericError {
}

export class TaskCompleteError extends TaskGenericError {
}

export class TaskClaimError extends TaskGenericError {
}

export class TaskUnclaimError extends TaskGenericError {
}

import { NgModule } from '@angular/core';
import { FORM_PROCESSOR_MAPPING, WORKFLOW_SERVICE_MAPPING } from '@alf-nx-workspace/shared/utils';

import { DokumentenVerteilungService } from "./dokumenten-verteilung.service";
import { DokumentenVerteilungAuftragProcessor } from "./form-processors/dokumenten-verteilung-auftrag.processor";


@NgModule({
  providers: [
    {provide: FORM_PROCESSOR_MAPPING, useValue: DokumentenVerteilungAuftragProcessor, multi: true},
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: DokumentenVerteilungService, multi: true},
  ],
})
export class DokumentenVerteilungModule {
}

import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { WorkflowServiceModel } from "@alf-nx-workspace/flow/interfaces";

export const WORKFLOW_SERVICE_MAPPING = new InjectionToken('WORKFLOW_PRIORITY_MAPPING');
export const WORKFLOW_VARIABLE_MAPPING = new InjectionToken('WORKFLOW_VARIABLE_MAPPING');

@Injectable({
  providedIn: 'root',
})
export class WorkflowService {
  constructor(
    public injector: Injector,
    @Inject(WORKFLOW_SERVICE_MAPPING)
    public workflowMappings: WorkflowServiceModel[]
  ) {
  }

  public getServiceByProcessDefinitionKey(name: string): WorkflowServiceModel {
    const className: WorkflowServiceModel = this.workflowMappings.find((mapping: WorkflowServiceModel) => {
      return mapping["WORKFLOW_NAME"] === name;
    });
    return this.injector.get(className, null);
  }
}

import { Component, inject } from '@angular/core';

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { ActivitiContentService } from "@alfresco/adf-core";
import { of } from "rxjs";
import { MatButtonModule } from "@angular/material/button";
import { DokumentenFreigabeVariables } from "../dokumenten-freigabe.variables";
import { fromPromise } from "rxjs/internal-compatibility";
import { switchMap } from "rxjs/operators";
import { saveAs } from 'file-saver';
import { WorkflowResultProtocol, WorkflowVariables } from '@alf-nx-workspace/shared/interfaces';
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { TranslateInterpolatePipe } from "@alf-nx-workspace/shared/ui";
import { ProcessVariableUtils } from '@alf-nx-workspace/shared/utils';

@Component({
  selector: 'dokumenten-freigabe-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, TranslateInterpolatePipe],

})
export class ResultTemplateComponent extends BaseResultTemplateComponent{

  readonly activitiContentService = inject(ActivitiContentService);
  readonly fields = DokumentenFreigabeVariables;

  public autoBeendetProtocol: WorkflowResultProtocol = null;
  public approvedProtocol: WorkflowResultProtocol[] = [];
  public declinedProtocol: WorkflowResultProtocol[] = [];
  public abgebrochenProtocols: WorkflowResultProtocol[] = [];

  ngOnInit() {
    let protocol:WorkflowResultProtocol[] = [];
    try {
      protocol = JSON.parse(this.variableMap.get(WorkflowVariables._bokuFlow_workflowResultProtocolJSON));
    } catch (e) {
      return;
    }

    if (protocol && !Array.isArray(protocol) || protocol && protocol.length == 0) {
      return;
    }


    this.autoBeendetProtocol = this.getAutoBeendetProtocol(protocol);
    this.abgebrochenProtocols = this.getAbgebrochenProtocols(protocol);

    if(this.autoBeendetProtocol) {
      return;
    }

    this.declinedProtocol = this.getDeclinedResults(protocol);
    this.approvedProtocol = this.getApprovedResults(protocol);

  }

  private getAutoBeendetProtocol(protocol: WorkflowResultProtocol[]): WorkflowResultProtocol {
    return protocol.find(entry => {
      if (typeof entry.reason !== 'string' || entry.reason.trim() === '') {
        return false;
      }
      const [prefix] = entry.reason.split('.');
      return entry.result == 'WORKFLOW.AUTO_BEENDET' && prefix == 'DOKUMENTEN-FREIGABE';
    });
  }

  private getAbgebrochenProtocols(protocol: WorkflowResultProtocol[]): WorkflowResultProtocol[] {
    return protocol.filter(entry => entry.result == 'DOKUMENTEN-FREIGABE.ABGEBROCHEN');
  }

  private getDeclinedResults(protocol: WorkflowResultProtocol[]): WorkflowResultProtocol[] {
    const p = protocol.filter(entry => entry.result == 'ABLEHNUNG');
    // to ensure backwards compatibility
    if (p.length && (p[0].reason !== null || p[0].reason !== "")) {
      p[0].reason = this.variableMap.get(this.fields.begruendung);
    }
    // --
    return p;
  }

  private getApprovedResults(protocol: WorkflowResultProtocol[]) {
    return protocol.filter(entry => entry.result == 'FREIGABE');
  }

  downloadContent($event: MouseEvent) {
    $event.preventDefault();

    const contentId = parseInt(this.variableMap.get(this.fields.approvedDoc));
    let filename = '';
    fromPromise(this.activitiContentService.contentApi.getContent(contentId))
      .pipe(
        switchMap(({name}) => of(name)),
        switchMap((name) => {
          filename = name;
          return this.activitiContentService.contentApi.getRawContent(contentId);
        })
      )
      .subscribe((content) => saveAs(content, filename));
  }

  getContentUrl(): string {
    const contentId = parseInt(this.variableMap.get(this.fields.approvedDoc));
    return this.activitiContentService.contentApi.getRawContentUrl(contentId);
  }


}

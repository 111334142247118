export const START_FORM_PREFIX = 'start-form'
export const START_FORM_URL = `${START_FORM_PREFIX}/:processDefinitionKey`;

export const TASK_FORM_PREFIX = 'task-form';
export const TASK_FORM_URL = `${TASK_FORM_PREFIX}/:taskId`;


export const TASK_ENDED_URL = 'task-ended';

export const TASK_NOT_FOUND_IN_TIME_URL = `task-not-found-in-time/:taskId`;

export const TASK_NOT_FOUND_URL = 'task-not-found/:taskId';

export const WORKFLOW_NOT_FOUND_URL = 'workflow-not-found/:processInstanceId';

const PROCESS_ENDED_PREFIX = 'process-ended';
export const PROCESS_ENDED_URL = `${PROCESS_ENDED_PREFIX}/:processInstanceId`;

const TASK_NOT_AUTHORIZED_PREFIX = 'task-no-permission';
export const TASK_NOT_AUTHORIZED_URL = `${TASK_NOT_AUTHORIZED_PREFIX}/:taskId`;

const WORKFLOW_DETAILS_PREFIX = 'workflow-details';
export const WORKFLOW_DETAILS_URL = `${WORKFLOW_DETAILS_PREFIX}/:processInstanceId`;

const LATEST_TASK_FORM_PREFIX = 'latest-task-form';
export const LATEST_TASK_FORM_URL = `${LATEST_TASK_FORM_PREFIX}/:processInstanceId`;

<ng-container *ngIf='variableMap'>
  <ng-container *ngIf='workflowAdmin'>
    <shared-ui-information-element
      *ngIf="this.process.ended"
      [information]='getInformationElementProcessEnded()'
      type='date'
      format='dd.MM.yyyy HH:mm'
    ></shared-ui-information-element>
  </ng-container>
  <shared-ui-information-element [information]='getDocumentTitle()' ></shared-ui-information-element>
</ng-container>

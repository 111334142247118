import { Select, Store } from '@ngxs/store';

import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageState } from "./language.state";
import { SetLanguage } from "./language.actions";


@Injectable({ providedIn: 'root' })
export class LanguageFacade {
  @Select(LanguageState.language)
  public language$: Observable<string>;

  private readonly store = inject(Store);

  public setLanguage(language: string) {
    return this.store.dispatch(new SetLanguage(language));
  }
}

<mat-dialog-content>
  <div class='margin-16'>
    <div class="flex-container center-align">
      <shared-ui-flow-icon size='xlarge'
                           icon='alert-triangle.svg'>
      </shared-ui-flow-icon>
      <div class="margin-left-32" [innerHTML]="'BANNER.ALF-670' | translate"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align='end'>
  <button mat-button (click)='onDismiss()'>{{'BANNER.DISMISS' | translate}}</button>
</mat-dialog-actions>

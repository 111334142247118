import { TaskQueryRepresentation } from '@alfresco/js-api';

export interface EbenTaskQueryRepresentation extends TaskQueryRepresentation {
  includeProcessVariables?: boolean;
  includeTaskLocalVariables?: boolean;
}

export const defaultEbenTaskQueryRepresentation: EbenTaskQueryRepresentation = {
  state: TaskQueryRepresentation.StateEnum.Active,
  sort: TaskQueryRepresentation.SortEnum.CreatedDesc,
  page: 0,
  size: 25
}

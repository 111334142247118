import { Component } from '@angular/core';

import { NgIf } from "@angular/common";
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";

import { InformationElement } from "@alf-nx-workspace/shared/interfaces";

import { BaseInformationTemplateComponent } from "../../base-information-template.component";

@Component({
  selector: 'example-workflow-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent]
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public getInformationElement(): InformationElement {
    return {
      name: "EXAMPLE_EXAMPLE.ANTRAGSTELLER",
      value: "Alex Beispiel"
    };
  }

  public getInformationElementProcessEnded() {
    return { name: 'PROCESS.ENDED', value: this.process.ended.toString() };
  }
}

export enum WorkflowResult {
  successful = 'successful',
  unsuccessful = 'unsuccessful',
}

export interface WorkflowResultProtocol {
  by: string;
  on: string;
  result: 'FREIGABE' | 'ABLEHNUNG' | 'WORKFLOW.AUTO_BEENDET' | 'DOKUMENTEN-FREIGABE.ABGEBROCHEN';
  reason: string;
}

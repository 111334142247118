import { Component, inject, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackbarDataModel, SnackbarType } from '@alf-nx-workspace/shared/interfaces';
import { TranslateModule, TranslatePipe } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule, NgIf } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'shared-ui-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatIconModule, MatButtonModule]
})
export class SnackbarComponent {

  snackBarRef = inject(MatSnackBarRef);

  public success: string = SnackbarType.success;
  public error: string = SnackbarType.error;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarDataModel) {}

}

import { EbenGenericError } from "./eben-error.model";

export class ProcessGenericError extends EbenGenericError {
  constructor(public error: Error, public id: string) {
    super();
  }
}

export class ProcessStartError extends ProcessGenericError {
  constructor(public error: Error, public processDefinitionId: string) {
    super(error, processDefinitionId);
  }
}

export class ProcessInstanceNotFoundError extends ProcessGenericError {
  constructor(public error: Error, public processInstanceId: string) {
    super(error, processInstanceId);
  }
}

import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {EbenFacade} from '@alf-nx-workspace/eben/data-access';
import {Store} from '@ngxs/store';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {FlowFacade, GetFlowProcessDefinitions} from '@alf-nx-workspace/flow/data-access';
import {BpmService} from '@alf-nx-workspace/shared/utils';
import {FormService} from '@alfresco/adf-core';
import {FormDefinitionRepresentation, ProcessDefinitionRepresentation} from "@alfresco/js-api";
import { ProcessService } from "@alfresco/adf-process-services";
import { FormDefinitionModel } from "@alfresco/adf-core/lib/form/models/form-definition.model";

interface StartFormResolverData {
    processDefinitionKey?: string,
    processDefinition?: ProcessDefinitionRepresentation,
    formDefinition?: FormDefinitionModel

}

@Injectable({
    providedIn: 'root',
})
export class EbenStartFormResolver implements Resolve<Observable<StartFormResolverData>> {

    readonly store = inject(Store);
    readonly flowFacade = inject(FlowFacade);
    readonly ebenFacade = inject(EbenFacade);
    readonly formService = inject(FormService);
    readonly bpmService = inject(BpmService);
    readonly processService = inject(ProcessService);

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StartFormResolverData> {
        const processDefinitionKey: string = route.params['processDefinitionKey'];
        return this.flowFacade.getFlowDefinitionByKey(processDefinitionKey)
          .pipe(
            switchMap((flowProcessDefinition) => this.processService.getProcessDefinitions(flowProcessDefinition.appId)),
            map((processDefinitions: ProcessDefinitionRepresentation[]) => processDefinitions.find((processDefinition) => processDefinition.key === processDefinitionKey)),
            mergeMap((processDefinition: ProcessDefinitionRepresentation) => {
                if (!processDefinition.hasStartForm) {
                    return of({
                        processDefinition: processDefinition,
                        formDefinition: null
                    })
                }
                return this.formService.getStartFormDefinition(processDefinition.id).pipe(
                    map((formDefinition: FormDefinitionRepresentation) => {
                        return {
                            processDefinitionKey: processDefinitionKey,
                            processDefinition: processDefinition,
                            formDefinition: formDefinition
                        }
                    })
                )

            }),
            catchError((error) => {
              return of({
                processDefinitionKey: processDefinitionKey,
                processDefinition: null,
                formDefinition: null
              });
            })
        )
    }
}

import { NgModule } from '@angular/core';
import { FORM_PROCESSOR_MAPPING } from '@alf-nx-workspace/shared/utils';
import { BaseProcessor } from "./base.processor";


@NgModule({
  providers: [
    BaseProcessor,
    {provide: FORM_PROCESSOR_MAPPING, useValue: BaseProcessor, multi: true},
  ]
})

export class BaseModule {
}

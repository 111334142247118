import { Component, Input } from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
  selector: 'shared-ui-flow-icon',
  templateUrl: './flow-icon.component.html',
  styleUrls: ['./flow-icon.component.scss'],
  standalone: true,
  imports: [CommonModule]

})
export class FlowIconComponent {
  @Input()
  icon: string;

  @Input()
  invert: number;

  @Input()
  size: 'xlarge' | 'large' | 'normal' | 'small' | 'xsmall' = 'normal';

  @Input()
  hoverable = false;

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FlowProcessDefinitionModel } from '@alf-nx-workspace/shared/interfaces';
import { Observable } from 'rxjs';
import { FlowFacade } from '@alf-nx-workspace/flow/data-access';

@Component({
  selector: 'shared-ui-process-definition-icon',
  templateUrl: './process-definition-icon.component.html',
  styleUrls: ['./process-definition-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessDefinitionIconComponent {
  @Input()
  set processDefinitionKey(value) {
    this.processDefinition$ = this.flowFacade.getFlowDefinitionByKey(value);
  }

  public processDefinition$: Observable<FlowProcessDefinitionModel>;

  constructor(public flowFacade: FlowFacade) {}
}

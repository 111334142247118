<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.workflowResult === workflowResults.successful">
    <div class="mat-caption">{{'APPROVED_BY' | translate}}</div>
    <div class="mat-caption" *ngFor="let approved of vm.approved">
      <div>{{approved['by']}} {{'ON' | translate}} {{approved['on'] | date}}</div>
    </div>
    <a [href]="getContentUrl()"
       (click)="downloadContent($event)">{{ 'LINK_TO_PDF' | translate }}</a>
  </ng-container>

  <ng-container *ngIf="vm.workflowResult === workflowResults.unsuccessful">
    <!-- Abwärtskompatibel für ältere Reise Workflows die noch kein _bokuFlow_workflowResultProtocolJSON gesetzt haben -->
    <ng-container *ngIf="vm.rejectedBy">
      <div class="mat-caption">{{'REJECTED_BY' | translate}}</div>
      <div class="mat-caption">{{vm.rejectedBy}}</div>
      <div class="mat-caption">{{vm.rejectedReason}}</div>
    </ng-container>

    <!-- wenn _bokuFlow_workflowResultProtocolJSON existiert -->
    <ng-container *ngIf="vm.protocol.length">
      <div class="mat-caption" *ngFor="let entry of vm.protocol">
        <div>{{ entry['result'] | translate }}: {{ entry['reason'] | translate }}</div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

import { Injectable } from '@angular/core';
import { BaseProcessor } from '../../base.processor';
import { FormFieldModel, FormModel } from '@alfresco/adf-core';
import { ReiseAufVariables } from "../reise-auf.variables";

@Injectable({ providedIn: 'root' })
export class ReiseAufWeiterleitenProcessor extends BaseProcessor {
  static FORM_NAME = 'reiseAufWeiterleitenForm';
  public readonly FORM_NAME = ReiseAufWeiterleitenProcessor.FORM_NAME;

  public onLoaded(form: FormModel) {
    this.vorgesetzterPlaceholder(form);
    super.onLoaded(form);
  }

  private vorgesetzterPlaceholder(form: FormModel) {
    const fields: FormFieldModel[] = form.getFormFields();
    const vorgesetzterIdField = fields.find((f) => f.id === ReiseAufVariables.vorgesetzterId);
    const re = /^\${[a-z\d]+}$/i;

    // checks if Peoplewidget has got an expression inside placeholder e.g ${variable} is true
    // in aps variable inside expression must exist within the form
    if (vorgesetzterIdField.placeholder && re.test(vorgesetzterIdField.placeholder)) {
      const vorschlagVorgesetzteField = fields.find((f) => f.id === ReiseAufVariables.vorschlagVorgesetzte);
      vorgesetzterIdField.placeholder = vorschlagVorgesetzteField.value;
      this.updateField(vorgesetzterIdField);
    }
  }
}

import { ErrorHandler, inject, NgZone } from "@angular/core";
import { SnackbarService } from "@alf-nx-workspace/shared/utils";
import { EbenGenericError } from "@alf-nx-workspace/eben/interfaces";

export class FlowErrorHandler implements ErrorHandler {
  readonly snackbarService = inject(SnackbarService);
  readonly zone = inject(NgZone);

  handleError(error: unknown): void {
    if (error instanceof EbenGenericError) {
      this.zone.run(() => {
        this.snackbarService.error(error, true);
      })
    } else {
      console.error(error);
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIf } from "@angular/common";
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { DokumentenFreigabeVariables } from "../dokumenten-freigabe.variables";
import { InformationElement } from "@alf-nx-workspace/shared/interfaces";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";

@Component({
  selector: 'dokumenten-freigabe-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = DokumentenFreigabeVariables;

  public getInformationElementProcessEnded(): InformationElement {
    return {name: 'PROCESS.ENDED', value: this.process.ended.toString()};
  }

  public getDocumentTitle(): InformationElement {
    return {name: 'DOKUMENTEN-FREIGABE.DOCUMENT', value: this.variableMap.get(this.fields.titel)};
  }

}

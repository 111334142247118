<div
  [class]="size"
  [ngClass]="{
    hoverable: hoverable
  }"
  [style.background-image]="'url(../../assets/icons/' + icon + ')'"
  [style.background-size]="'contain'"
  [style.filter]="'invert(' + invert + ')'"
  class="icon"
></div>

import { Component, Input } from '@angular/core';
import { InformationElement } from '@alf-nx-workspace/shared/interfaces';
import { CommonModule, DatePipe, NgIf } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'shared-ui-information-element',
  templateUrl: './information-element.component.html',
  styleUrls: ['./information-element.component.scss'],
  imports: [NgIf, DatePipe, TranslateModule, CommonModule],
  standalone: true
})
export class InformationElementComponent {
  @Input()
  information: InformationElement;

  @Input()
  type: 'text' | 'date' | 'boolean' | 'raw' = 'text';

  @Input()
  format: 'dd.MM.yyyy' | 'dd.MM.yyyy HH:mm' = 'dd.MM.yyyy';
}

import { Component, inject } from '@angular/core';

import { TranslateModule } from "@ngx-translate/core";
import { fromPromise } from "rxjs/internal-compatibility";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { of, Subject } from "rxjs";
import { RxState, selectSlice } from "@rx-angular/state";
import { MasterzulassungVariables } from "../masterzulassung.variables";
import { ActivitiContentService } from "@alfresco/adf-core";
import { saveAs } from 'file-saver';
import { RelatedContentRepresentation } from "@alfresco/js-api/typings/src/api/activiti-rest-api/model/relatedContentRepresentation";
import { AsyncPipe, DatePipe, NgForOf, NgIf } from "@angular/common";
import { WorkflowResultProtocol } from "@alf-nx-workspace/shared/interfaces";
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { WorkflowApprover } from "@alf-nx-workspace/flow/interfaces";
import { TranslateInterpolatePipe } from "@alf-nx-workspace/shared/ui";

interface ResultTemplateComponentState {
  variableMap: Map<string, string>,
  workflowResult: string,
  approved: WorkflowApprover[],
  rejectedBy: string,
  rejectedReason: string,
  contentId: number,
  filename: string,
  relatedContent: RelatedContentRepresentation,
  ausgestelltAm: Date,
  protocol: WorkflowResultProtocol[]
}

@Component({
  selector: 'interne-masterzulassung-result-template',
  template: `
    <ng-container *ngIf="vm$ | async as vm">
        <ng-container *ngIf="vm.ausgestelltAm && vm.contentId;">
            <a [href]="getContentUrl()" (click)="downloadContent($event, vm.contentId)">{{ vm.filename }}</a>
            <div class="mat-caption">ausgestellt am {{vm.ausgestelltAm | date: 'dd.MM.yyyy HH:mm'}}</div>
        </ng-container>

        <ng-container>
            <div class="mat-caption" *ngFor="let entry of vm.protocol">
                <div class="mat-caption rejected-reason">{{ entry['result'] | translate }}: <span [innerHtml]="entry['reason'] | translateInterpolate:variableMap"></span></div>
            </div>
        </ng-container>
    </ng-container>
  `,
  styles: [``],
  standalone: true,
  imports: [
    TranslateModule,
    AsyncPipe,
    NgIf,
    DatePipe,
    NgForOf,
    TranslateInterpolatePipe
  ]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {
  public readonly fields = MasterzulassungVariables;
  readonly rxState = inject<RxState<ResultTemplateComponentState>>(RxState);
  readonly activitiContentService = inject(ActivitiContentService);

  readonly vm$ = this.rxState.select(selectSlice(["filename", "contentId", "variableMap", "ausgestelltAm", "protocol"]));
  readonly downloadSubject = new Subject<number>();
  ngOnInit() {
    this.connectState();
    this.sideEffects();
  }
  private sideEffects() {
    this.rxState.hold(this.downloadSubject.pipe(
      switchMap((contentId) => fromPromise(this.activitiContentService.contentApi.getRawContent(contentId))),
      tap((content) => saveAs(content, this.rxState.get('filename')))
    ));
  }

  private connectState() {
    this.rxState.connect("variableMap", this.variableMapSubject);
    this.rxState.connect("ausgestelltAm", this.variableMapSubject, (oldState, value) => {
      return new Date(value.get(this.fields.ausgestelltAm));
    });
    this.rxState.connect("contentId", this.variableMapSubject, (oldState, value) => {
      try {
        return parseInt(value.get(this.fields.bescheid));
      } catch (e) {
        return null;
      }
    });
    this.rxState.connect("relatedContent", this.rxState.select("contentId").pipe(
      filter(contentId => !!contentId),
      switchMap(contentId => fromPromise(this.activitiContentService.contentApi.getContent(contentId)))
    ));
    this.rxState.connect("filename", this.rxState.select("relatedContent").pipe(
      map((value:RelatedContentRepresentation) => {
        return value.name;
      })
    ));
    this.rxState.connect("protocol", this.variableMapSubject, (oldState, value) => {
      try {
        return JSON.parse(this.variableMap.get(this.fields._bokuFlow_workflowResultProtocolJSON));
      } catch (e) {
        return [];
      }
    });
  }

  downloadContent($event, contentId) {
    $event.preventDefault();
    this.downloadSubject.next(contentId);
  }

  getContentUrl(): string {
    return this.activitiContentService.contentApi.getRawContentUrl(this.rxState.get('contentId'));
  }
}

export const FLOW_ROOT = 'flow';
export const FLOW_HOME_ROOT = 'home';
export const FLOW_MY_TASKS_ROOT = 'my-tasks';
export const FLOW_START_WORKFLOW_ROOT = 'start-workflow';
export const FLOW_MY_WORKFLOWS_ROOT = 'my-workflows';
export const FLOW_MY_DOCUMENTS_ROOT = 'my-documents';
export const FLOW_OE_WORKFLOWS_ROOT = 'oe-workflows';
export const FLOW_WORKFLOW_ADMIN_ROOT = 'workflow-admin';
export const FLOW_NO_PERMISSION = 'not-authorized';
export const FLOW_PROCESS_CURRENT_TASK = `process/:processInstanceId/current-task`;

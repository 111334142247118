export enum WEEKDAY {
  'monday' = 'Montag',
  'tuesday' = 'Dienstag',
  'wednesday' = 'Mittwoch',
  'thursday' = 'Donnerstag',
  'friday' = 'Freitag',
}

export enum ERREICHBARKEIT {
  'telefon' = 'Telefon',
  'mail' = 'E-Mail',
}

export interface HomeOfficeTag {
  wochentag: string;
  uhrzeitVon: string;
  uhrzeitBis: string;
  erreichbarkeit: ERREICHBARKEIT[];
  erreichbarkeitFree: string;
}

export interface HomeOfficeTagReadonly {
  wochentag: string;
  uhrzeitVon: string;
  uhrzeitBis: string;
  zeitspanne: string;
  erreichbarkeit: string;
  erreichbarkeitFree: string;
}

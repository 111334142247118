import { Component, inject, OnInit } from '@angular/core';
import { take } from "rxjs/operators";
import { FLOW_ROOT } from "@alf-nx-workspace/flow/data-access";
import { Router, RouterModule } from "@angular/router";
import { LanguageFacade } from "@alf-nx-workspace/language/data-access";

@Component({
  selector: 'alf-nx-workspace-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  standalone: true,
  imports: [RouterModule]
})
export class LanguageComponent implements OnInit {

  private router = inject(Router);
  private languageFacade = inject(LanguageFacade);

  ngOnInit(): void {
    this.languageFacade.language$.pipe(
      take(1),
    ).subscribe((language: string) => {
      let l = language ? language : navigator.language.split("-")[0];
      this.router.navigate(["/", l, FLOW_ROOT]);
    });
  }
}

import { AlfrescoApi, AlfrescoApiConfig, AlfrescoApiType, Emitters } from "@alfresco/js-api";
import { FlowOauth2Auth } from "./flow-oauth-2-auth";
import { Storage, ProcessClient, ContentClient, ProcessAuth, ContentAuth } from '@alfresco/js-api';

export class FlowAlfrescoApi extends AlfrescoApi {
  setConfig(config: AlfrescoApiConfig) {

    if (!config) {
      config = {} as AlfrescoApiConfig;
    }

    this.storage = new Storage();
    this.storage.setDomainPrefix(config.domainPrefix);

    this.config = new AlfrescoApiConfig(config);

    this.clientsFactoryFix();

    this.processClient = new ProcessClient(this.config, this.httpClient);

    this.errorListeners();
    this.initAuthFix(config);

    if(this.isLoggedIn()){
      this.emit('logged-in');
      this.bufferEvents.push('logged-in');
    }

    return config;
  }

  private initAuthFix(config: AlfrescoApiConfig): void {
    if (this.isOauthConfiguration()) {

      if (!this.oauth2Auth) {
        this.oauth2Auth = new FlowOauth2Auth(this.config, this, this.httpClient);
      } else {
        this.oauth2Auth.setConfig(this.config, this);
      }

      this.oauth2Auth?.on('logged-in', () => {
        this.emitBufferFix('logged-in');
      });

      this.setAuthenticationClientECMBPM(this.oauth2Auth.getAuthentication(), this.oauth2Auth.getAuthentication());
    } else {

      if (!this.processAuth) {
        this.processAuth = new ProcessAuth(this.config, this.httpClient);
      } else {
        this.processAuth.setConfig(this.config);
      }

      this.processAuth?.on('logged-in', () => {
        this.emitBufferFix('logged-in');
      });

      if (!this.contentAuth) {
        this.contentAuth = new ContentAuth(this.config, this, this.httpClient);
      } else {
        this.contentAuth.setConfig(config);
      }

      this.contentAuth?.on('logged-in', () => {
        this.emitBufferFix('logged-in');
      });

      this.setAuthenticationClientECMBPM(this.contentAuth.getAuthentication(), this.processAuth.getAuthentication());
    }

  }

  private clientsFactoryFix() {
    if (!this.contentPrivateClient) {
      this.contentPrivateClient = new ContentClient(this.config, `/api/${this.config.tenant}/private/alfresco/versions/1`, this.httpClient);
    } else {
      this.contentPrivateClient.setConfig(this.config, `/api/${this.config.tenant}/private/alfresco/versions/1`);
    }

    if (!this.contentClient) {
      this.contentClient = new ContentClient(this.config, `/api/${this.config.tenant}/public/alfresco/versions/1`, this.httpClient);
    } else {
      this.contentClient.setConfig(this.config, `/api/${this.config.tenant}/public/alfresco/versions/1`);
    }

    if (!this.authClient) {
      this.authClient = new ContentClient(this.config, `/api/${this.config.tenant}/public/authentication/versions/1`, this.httpClient);
    } else {
      this.authClient.setConfig(this.config, `/api/${this.config.tenant}/public/authentication/versions/1`);
    }

    if (!this.searchClient) {
      this.searchClient = new ContentClient(this.config, `/api/${this.config.tenant}/public/search/versions/1`, this.httpClient);
    } else {
      this.searchClient.setConfig(this.config, `/api/${this.config.tenant}/public/search/versions/1`);
    }

    if (!this.discoveryClient) {
      this.discoveryClient = new ContentClient(this.config, `/api`, this.httpClient);
    } else {
      this.discoveryClient.setConfig(this.config, `/api`);
    }

    if (!this.gsClient) {
      this.gsClient = new ContentClient(this.config, `/api/${this.config.tenant}/public/gs/versions/1`, this.httpClient);
    } else {
      this.gsClient.setConfig(this.config, `/api/${this.config.tenant}/public/gs/versions/1`);
    }

    if (!this.processClient) {
      this.processClient = new ProcessClient(this.config, this.httpClient);
    } else {
      this.processClient.setConfig(this.config);
    }
  }

  private emitBufferFix(event: string, callback?:  () => void): void {
    this.emit(event, callback);
    this.bufferEvents.push(event);
  }
}

import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { EbenFacade, TASK_NOT_FOUND_URL } from '@alf-nx-workspace/eben/data-access';
import { Store } from '@ngxs/store';
import { catchError, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EbenTaskResolver implements Resolve<Observable<EbenTaskDetail>> {

  readonly store = inject(Store);
  readonly router = inject(Router);
  readonly ebenfacade = inject(EbenFacade);

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<EbenTaskDetail> {
    return this.ebenfacade.getTaskDetails(route.params['taskId'])
      .pipe(
        take(1),
        catchError((error) => {
          return of(null);
        })
      );
  }
}

export interface EbenPagination<T> {
  data?: T[];
  size?: number;
  start?: number;
  total?: number;
  pending?: boolean;
}

export const EMPTY_EBEN_PAGINATION: EbenPagination<unknown> = {
  data: [],
  size: 0,
  total: 0,
};

import { NgModule } from '@angular/core';
import { FORM_PROCESSOR_MAPPING, WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING } from '@alf-nx-workspace/shared/utils';
import { DokumentenFreigabeService } from "./dokumenten-freigabe.service";
import { DokumentenFreigabeVariablesSearchable } from "./dokumenten-freigabe.variables";
import { DokumentenFreigabeStartFormularProcessor } from "./form-processors/dokumenten-freigabe.start-formular.processor";

@NgModule({
  providers: [
    {provide: FORM_PROCESSOR_MAPPING, useValue: DokumentenFreigabeStartFormularProcessor, multi: true},
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: DokumentenFreigabeService, multi: true},
    {provide: WORKFLOW_VARIABLE_MAPPING, useValue: DokumentenFreigabeVariablesSearchable, multi: true},
  ],
})
export class DokumentenFreigabeModule {
}

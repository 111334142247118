<div class="flex-container  center-align">
  <shared-ui-flow-icon
    *ngIf="highPriority"
    [matTooltip]="highPriorityTooltip"
    class="margin-right-8"
    icon="workflow-high-priority.svg"
  ></shared-ui-flow-icon>
  <span class="mat-caption high-priority-tooltip">{{highPriorityTooltip}}</span>
</div>
<div class="flex-container center-align">

  <shared-ui-flow-icon
    *ngIf="longRunning"
    [matTooltip]="longRunninTootip"
    class="margin-right-8"
    icon="workflow-long-running.svg"
  ></shared-ui-flow-icon>
  <span class="mat-caption long-running-tooltip">{{longRunninTootip}}</span>
</div>

import { inject, Injectable } from "@angular/core";
import { defaultEbenProcessInstanceQueryReprensentation, EbenProcessInstanceQueryRepresentation } from "@alf-nx-workspace/eben/interfaces";
import { WORKFLOW_VARIABLE_MAPPING } from "./workflow.service";
import { Router } from "@angular/router";

@Injectable({providedIn: 'root'})
export class FlowProcessListService {

  readonly workflowVariables = inject(WORKFLOW_VARIABLE_MAPPING);
  readonly router = inject(Router);

  public onQueryChange(query: EbenProcessInstanceQueryRepresentation) {
    if (query.variablesQuery) {
      query.variables = (<string[]>this.workflowVariables).reduce((accumulator, value) => accumulator.concat(value), []);
    }

    // Remove default query params from url
    const modifiedQueryParams = {};
    for (const key in query) {
      if(key === 'variables') continue;
      if(defaultEbenProcessInstanceQueryReprensentation[key] != query[key]) {
        modifiedQueryParams[key] = query[key];
      }
    }
    this.router.navigate([], {queryParams: modifiedQueryParams});

    return query;
  }
}

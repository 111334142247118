import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationTemplateComponent } from './information-template/information-template.component';
import { FORM_PROCESSOR_MAPPING, WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING } from '@alf-nx-workspace/shared/utils';
import { UrlaubsfreigabeDelegierenService } from './urlaubsfreigabe-delegieren.service';
import { UrlaubsfreigabeDelegierenStartFormProcessor } from './form-processors/urlaubsfreigabe-delegieren.start-form.processor';
import { SharedUiModule } from "@alf-nx-workspace/shared/ui";
import { UrlaubsfreigabeDelegierenVariablesSearchable } from "./urlaubsfreigabe-delegieren.variables";


@NgModule({
  providers: [
    {provide: FORM_PROCESSOR_MAPPING, useValue: UrlaubsfreigabeDelegierenStartFormProcessor, multi: true},
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: UrlaubsfreigabeDelegierenService, multi: true},
    {provide: WORKFLOW_VARIABLE_MAPPING, useValue: UrlaubsfreigabeDelegierenVariablesSearchable, multi: true}
  ],
  imports: [CommonModule, SharedUiModule, InformationTemplateComponent],
})

export class UrlaubsfreigabeDelegierenModule {
}

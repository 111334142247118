import { FormFieldModel, FormModel } from '@alfresco/adf-core';
import { inject, Injectable } from '@angular/core';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';
import { BaseProcessor } from '../../base.processor';
import { DokumentenVerteilungValidator } from "../dokumenten-verteilung.validators";
import { DokumentenVerteilungVariables } from '../dokumenten-verteilung.variables';

@Injectable({ providedIn: 'root' })
export class DokumentenVerteilungAuftragProcessor extends BaseProcessor {
  static FORM_NAME = 'dokumenten-verteilung-auftrag';
  public readonly FORM_NAME = DokumentenVerteilungAuftragProcessor.FORM_NAME;

  onLoaded(form: FormModel) {
    super.onLoaded(form);
    this.addFormFieldValidator(new DokumentenVerteilungValidator());
    this.updateBokuDMSAllCheckbox(form);
  }

  onFieldValueChange(field: FormFieldModel): void {

    // set all bokudms distribution on check all
    if(field.id == DokumentenVerteilungVariables.dokumentenverteilungverteilungalleablagebokudms) {
      const documentDistributionValue: unknown[] = super.getFormFieldByName(field.form, DokumentenVerteilungVariables.documentDistribution).value;
      documentDistributionValue.forEach((distribution: unknown) => distribution["DOKUMENTEN-VERTEILUNG.VERTEILUNG.MATRIX.ABLAGE-BOKUDMS"] = field.value);
    }

    // unset DokumentenVerteilungVariables.dokumentenverteilungverteilungalleablagebokudms if one bokudms distribution is false
    if(field.id == DokumentenVerteilungVariables.documentDistribution) {
      const documentDistributionValue: unknown[] = super.getFormFieldByName(field.form, DokumentenVerteilungVariables.documentDistribution).value;
      this.updateBokuDMSAllCheckbox(field.form);
      const isNotBokuDMSAllCheckboxChecked: boolean = documentDistributionValue.some((distribution: unknown) => !distribution["DOKUMENTEN-VERTEILUNG.VERTEILUNG.MATRIX.ABLAGE-BOKUDMS"]);
      super.getFormFieldByName(field.form, DokumentenVerteilungVariables.dokumentenverteilungverteilungalleablagebokudms).value = !isNotBokuDMSAllCheckboxChecked;
    }
  }

  private updateBokuDMSAllCheckbox(form: FormModel): void {
    const bokuDMSAllCheckbox: FormFieldModel = super.getFormFieldByName(form, DokumentenVerteilungVariables.dokumentenverteilungverteilungalleablagebokudms);
    if(!bokuDMSAllCheckbox) {
      return;
    }

    const documentDistributionValue: unknown[] = super.getFormFieldByName(form, DokumentenVerteilungVariables.documentDistribution).value;
    bokuDMSAllCheckbox.isVisible = documentDistributionValue ? documentDistributionValue.length > 0 : false;
  }
}

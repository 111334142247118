import { Component, ElementRef, HostListener, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivitiContentService, ContentLinkModel, ContentWidgetComponent, FormBaseModule, FormService, PipeModule, ProcessContentService } from "@alfresco/adf-core";
import { fromPromise } from "rxjs/internal-compatibility";
import { saveAs } from 'file-saver';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogActions, MatDialogConfig, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ApsDocumentLinkElementPreviewService } from "./aps-document-link-element-preview.service";
import { takeUntil } from "rxjs/operators";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { RelatedContentRepresentation } from "@alfresco/js-api";

@Component({
  selector: 'shared-ui-aps-document-link-element',
  standalone: true,
  imports: [CommonModule, PipeModule, FormBaseModule, MatProgressSpinnerModule, MatIconModule, MatButtonModule, MatDialogModule, TranslateModule, PdfViewerModule],
  templateUrl: './aps-document-link-element.component.html',
  styleUrls: ['./aps-document-link-element.component.scss'],
  providers: [ApsDocumentLinkElementPreviewService],
  animations: [
    trigger('fadeInOut', [
      state('visible', style({ opacity: 1 })),
      state('hidden', style({ opacity: 0 })),
      transition('visible => hidden', animate('100ms ease-out')),
      transition('hidden => visible', animate('100ms ease-in'))
  ])]
})
export class ApsDocumentLinkElementComponent {

  readonly elementRef = inject(ElementRef);
  readonly dialog = inject(MatDialog);
  readonly domSanitizer = inject(DomSanitizer);

  readonly activitiContentService = inject(ActivitiContentService);
  readonly previewService = inject(ApsDocumentLinkElementPreviewService);

  @Input()
  public document: RelatedContentRepresentation;

  @Input()
  public dataName: string;

  @ViewChild(ContentWidgetComponent) contentWidget: ContentWidgetComponent;
  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<MatDialogActions>;
  @ViewChild('download_button', { static: false }) downloadButton: ElementRef;

  private dialogRef: MatDialogRef<MatDialogActions>;

  private isViewerOpen = false;
  private count = 0;
  public showSpinner = true;
  public isPDFLoadComplete = false;

  public thumbnailBlobUrl:SafeUrl;
  public previewBlobUrl:string;
  ngOnInit(): void {
    this.previewService.getRenditionBlobUrl(this.document).subscribe((url) => {
      if(url) {
        this.thumbnailBlobUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      }
      this.showSpinner = false;
    });
    this.setPdfWorkerSrc();

    const nativeElement: HTMLElement = this.elementRef.nativeElement;
    nativeElement.addEventListener('click', (event: Event) => {
      const buttonElement: HTMLElement = this.downloadButton.nativeElement;
      if (this.isViewerOpen || buttonElement.contains(event.target as HTMLElement)) {
        return;
      }
      this.openViewer();
    });
  }

  private setPdfWorkerSrc() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).pdfWorkerSrc = '/pdf.worker.js';
  }

  downloadContent($event: MouseEvent, contentId: number, filename: string) {
    $event.preventDefault();
    fromPromise(this.activitiContentService.contentApi.getRawContent(contentId))
      .subscribe((content: string) => {
        saveAs(content, filename);
      })
  }
  getContentUrl(contentId: number): string {
    return this.activitiContentService.contentApi.getRawContentUrl(contentId);
  }

  openViewer() {
    const options: MatDialogConfig = {
      position: {
        top: 10 + '%',
        left: 10 + '%'
      },
      height: '100%',
      width: '100%',
      hasBackdrop: true,
      disableClose: false,
      panelClass: 'custom-dark-panel-dialog',
      backdropClass: 'custom-dark-backdrop-dialog'
    };
    this.dialogRef = this.dialog.open(this.dialogTemplate, options);
    this.dialogRef.afterClosed().subscribe(result => {
      this.isViewerOpen = false;
      if (this.previewBlobUrl) {
        this.previewBlobUrl = null;
      }
    });
    this.isViewerOpen = true;

    this.loadContentPreview(this.document);
  }

  private loadContentPreview(document: RelatedContentRepresentation) {
    this.isPDFLoadComplete = false;
    this.previewService.getRenditionBlobUrl(this.document, 'preview')
      .pipe(takeUntil(this.dialogRef.afterClosed()))
      .subscribe((url) => {
        if(url) {
          this.previewBlobUrl = url;
        }
      });
  }

  private ngOnDestroy() {
    if (this.thumbnailBlobUrl) {
      URL.revokeObjectURL(this.thumbnailBlobUrl as string);
      this.thumbnailBlobUrl = null;
    }
  }

  closeViewer() {
    this.dialogRef.close();
  }

  public afterLoadComplete() {
    this.isPDFLoadComplete = true;
  }
}

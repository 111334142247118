import { Component, inject } from '@angular/core';

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

import { RxState, selectSlice } from "@rx-angular/state";
import { ActivitiContentService } from "@alfresco/adf-core";
import { of } from "rxjs";
import { MatButtonModule } from "@angular/material/button";
import { UrlaubsfreigabeDelegierenFreigabeVariables } from "../urlaubsfreigabe-delegieren-freigabe.variables";
import moment from "moment-es6";
import { saveAs } from 'file-saver';
import { fromPromise } from "rxjs/internal-compatibility";
import { switchMap } from "rxjs/operators";
import { BaseResultTemplateComponent } from "../../base-result-template.component";
import { WorkflowApprover } from "@alf-nx-workspace/flow/interfaces";

interface ResultTemplateComponentState {
  variableMap: Map<string, string>,
  workflowResult: string,
  approved: WorkflowApprover[],
  rejectedBy: string,
  rejectedReason: string,
  contentId: number
}

@Component({
  selector: 'urlaubsfreigabe-delegieren-freigabe-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.css'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  providers: [RxState]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {

  readonly activitiContentService = inject(ActivitiContentService);
  readonly fields = UrlaubsfreigabeDelegierenFreigabeVariables;
  readonly rxState = inject<RxState<ResultTemplateComponentState>>(RxState);
  readonly vm$ = this.rxState.select(selectSlice(["workflowResult", "approved", "rejectedBy", "rejectedReason"]));

  private filename;

  ngOnInit() {
    this.connectState();
    this.sideEffects();
  }

  private connectState() {
    this.rxState.connect("variableMap", this.variableMapSubject);
    this.rxState.connect("workflowResult", this.workflowResultSubject);

    this.rxState.connect("contentId", this.variableMapSubject, (oldState, value) => {
      return 0;
    });
  }

  private sideEffects() {

    this.rxState.hold(this.rxState.select('workflowResult'), (result) => {
      const variableMap = this.rxState.get('variableMap');

      if(result === this.workflowResults.successful) {
        const approved = [];
        if (variableMap.has(this.fields.currentLeiterFullname)) {
          const fullName = variableMap.get(this.fields.currentLeiterFullname);
          const date = variableMap.has(this.fields.bestaetgtAmUm) ? moment(variableMap.get(this.fields.bestaetgtAmUm), 'DD.MM.YYYY HH:mm Z').toDate() : null;
          approved.push({by: fullName.toUpperCase(), on: date})
        }
        this.rxState.set('approved', () => approved);
      } else if (result === this.workflowResults.unsuccessful) {
        const rejected = [];
        const fullName = variableMap.get(this.fields.currentLeiterFullname);
        this.rxState.set('rejectedBy', () => fullName);
        this.rxState.set('rejectedReason', () => variableMap.get(this.fields.ablehnenbegruendung));
      }
    })
  }

  downloadContent($event: MouseEvent) {
    $event.preventDefault();

    const contentId = this.rxState.get('contentId');
    fromPromise(this.activitiContentService.contentApi.getContent(contentId))
      .pipe(
        switchMap(({ name }) => of(name)),
        switchMap((name) => {
          this.filename = name;
          return this.activitiContentService.contentApi.getRawContent(contentId);
        })
      )
      .subscribe((content) => saveAs(content, this.filename));
  }

  getContentUrl(): string {
    return this.activitiContentService.contentApi.getRawContentUrl(this.rxState.get('contentId'));
  }
}

import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { EbenProcessInstance, EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';
import { BpmService } from '@alf-nx-workspace/shared/utils';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EbenProcessResolver implements Resolve<Observable<EbenProcessInstance>> {
  readonly bpmService = inject(BpmService);

  resolve(route: ActivatedRouteSnapshot): Observable<EbenProcessInstance> {
    return this.bpmService.getProcessInstanceById(route.params['processInstanceId']).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of(null);
        }
        return throwError(error);
      })
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from "@ngxs/store";
import { LanguageState } from "./+store/language.state";

@NgModule({
  imports: [
    CommonModule,
    NgxsModule.forFeature([LanguageState])
    ]
})
export class LanguageDataAccessModule {}

import { inject, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Pipe({
  name: 'translateInterpolate',
  standalone: true
})
export class TranslateInterpolatePipe implements PipeTransform {

  readonly translateService = inject(TranslateService);
  private variableMap: Map<string, string>;

  transform(value: string, variables: Map<string, string>): string {
    const translation = this.translateService.instant(value);
    if(variables) {
      this.variableMap = variables;
      return this.recursiveInterpolation(translation);
    } else {
      return translation;
    }
  }

  private recursiveInterpolation(value: string): string {
    const placeholderMatches = value.match(/%([^%]+)%/);
    if (!placeholderMatches) {
      return value;
    } else {
      return value.replace(/%([^%]+)%/g, (match, name) => {
        const variableValue: string = this.variableMap.get(name);
        if (variableValue) {
          return this.recursiveInterpolation(variableValue);
        }
        return this.translateService.instant(name);
      })
    }
  }
}

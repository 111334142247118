export enum ReiseAufVariables {

  'reisenderId' = 'reisenderId',
  'reisenderFullname' = 'reisenderFullname',
  'kostenubernahme_LABEL' = 'kostenubernahme_LABEL',
  'reiseziel' = 'reiseziel',
  'beginn_dienstreise' = 'beginn_dienstreise',
  'ende_dienstreise' = 'ende_dienstreise',
  'status' = 'status',
  'vorgesetzterUsername_LABEL' = 'vorgesetzterUsername_LABEL',
  'aktuelleFreigabe' = 'aktuelleFreigabe',
  'zusaetzlich' = 'zusaetzlich',
  'vorgesetzterId' = 'vorgesetzterId',
  'kostenverantwortlicher' = 'kostenverantwortlicher',
  'hasBeenCancelledComment' = 'hasBeenCancelledComment',
  'hasBeenCancelledByFullname' = 'hasBeenCancelledByFullname',
  'reisenderVorname' = 'reisenderVorname',
  'reisenderNachname' = 'reisenderNachname',
  'vorschuss' = 'vorschuss',
  'vorschussBetrag' = 'vorschussBetrag',
  'reisekosten' = 'reisekosten',
  'postleitzahl' = 'postleitzahl',
  'wohnadresse' = 'wohnadresse',
  'land' = 'land',
  'a1' = 'a1',
  'kostenubernahme' = 'kostenubernahme',
  'co2_rechner' = 'co2_rechner',
  'a1_beginn' = 'a1_beginn',
  'a1_ende' = 'a1_ende',
  'freigabe' = 'freigabe',
  'vorgesetzterNachname' = 'vorgesetzterNachname',
  'vorgesetzterVorname' = 'vorgesetzterVorname',
  'vorschlagVorgesetzte' = 'vorschlagVorgesetzte',
  'kostenverantwortlicherId' = 'kostenverantwortlicherId',
  'kostenverantwortlicherNachname' = 'kostenverantwortlicherNachname',
  'kostenverantwortlicherVorname' = 'kostenverantwortlicherVorname',
  'zusatzPersonNachname' = 'zusatzPersonNachname',
  'zusatzPersonVorname' = 'zusatzPersonVorname',
  'zusatzPersonUsername' = 'zusatzPersonUsername',
  'beginn_dienstreise_string' = 'beginn_dienstreise_string',
  'ende_dienstreise_string' = 'ende_dienstreise_string',
  'datumFreigabeReisender' = 'datumFreigabeReisender',
  'datumFreigabeVorgesetzter' = 'datumFreigabeVorgesetzter',
  'datumFreigabeKostenverantwortlicher' = 'datumFreigabeKostenverantwortlicher',
  'datumFreigabeZusatzlich' = 'datumFreigabeZusatzlich',
  'reiseauftragFormularAbschluss' = 'reiseauftragFormularAbschluss',
  'verbesserungsauftrag' = 'verbesserungsauftrag',
  _bokuFlow_workflowResultProtocolJSON = "_bokuFlow_workflowResultProtocolJSON"
}

export const ReiseAufVariablesSearchable = [
  ReiseAufVariables.reisenderFullname,
  ReiseAufVariables.beginn_dienstreise_string,
  ReiseAufVariables.ende_dienstreise_string,
  ReiseAufVariables.reiseziel,
  ReiseAufVariables.kostenubernahme_LABEL,
  ReiseAufVariables.kostenverantwortlicherNachname,
  ReiseAufVariables.vorgesetzterNachname,
  ReiseAufVariables.zusatzPersonNachname,
]

import { HomeOfficeTag } from '@alf-nx-workspace/eben/interfaces';
import { DynamicTableColumn } from "@alfresco/adf-core/lib/form/components/widgets/dynamic-table/dynamic-table-column.model";
import { isEmpty } from 'lodash-es';

export function isHomeOfficeBVTagValid(tag: HomeOfficeTag, required = false): boolean {
  const { uhrzeitVon, uhrzeitBis, erreichbarkeit, erreichbarkeitFree } = tag;

  if (!uhrzeitVon && !uhrzeitBis) {
    // if required we need a time given
    if (required === true) {
      return false;
    }

    const isErreichbarkeitSet: boolean = Array.isArray(erreichbarkeit) && erreichbarkeit.length !== 0;
    const isErreichbarkeitFreeSet: boolean = erreichbarkeitFree !== null && erreichbarkeitFree !== '';

    if( !isErreichbarkeitSet && !isErreichbarkeitFreeSet) {
      return true;
    } else if (isErreichbarkeitSet || isErreichbarkeitFreeSet) {
      return false;
    }

    // uhrzeit von und bis are empty - will be ignored, so it's considered valid
    return true;
  } else if (!uhrzeitVon || !uhrzeitBis) {
    // just one of the uhrzeit fields is empty
    return false;
  }

  // is the time valid
  if (!(isValidTimeString(uhrzeitVon) && isValidTimeString(uhrzeitBis))) {
    return false;
  }

  // is beginning before end time?
  if (!isBeginningBeforeEnd(uhrzeitVon, uhrzeitBis)) {
    return false;
  }

  // uhrzeit is between 08:00 and 16:00
  const before6Date: Date = new Date(null, null, null, 6, 0);
  const after18Date: Date = new Date(null, null, null, 18, 0);
  if (getDateFromUhrzeitString(uhrzeitVon) < before6Date || getDateFromUhrzeitString(uhrzeitBis) > after18Date) {
    return false;
  }

  // no erreichbarkeit selected
  if (!erreichbarkeit || erreichbarkeit.length == 0) {
    return false;
  }

  return true;
}

function isValidTimeString(uhrzeit: string): boolean {
  if (uhrzeit == null || uhrzeit.length != 5) {
    // format is xx:xx so string length should be 5
    return false;
  }

  // Except for the : in the middle, every char should be a number
  const allNumberPositionsFilled: boolean =
    isNumeric(uhrzeit.charAt(0)) &&
    isNumeric(uhrzeit.charAt(1)) &&
    isNumeric(uhrzeit.charAt(3)) &&
    isNumeric(uhrzeit.charAt(4));
  if (!allNumberPositionsFilled) {
    return false;
  }

  const hours: number = parseInt(uhrzeit.substring(0, 3));
  const minutes: number = parseInt(uhrzeit.substring(3, 5));

  return hours >= 0 && hours < 24 && minutes >= 0 && minutes < 60;
}

function isNumeric(value) {
  return /^-?\d+$/.test(value);
}

function isBeginningBeforeEnd(uhrzeitVon: string, uhrzeitBis: string): boolean {
  const uhrzeitVonDate: Date = getDateFromUhrzeitString(uhrzeitVon);
  const uhrzeitBisDate: Date = getDateFromUhrzeitString(uhrzeitBis);

  const elapsedMilliseconds: number = uhrzeitBisDate.getTime() - uhrzeitVonDate.getTime();

  return elapsedMilliseconds > 0;
}

export function getDateFromUhrzeitString(uhrzeit: string): Date {
  const uhrzeitHours: number = parseInt(uhrzeit.substring(0, 3));
  if(isNaN(uhrzeitHours)) return null;
  const uhrzeitMinutes: number = parseInt(uhrzeit.substring(3, 5));
  if(isNaN(uhrzeitMinutes)) return null;
  return new Date(null, null, null, uhrzeitHours, uhrzeitMinutes);
}

export function isDynamicTableColumnValid(columnValue: string, columnDefinition: DynamicTableColumn): boolean {
  if (columnDefinition.required) {
    return !isEmpty(columnValue);
  }
  return true;
}

import { FormFieldModel, FormModel } from '@alfresco/adf-core';

import { BaseProcessor } from '../../base.processor';
import { Injectable } from '@angular/core';
import { UrlaubsfreigabeDelegierenVariables } from '../urlaubsfreigabe-delegieren.variables';
import { EbenFormFieldModel, EbenPeopeFormFieldModel } from '@alf-nx-workspace/eben/interfaces';

@Injectable({ providedIn: 'root' })
export class UrlaubsfreigabeDelegierenStartFormProcessor extends BaseProcessor {
  static FORM_NAME = 'urlaubsfreigabe-delegieren'; // As this is a start form we use the processDefinitionKey to identify it
  public readonly FORM_NAME = UrlaubsfreigabeDelegierenStartFormProcessor.FORM_NAME;

  public onLoaded(form: FormModel): void {
    super.onLoaded(form);
  }

  /**
   * @param field
   */
  onFieldValueChange(field: FormFieldModel): void {
    if (field.id === UrlaubsfreigabeDelegierenVariables.urlaubsGenehmigendePerson) {
      const multiSelectUserField: EbenPeopeFormFieldModel = this.getFormFieldByName(
        field.form,
        UrlaubsfreigabeDelegierenVariables.multiSelectUsers
      ) as EbenPeopeFormFieldModel;
      if (field.value) {
        multiSelectUserField.notSelectable = [field.value];
      } else {
        multiSelectUserField.notSelectable = [];
      }
      this.updateField(multiSelectUserField);
    }

    if (field.id === UrlaubsfreigabeDelegierenVariables.multiSelectUsers) {
      const urlaubsGenehmigendePersonField: EbenPeopeFormFieldModel = this.getFormFieldByName(
        field.form,
        UrlaubsfreigabeDelegierenVariables.urlaubsGenehmigendePerson
      ) as EbenPeopeFormFieldModel;
      if (Array.isArray(field.value) && field.value.length > 0) {
        urlaubsGenehmigendePersonField.notSelectable = field.value;
      } else {
        urlaubsGenehmigendePersonField.notSelectable = [];
      }
      this.updateField(urlaubsGenehmigendePersonField);
    }

    if (field.id === UrlaubsfreigabeDelegierenVariables.urlaubsGenehmigendePersonHatKeineStellvertreter) {
      if (field.value) {
        field.form.validateForm();
        return;
      }
      const urlaubsgenehmigerStellvertretungField: EbenFormFieldModel = this.getFormFieldByName(
        field.form,
        UrlaubsfreigabeDelegierenVariables.urlaubsgenehmigerStellvertretung
      );

      urlaubsgenehmigerStellvertretungField.value = null;
      this.updateField(urlaubsgenehmigerStellvertretungField);
    }
  }
}

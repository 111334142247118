import { EbenFormFieldModel, FormFieldWarning, FormProcessor } from '@alf-nx-workspace/eben/interfaces';
import {
  DateFieldValidator,
  DateTimeFieldValidator,
  FormFieldModel,
  FormFieldValidator,
  FormModel,
  MaxDateFieldValidator,
  MaxDateTimeFieldValidator,
  MaxLengthFieldValidator,
  MaxValueFieldValidator,
  MinDateFieldValidator,
  MinDateTimeFieldValidator,
  MinLengthFieldValidator,
  MinValueFieldValidator,
  NumberFieldValidator,
  RegExFieldValidator,
  RequiredFieldValidator,
} from '@alfresco/adf-core';
import { EbenDateFieldValidator, EbenDynamicTableValidator, EbenMultiSelectorValidator, EbenPeopleValidator, EbenRequiredValidator } from './base.validators';
import { EbenFormService } from "@alf-nx-workspace/eben/utils";
import { inject, Injectable } from "@angular/core";

export const FORM_FIELD_VALIDATORS = [
  new RequiredFieldValidator(),
  new NumberFieldValidator(),
  new MinLengthFieldValidator(),
  new MaxLengthFieldValidator(),
  new MinValueFieldValidator(),
  new MaxValueFieldValidator(),
  new RegExFieldValidator(),
  new DateFieldValidator(),
  new DateTimeFieldValidator(),
  new MinDateFieldValidator(),
  new MaxDateFieldValidator(),
  new MinDateTimeFieldValidator(),
  new MaxDateTimeFieldValidator(),
];

@Injectable()
export class BaseProcessor implements FormProcessor {
  static FORM_NAME = 'BaseProcessor';
  public readonly FORM_NAME = BaseProcessor.FORM_NAME;
  public readonly formFieldValidators: FormFieldValidator[] = [
    new EbenRequiredValidator(),
    new EbenDateFieldValidator(),
    new EbenDynamicTableValidator(),
    new EbenPeopleValidator(),
    new EbenMultiSelectorValidator(),
    ...FORM_FIELD_VALIDATORS,
  ];

  public form: FormModel;

  readonly ebenFormService = inject(EbenFormService);

  onLoaded(form: FormModel): void {
    this.form = form;

    form.fieldValidators = this.formFieldValidators;
  }

  onFieldFocusIn(field: FormFieldModel): void {
  }

  onFieldValueChange(field: FormFieldModel): void {
  }

  onFieldFocusOut(field: FormFieldModel): void {
  }

  getFormFieldWarnings(form: FormModel): FormFieldWarning[] {
    return [];
  }

  getFormFieldByName(form: FormModel, fieldId: string): EbenFormFieldModel {
    return form.getFormFields().find((f) => f.id === fieldId) as EbenFormFieldModel;
  }

  addFormFieldValidator(validator: FormFieldValidator): void {
    this.form.fieldValidators.unshift(validator);
  }

  falseIfNotVisible(field: FormFieldModel) {
    /*  temporary Workaround to prevent error:
        can't access property "isVisible", field is undefined */
    if (field && !field.isVisible) {
      field.isVisible = false;
      this.updateField(field);
    }
  }

  updateField(field: FormFieldModel): void {
    this.ebenFormService.onFormFieldChanged(field);
  }
}

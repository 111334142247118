<mat-nav-list class="nav-list">
  <ng-container *ngFor="let route of routes">
      <ng-container *hasRoutePermission="route.route">
        <a [routerLinkActive]="'active'" [routerLink]="[route.route]" class="padding-top-16 {{ route.route }}">
        <div matRipple>
            <div class="flex-container margin-left-8 padding-top-16 padding-bottom-8">
            <div [ngClass]="['margin-left-16', route.route]">
                <shared-ui-flow-icon [icon]="route.icon"></shared-ui-flow-icon>
            </div>
              <div class="margin-left-16">
              {{ ('HOME.' + route.route + '.TITLE') | uppercase | translate }}
            </div>
            <span
                *ngIf="route?.data?.assignedTasksTotal$ | async as total"
                [matBadge]="total"
                class="badge margin-left-16"
                matBadgeColor="accent"
                matBadgeSize="medium"
            ></span>
          </div>
        </div>
      </a>
    </ng-container>
  </ng-container>
</mat-nav-list>

import { Component, inject } from '@angular/core';
import { FlowSessionService } from "../services/flow-session.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: 'flow-dialog-session-error',
  templateUrl: './dialog-session-error.component.html',
  styleUrls: ['./dialog-session-error.component.scss'],
  standalone: true,

  imports: [
    TranslateModule,
    MatDialogModule,
    MatButtonModule
  ],
})
export class DialogSessionErrorComponent {

  readonly flowSessionService = inject(FlowSessionService);
  readonly dialogRef = inject(MatDialogRef);

  public disableDialogActions = false;

  refresh() {
    this.disableDialogActions = true;
    this.flowSessionService.refresh();
  }

  logout() {
    this.disableDialogActions = true;
    this.flowSessionService.logout();
  }
}

import { FormFieldModel, FormModel } from '@alfresco/adf-core';
import { Injectable } from '@angular/core';
import moment from 'moment-es6';


import { BaseProcessor } from '../../base.processor';
import { VorschussBetragValidator } from '../reise-auf.validators';
import { ReiseAufVariables } from "../reise-auf.variables";
import { FormFieldWarning } from "@alf-nx-workspace/eben/interfaces";

@Injectable({ providedIn: 'root' })
export class ReiseAufGrunddatenProcessor extends BaseProcessor {
  static FORM_NAME = 'reiseAufGrunddatenForm';
  public readonly FORM_NAME = ReiseAufGrunddatenProcessor.FORM_NAME;

  public onLoaded(form: FormModel) {
    super.onLoaded(form);
    this.addFormFieldValidator(new VorschussBetragValidator());
    // @todo create a onPostLoaded function
    // the eben-widgets are not rendered at the correct time so the following updates are not working without the timeout
    setTimeout(() => {
      this.updateEndeDienstreise(form);
      this.updateCo2Rechner(form);
    }, 250);
  }

  public onFieldValueChange(field: FormFieldModel) {

    if(field.id === ReiseAufVariables.vorschuss || field.id === ReiseAufVariables.vorschussBetrag || field.id === ReiseAufVariables.reisekosten) {
      this.updateVorschussBetrag(field);
    }

    if (field.id === ReiseAufVariables.beginn_dienstreise || field.id === ReiseAufVariables.ende_dienstreise) {
      this.updateBeginnUndEndeDienstreise(field);
    }

    field.form.validateForm();
  }

  public onFieldFocusOut(field: FormFieldModel) {
    if (field.id === ReiseAufVariables.wohnadresse || field.id === ReiseAufVariables.reiseziel || field.id === ReiseAufVariables.land) {
      this.updateCo2Rechner(field.form);
    }
  }

  public getFormFieldWarnings(form: FormModel): FormFieldWarning[] {
    let warnings: FormFieldWarning[] = [];
    const fields: FormFieldModel[] = form.getFormFields();
    const beginnDienstreiseField = fields.find((f) => f.id === ReiseAufVariables.beginn_dienstreise);

    warnings = [...warnings, ...this.validateBeginnDienstreise(beginnDienstreiseField)];
    return warnings;
  }

  private validateBeginnDienstreise(field: FormFieldModel) {
    if (moment(field.value, field.dateDisplayFormat).startOf('day').isBefore(moment().startOf('day'))) {
      return [
        {
          fieldId: field.id,
          warningSummary: 'REISE-AUF.WARNING.BEGINN_DIENSTREISE_IN_THE_PAST'
        }
      ];
    }
    return [];
  }

  private updateVorschussBetrag(field: FormFieldModel) {
    const fields: FormFieldModel[] = field.form.getFormFields();
    const vorschussBetragField = fields.find((f) => f.id === ReiseAufVariables.vorschussBetrag);
    const reisekostenField = fields.find((f) => f.id === ReiseAufVariables.reisekosten);
    const reisekostenValueRange = reisekostenField['value'].split`-`.filter(Boolean);
    const reisekostenLimit = '' + Math.max(...reisekostenValueRange);

    vorschussBetragField.minValue = '0';
    vorschussBetragField.maxValue = reisekostenValueRange.length > 1 ? reisekostenLimit : '';
    this.updateField(vorschussBetragField);
    field.form.validateForm();
  }

  private updateEndeDienstreise(form: FormModel) {
    const fields: FormFieldModel[] = form.getFormFields();
    const beginnDienstreiseField = fields.find((f) => f.id === ReiseAufVariables.beginn_dienstreise);
    const endeDienstreiseField = fields.find((f) => f.id === ReiseAufVariables.ende_dienstreise);

    const beginnDienstreiseValue = beginnDienstreiseField.value;

    if (beginnDienstreiseValue) {
      endeDienstreiseField.minValue = beginnDienstreiseValue;
      this.updateField(endeDienstreiseField);
    }
  }

  private updateBeginnUndEndeDienstreise(field: FormFieldModel) {
    const fields: FormFieldModel[] = field.form.getFormFields();
    const beginnDienstreiseField = fields.find((f) => f.id === ReiseAufVariables.beginn_dienstreise);
    const endeDienstreiseField = fields.find((f) => f.id === ReiseAufVariables.ende_dienstreise);

    const beginnDienstreiseValue = beginnDienstreiseField.value;
    const endeDienstreiseValue = endeDienstreiseField.value;

    if (field.id === ReiseAufVariables.beginn_dienstreise && beginnDienstreiseValue) {
      if (!endeDienstreiseValue) {
        endeDienstreiseField.value = beginnDienstreiseValue;
      }
      endeDienstreiseField.minValue = beginnDienstreiseValue;
      this.updateField(endeDienstreiseField);
    } else if (field.id === ReiseAufVariables.ende_dienstreise && endeDienstreiseValue) {
      if (!beginnDienstreiseValue) {
        beginnDienstreiseField.value = endeDienstreiseValue;
      }
      this.updateField(beginnDienstreiseField);
    }
  }

  private updateCo2Rechner(form: FormModel) {
    const co2RechnerField = this.getFormFieldByName(form, ReiseAufVariables.co2_rechner);

    if (!co2RechnerField) {
      return;
    }

    const wohnadresseField = this.getFormFieldByName(form, ReiseAufVariables.wohnadresse);
    const landField = this.getFormFieldByName(form, ReiseAufVariables.land);
    const reisezielField = this.getFormFieldByName(form, ReiseAufVariables.reiseziel);

    const postleitzahlField = this.getFormFieldByName(form, ReiseAufVariables.postleitzahl);

    let destinationString: string;
    if (reisezielField.value) {
      destinationString = [landField.value, postleitzahlField.value, reisezielField.value].join(' ');
    }
    co2RechnerField.value = {
      origin: wohnadresseField.value,
      destination: destinationString
    };
    this.updateField(co2RechnerField);
  }
}

import { Component, Input } from '@angular/core';

@Component({
  selector: 'shared-ui-list-no-data',
  templateUrl: './list-no-data.component.html',
  styleUrls: ['./list-no-data.component.scss'],
})
export class ListNoDataComponent {

  @Input()
  noDataMessage: string;

}

import { Component, inject, Input, OnInit } from '@angular/core';
import { FlowFacade } from '@alf-nx-workspace/flow/data-access';
import { EbenProcessInstance } from '@alf-nx-workspace/eben/interfaces';
import { ResultSetRowEntry } from '@alfresco/js-api/typings/src/api/search-rest-api/model/resultSetRowEntry';
import { RxState, selectSlice } from "@rx-angular/state";
import { BehaviorSubject, Observable } from "rxjs";

interface ProcessDocumentsState {
  documents$: Observable<ResultSetRowEntry[]>;
}
@Component({
  selector: 'shared-ui-process-documents',
  templateUrl: './process-documents.component.html',
  styleUrls: ['./process-documents.component.scss'],
  providers: [RxState]
})
export class ProcessDocumentsComponent implements OnInit {

  @Input()
  set processInstance(processInstance: EbenProcessInstance) {
    this.processSubject$.next(processInstance);
  }

  readonly flowFacade = inject(FlowFacade);
  readonly rxState = inject<RxState<ProcessDocumentsState>>(RxState);
  readonly vm$ = this.rxState.select(selectSlice(['documents$']));
  readonly processSubject$ = new BehaviorSubject<EbenProcessInstance>(null);

  ngOnInit(): void {
    this.connectState();
  }

  private connectState() {
    this.rxState.connect('documents$', this.processSubject$, (oldState, processInstance) => {
      return this.flowFacade.getWorkflowDocuments([processInstance.id]);
    })
  }
}

import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';
import { RouterStateParams } from "@alf-nx-workspace/shared/interfaces";

export class FlowRouterStateSerializer implements RouterStateSerializer<RouterStateParams> {
  serialize(routerState: RouterStateSnapshot): RouterStateParams {
    const {
      url,
      root: { queryParams }
    } = routerState;

    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;
    }
    const { params } = route;
    return {
      url,
      flowRoute: this.getFlowRoute(url),
      ebenDialog: this.getEbenDialog(url),
      params,
      queryParams
    };
  }

  private getFlowRoute(url: string): string {
    const regexp = /\/flow\/([a-z-]*)/;
    const match = regexp.exec(url);
    return match ? match[1] : '';
  }

  private getEbenDialog(url: string): string {
    const regexp = /\/*\/flow\/(.*)\(dialog:(.*)\/\d*\)/;
    const match = regexp.exec(url);
    return match ? match[2] : '';
  }
}

import { NgModule } from '@angular/core';
import { WORKFLOW_SERVICE_MAPPING } from '@alf-nx-workspace/shared/utils';
import { EntsorgungAnfordernService } from "./entsorgung-anfordern.service";


@NgModule({
  providers: [
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: EntsorgungAnfordernService, multi: true},
  ],
})
export class EntsorgungAnfordernModule {
}

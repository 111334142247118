import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationTemplateComponent } from './information-template/information-template.component';
import { WORKFLOW_SERVICE_MAPPING, WORKFLOW_VARIABLE_MAPPING } from '@alf-nx-workspace/shared/utils';

import { SharedUiModule } from "@alf-nx-workspace/shared/ui";
import { MasterzulassungEinschreibungService } from "./masterzulassung-einschreibung.service";
import { MasterzulassungEinschreibungVariablesSearchable } from "./masterzulassung-einschreibung.variables";

@NgModule({
  providers: [
    {provide: WORKFLOW_SERVICE_MAPPING, useValue: MasterzulassungEinschreibungService, multi: true},
    {provide: WORKFLOW_VARIABLE_MAPPING, useValue: MasterzulassungEinschreibungVariablesSearchable, multi: true},
  ],
  imports: [CommonModule, SharedUiModule, InformationTemplateComponent],
})

export class MasterzulassungEinschreibungModule {
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EbenProcessInstance } from '@alf-nx-workspace/eben/interfaces';
import { InformationElement } from '@alf-nx-workspace/shared/interfaces';
import { MasterzulassungVariables } from "../masterzulassung.variables";
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { NgIf } from "@angular/common";
import { WorkflowInformationTemplate } from "@alf-nx-workspace/flow/interfaces";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";


@Component({
  selector: 'interne-masterzulassung-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = MasterzulassungVariables;

  public getInformationElementProcessId(): InformationElement {
    return {name: 'PROCESS.ID', value: this.process.id};
  }

  public getInformationElementProcessStarted(): InformationElement {
    return {name: 'eingereicht am', value: this.variableMap.get(this.fields.antragEingereichtAm)};
  }

  public getInformationElementStudentFullname() {
    if (this.fields.vorname && this.fields.nachname) {
      return {
        name: "INT_MASZUL.ANTRAGSTELLER",
        value: `${this.variableMap.get(this.fields.vorname)} ${this.variableMap.get(this.fields.nachname)} (${this.variableMap.get(this.fields.matrikelnummer)})`
      };
    }
  }

  getVorstudium() {
    return {
      name: "Masterstudium",
      value: this.variableMap.get(this.fields.masterstudiumIdf)
    };
  }

  getAntragBereitsEingereicht() {
    if (this.variableMap.get(this.fields.antragBereitsEingereicht)) {
      return {
        name: "INT_MASZUL.PROCESS_ENDED.ABGELEHNT.KOMMENTAR",
        value: "COMPLETED_NOT_ALLOWED"
      }
    }
  }
}

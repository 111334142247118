import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AuthFacade } from '@alf-nx-workspace/auth/data-access';
import { LightUserRepresentation, UserRepresentation } from '@alfresco/js-api';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EbenUserResolver implements Resolve<Observable<UserRepresentation>> {
  constructor(public authFacade: AuthFacade) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<LightUserRepresentation> {
    return this.authFacade.user$.pipe(take(1));
  }
}

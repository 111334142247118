<ng-container *ngIf="vm$ | async as vm">
  <ng-container *ngIf="vm.workflowResult === workflowResults.successful">
    <a [href]="getContentUrl()"
       (click)="downloadContent($event)">{{ 'LINK_TO_DOCUMENT' | translate }}</a>
  </ng-container>
  <ng-container *ngIf="vm.workflowResult === workflowResults.unsuccessful">
    <div class="mat-caption" *ngIf="reason">{{ reason | translate }}</div>
    <div class="mat-caption" *ngIf="hasReviewer">{{ 'DOKUMENTEN-VERTEILUNG.PROCESS_ENDED.NOT-ACKNOWLEDGE-BY' | translate }}</div>
    <div class="mat-caption" *ngFor="let entry of vm.workflowResultProtocol">
      <ng-container *ngIf="entry['by']">
        <div class="mat-caption">{{ entry['by'] }}</div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

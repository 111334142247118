<a [routerLink]="['', { outlets: { dialog: ['task-form', task.id] } }]" (click)="$event.stopPropagation()">
  <span>{{ task?.name | bokuTaskName | translate }}</span>
</a>

<span class="mat-caption" *ngIf="assigneeLabel">
  {{ 'TASK_STATUS.ASSIGNED_TO' | translate }} {{ assigneeLabel | translate }} <span *ngIf="assignedToUser">({{ assignedToUser | translate }})</span>
</span>

<span class="mat-caption" *ngIf="task.dueDate">
  {{ "TASK_DUE_DATE" | translate :{dueDate: task.dueDate | date: 'dd.MM.yyyy HH:mm'} }}
</span>

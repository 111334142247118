import { FormFieldModel, FormFieldValidator } from '@alfresco/adf-core';
import { HomeOfficeTag } from '@alf-nx-workspace/eben/interfaces';
import { isHomeOfficeBVTagValid } from '@alf-nx-workspace/eben/utils';

export class HomeofficeVereinbarungValidator implements FormFieldValidator {
  private supportedTypes = ['form-builder.pallette.homeoffice-bv.tage'];

  isSupported(field: FormFieldModel): boolean {
    return field && field.isVisible && this.supportedTypes.indexOf(field.type) > -1;
  }

  validate(field: FormFieldModel): boolean {
    let valid = true;
    if (this.isSupported(field)) {
      const tage: HomeOfficeTag[] = JSON.parse(field.value);
      valid = tage.every((tag: HomeOfficeTag) => isHomeOfficeBVTagValid(tag));
      if (!valid) {
        field.validationSummary.message = 'VALIDATOR.HOMEOFFICE_BV_TAGE';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
      }
    }

    return valid;
  }
}

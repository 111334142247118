<h4 class="margin-0">
  {{ process.processDefinitionKey | uppercase | translate }}
</h4>
<div class="mat-caption">
  #<span>{{process.id}}</span>
  {{ 'PROCESS_STATUS.STARTED_BY' | translate }}
  {{user}}
</div>
<span class="mat-caption">
  {{ process.started | date: 'dd.MM.yyyy HH:mm' }}
</span>

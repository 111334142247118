import { DateFieldValidator, FormFieldModel, FormFieldTypes, FormFieldValidator } from '@alfresco/adf-core';
import moment from 'moment-es6';

import { HomeOfficeTag } from '@alf-nx-workspace/eben/interfaces';
import { isDynamicTableColumnValid, isHomeOfficeBVTagValid } from '@alf-nx-workspace/eben/utils';
import { DynamicTableColumn } from "@alfresco/adf-core/lib/form/components/widgets/dynamic-table/dynamic-table-column.model";

export class EbenDateFieldValidator implements FormFieldValidator {
  private supportedTypes = [FormFieldTypes.DATE];

  // Validates that the input string is a valid date formatted as <dateFormat> (default D-M-YYYY)
  static isValidDate(inputDate: string, dateFormat = 'DD-MM-YYYY'): boolean {
    if (inputDate) {
      const d = moment(inputDate, dateFormat, true);
      return d.isValid();
    }

    return false;
  }

  isSupported(field: FormFieldModel): boolean {
    return field && this.supportedTypes.indexOf(field.type) > -1;
  }

  validate(field: FormFieldModel): boolean {
    if (this.isSupported(field) && field.value && field.isVisible) {
      if (DateFieldValidator.isValidDate(field.value, field.dateDisplayFormat)) {
        return true;
      }
      field.validationSummary.message = 'VALIDATOR.INVALID_DATE';
      return false;
    }
    return true;
  }
}

export class EbenRequiredValidator implements FormFieldValidator {
  isSupported(field: FormFieldModel): boolean {
    return field.type !== 'readonly-text';
  }

  validate(field: FormFieldModel): boolean {
    let valid = true;
    if (this.isSupported(field) && !field.readOnly && field.isVisible && field.required) {
      if (field.type === 'form-builder.pallette.homeoffice-bv.tage') {
        if (field.value) {
          const tage: HomeOfficeTag[] = JSON.parse(field.value);
          valid = tage.some((tag: HomeOfficeTag) => isHomeOfficeBVTagValid(tag, true));
        } else {
          valid = false;
        }
      } else if (field.type === FormFieldTypes.DROPDOWN) {
        if (field.hasEmptyValue && field.emptyOption) {
          if (field.value === field.emptyOption.id) {
            valid = false;
          }
        }
      } else if (field.type === FormFieldTypes.RADIO_BUTTONS) {
        const option = field.options.find((opt) => opt.id === field.value);
        valid = !!option;
      } else if (field.type === FormFieldTypes.UPLOAD) {
        valid = field.value && field.value.length > 0;
      } else if (field.type === FormFieldTypes.DYNAMIC_TABLE) {
        valid = field.value && field.value instanceof Array && field.value.length > 0;
      } else if (field.type === FormFieldTypes.BOOLEAN) {
        valid = !!field.value;
      } else if (field.value === null || field.value === undefined || field.value === '') {
        valid = false;
      } else if (Array.isArray(field.value) && field.value.length === 0) {
        valid = false;
      }

      if (!valid) {
        field.validationSummary.message = 'VALIDATOR.REQUIRED';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
      }
    }
    return valid;
  }
}

export class EbenDynamicTableValidator implements FormFieldValidator {
  private supportedTypes = ['dynamic-table'];

  isSupported(field: FormFieldModel): boolean {
    return field && this.supportedTypes.indexOf(field.type) > -1;
  }

  validate(field: FormFieldModel): boolean {
    let valid = true;
    if (this.isSupported(field)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value: any[] = field.value;
      const columnDefinitions: DynamicTableColumn[] = field.json.columnDefinitions;

      if(!value || !columnDefinitions) {
        return valid;
      }

      valid = value.every((row) => {
        let valid = true;
        for (const key in row) {
          const columnValue: string = row[key];
          const columnDefinition: DynamicTableColumn = columnDefinitions.find((cd) => cd.id === key);
          valid = isDynamicTableColumnValid(columnValue, columnDefinition);
          if (!valid) {
            return valid;
          }
        }
        return valid;
      });

      if (!valid) {
        field.validationSummary.message = 'VALIDATOR.DYNAMIC_ROW';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
      }
    }
    return valid;
  }
}

export class EbenPeopleValidator implements FormFieldValidator {
  private supportedTypes = ['people'];

  isSupported(field: FormFieldModel): boolean {
    return field && this.supportedTypes.indexOf(field.type) > -1;
  }

  validate(field: FormFieldModel): boolean {
    return true;
  }
}

export class EbenMultiSelectorValidator implements FormFieldValidator {
  private supportedTypes = ['multi-select-people'];

  isSupported(field: FormFieldModel): boolean {
    return field && this.supportedTypes.indexOf(field.type) > -1;
  }

  validate(field: FormFieldModel): boolean {
    return true;
  }
}

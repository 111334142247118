import { Injectable, Type } from '@angular/core';
import { EbenProcessInstance, EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';

import moment from 'moment-es6';
import { InformationTemplateComponent } from './information-template/information-template.component';
import { ProcessVariableUtils } from "@alf-nx-workspace/shared/utils";
import { ResultTemplateComponent } from "./result-template/result-template.component";
import { BaseResultTemplateComponent } from "../base-result-template.component";
import { BaseService } from "../base.service";
import { BaseInformationTemplateComponent } from "../base-information-template.component";

@Injectable({providedIn: 'root'})
export class ReiseAufService extends BaseService {

  WORKFLOW_NAME = 'reise-auf';
  static WORKFLOW_NAME = 'reise-auf';

  isHighPriority(process: EbenProcessInstance): string {
    const beginnDienstreise: string = ProcessVariableUtils.getVariable(process.variables, 'beginn_dienstreise');
    if (beginnDienstreise) {
      const beginnDienstreiseDate = moment(beginnDienstreise);
      const daysFromBeginnDienstreise = moment(new Date()).diff(beginnDienstreiseDate, 'days');
      if (Math.abs(daysFromBeginnDienstreise) <= 3) {
        return 'Dieser Task ist als wichtig markiert, da das Abreisedatum innerhalb der letzten 3 Tage liegt';
      }
    }
  }

  isLongRunning(process: EbenProcessInstance, task: EbenTaskDetail): string {
    let created: Date = new Date();

    if (task) {
      created = task.created;
    } else if (process.taskRepresentations && process.taskRepresentations.length == 1) {
      created = process.taskRepresentations[0].created;
    }

    const daysFromCreation = moment(new Date()).diff(moment(created), 'days');

    if (daysFromCreation > 14) {
      return 'Dieser Dienstreiseauftrag wurde seit mehr als 14 Tagen nicht mehr bearbeitet';
    }
  }

  getInformationTemplate(): Type<BaseInformationTemplateComponent> {
    return InformationTemplateComponent;
  }

  getResultTemplate(): Type<BaseResultTemplateComponent> {
    return ResultTemplateComponent;
  }
}

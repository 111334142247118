import { inject, Injectable } from '@angular/core';
import { AlfrescoApiService } from '@alfresco/adf-core';
import { catchError, tap } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
import { EbenPagination, EbenProcessInstance, EbenProcessInstanceQueryRepresentation, EbenTaskDetail, EbenTaskQueryRepresentation } from '@alf-nx-workspace/eben/interfaces';
import { ResultListDataRepresentationLightUserRepresentation } from '@alfresco/js-api/src/api/activiti-rest-api/model/resultListDataRepresentationLightUserRepresentation';
import { AlfrescoApiConfig } from '@alfresco/js-api/src/alfrescoApiConfig';
import { QueryError } from "./error.service";


@Injectable({
  providedIn: 'root'
})
export class BpmService {

  readonly alfrescoApiService: AlfrescoApiService = inject(AlfrescoApiService);

  private readonly BOKU_USER_ROOT = '/activiti-app/api/enterprise/boku-flow/users';
  private readonly BOKU_PROCESS_INSTANCE_ROOT = '/activiti-app/api/enterprise/boku-flow/process-instances';
  private readonly BOKU_TASK_ROOT = '/activiti-app/api/enterprise/boku-flow/tasks';
  private readonly CUSTOM_USER_ROOT = '/activiti-app/api/enterprise/custom/users';

  private callApi<T>(path: string, method: string, bodyParam?: unknown, pathParams?: unknown, queryParams?: unknown): Observable<T> {
    const apiConfig: AlfrescoApiConfig = this.alfrescoApiService.getInstance().config;
    const url = `${apiConfig.hostBpm}${path}`;

    return from(
      this.alfrescoApiService.getInstance().oauth2Auth.callCustomApi(
        url, method, pathParams, queryParams, null, null, bodyParam, ['application/json'], ['application/json']
      )
    )
      .pipe(
      tap(() => {
        //throw new Error('ERROR')
      })
    )
  }


  public getUser(userId: number): Observable<ResultListDataRepresentationLightUserRepresentation> {
    const path = `${this.BOKU_USER_ROOT}/{userId}`;
    return this.callApi<ResultListDataRepresentationLightUserRepresentation>(path, 'GET', null, {userId}).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'USER', {userId})))
    );
  }

  /**
   * Query users with a filter and optional groupId restriction. Uses custom endpoint.
   * Filter will be applied to firstname, lastname, fullname, username, email
   * Returns only active users
   * @param filter can be empty string to find all available users
   * @param groupId can be empty string to search without group restrictions
   * @param representedBy  takes an ext.Id (BOKU UUID) and limits the search to users for which this user is a deputy. Can be empty string or omitted
   */
  public queryUsers(filter: string, groupId?: string, representedBy?: string): Observable<ResultListDataRepresentationLightUserRepresentation> {
    const path = `${this.CUSTOM_USER_ROOT}/query`;
    const queryParams = {
      filter: filter || '',
      groupId,
      representedBy
    };
    return this.callApi<ResultListDataRepresentationLightUserRepresentation>(path, 'GET', null, null, queryParams).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'USER', queryParams)))
    );
  }


  public loadMyWorkflows(query: EbenProcessInstanceQueryRepresentation): Observable<EbenPagination<EbenProcessInstance>> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/query/my-workflows`;
    return this.callApi<EbenPagination<EbenProcessInstance>>(path, 'POST', query).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESSES', query)))
    );
  }

  public loadMyWorkflowsNames(): Observable<string[]> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/query/my-workflows/definition-keys`;
    return this.callApi<string[]>(path, 'GET').pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESSES', path)))
    );
  }

  public loadOrgEhWorkflows(query: EbenProcessInstanceQueryRepresentation): Observable<EbenPagination<EbenProcessInstance>> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/query/orgeh-workflows`;
    return this.callApi<EbenPagination<EbenProcessInstance>>(path, 'POST', query).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESSES', query)))
    );
  }

  public loadOrgEhWorkflowsNames(): Observable<string[]> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/query/orgeh-workflows/definition-keys`;
    return this.callApi<string[]>(path, 'GET').pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESSES', path)))
    );
  }

  public loadAdminWorkflows(query: EbenProcessInstanceQueryRepresentation): Observable<EbenPagination<EbenProcessInstance>> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/query/admin-workflows`;
    return this.callApi<EbenPagination<EbenProcessInstance>>(path, 'POST', query).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESSES', query)))
    );
  }

  public loadAdminWorkflowsNames(): Observable<string[]> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/query/admin-workflows/definition-keys`;
    return this.callApi<string[]>(path, 'GET').pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESSES', path)))
    );
  }

  public getProcessInstanceById(processInstanceId: string): Observable<EbenProcessInstance> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/{processInstanceId}`;
    return this.callApi<EbenProcessInstance>(path, 'GET', null, {processInstanceId}).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESS', processInstanceId)))
    );
  }

  public cancelProcessInstance(processInstance: EbenProcessInstance, hasBeenCancelledComment: string): Observable<void> {
    const path = `${this.BOKU_PROCESS_INSTANCE_ROOT}/{processInstanceId}`;
    return this.callApi<void>(path, 'DELETE', null, {processInstanceId: processInstance.id}, {hasBeenCancelledComment}).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'PROCESS-CANCEL', processInstance.id)))
    );
  }

  public queryTasks(query: EbenTaskQueryRepresentation): Observable<EbenPagination<EbenTaskDetail>> {
    const path = `${this.BOKU_TASK_ROOT}/query`;
    return this.callApi<EbenPagination<EbenTaskDetail>>(path, 'POST', query).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'TASKS', query))
    ));
  }

  public getTaskById(taskId: string): Observable<EbenTaskDetail> {
    const path = `${this.BOKU_TASK_ROOT}/{taskId}`;
    return this.callApi<EbenTaskDetail>(path, 'GET', null, {taskId}).pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'TASK', taskId)))
    );
  }

  public getTaskNames(): Observable<{ processDefinitionKey: string, taskNames: string[] }[]> {
    const path = `${this.BOKU_TASK_ROOT}/task-names`;
    return this.callApi<{ processDefinitionKey: string, taskNames: string[] }[]>(path, 'GET').pipe(
      catchError(error => throwError(QueryError.createFromError(error, 'TASKS', path)))
    );
  }

  // @deprecated
  getLatestProcessDefinitionId(appName: string, processDefinitionKey: string) {
    return undefined;
  }
}

import { Injectable } from '@angular/core';
import { BaseProcessor } from '../../base.processor';

enum FIELDS { }

@Injectable({ providedIn: 'root' })
export class UrlaubsfreigabeDelegierenFreigabeZuordnungueberpruefenProcesser extends BaseProcessor {
  static FORM_NAME = 'urlaubsfreigabe-delegieren-freigabe-zuordnungueberpruefen';
  public readonly FORM_NAME = UrlaubsfreigabeDelegierenFreigabeZuordnungueberpruefenProcesser.FORM_NAME;
}

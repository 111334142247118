export enum MasterzulassungVariables {
  vorname = "vorname",
  nachname = "nachname",
  masterstudiumIdf = "masterstudiumIdf",
  antragEingereichtAm = "antragEingereichtAm",
  bescheid = "bescheid",
  ausgestelltAm = "ausgestelltAm",
  matrikelnummer = "matrikelnummer",
  antragBereitsEingereicht = "antragBereitsEingereicht",
  _bokuFlow_workflowResultProtocolJSON = "_bokuFlow_workflowResultProtocolJSON"
}

export const MasterzulassungVariablesSearchable = [
  MasterzulassungVariables.vorname,
  MasterzulassungVariables.nachname,
  MasterzulassungVariables.masterstudiumIdf,
  MasterzulassungVariables.matrikelnummer,
  MasterzulassungVariables.antragEingereichtAm,
  MasterzulassungVariables.ausgestelltAm
]

import { Component } from '@angular/core';
import { EbenProcessInstance } from "@alf-nx-workspace/eben/interfaces";
import { WorkflowInformationTemplate } from "@alf-nx-workspace/flow/interfaces";

@Component({
  selector: 'alf-nx-workspace-base-information-template',
  template: '',
  styleUrls: []
})
export abstract class BaseInformationTemplateComponent implements WorkflowInformationTemplate {
  process: EbenProcessInstance;
  workflowAdmin = false;
  variableMap: Map<string, string>;
}

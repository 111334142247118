import { Component } from '@angular/core';
import { UrlaubsfreigabeDelegierenFreigabeVariables } from "../urlaubsfreigabe-delegieren-freigabe.variables";
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { NgIf } from "@angular/common";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";

@Component({
  selector: 'urlaubsfreigabe-delegieren-freigabe-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent]
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = UrlaubsfreigabeDelegierenFreigabeVariables;

  public getInformationElementProcessEnded() {
    return { name: 'PROCESS.ENDED', value: this.process.ended.toString() };
  }
  public getInformationElementGenehmigerFullname() {
    if (this.fields.genehmigendePersonFullname) {
      return {
        name: `URLAUBSFREIGABE-DELEGIEREN-FREIGABE.${this.fields.genehmigendePersonFullname.toUpperCase()}`,
        value: this.variableMap.get(this.fields.genehmigendePersonFullname)
      };
    }
  }

  public getInformationElementStellvertreterFullname() {
    if (this.fields.urlaubsgenehmigerStellvertretungFullname) {
      return {
        name: `URLAUBSFREIGABE-DELEGIEREN-FREIGABE.${this.fields.urlaubsgenehmigerStellvertretungFullname.toUpperCase()}`,
        value: this.variableMap.get(this.fields.urlaubsgenehmigerStellvertretungFullname)
      };
    }
  }

  public getInformationElementCurrentPersonenFullnames() {
    if (this.fields.currentPersonenFullnames) {
      return {
        name: `URLAUBSFREIGABE-DELEGIEREN-FREIGABE.${this.fields.currentPersonenFullnames.toUpperCase()}`,
        value: this.variableMap.get(this.fields.currentPersonenFullnames)
      };
    }
  }
}

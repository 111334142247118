import { inject, Injectable, Injector } from '@angular/core';
import { FormFieldModel, FormModel, FormService, FormValues, ValidateFormEvent, WidgetVisibilityService } from '@alfresco/adf-core';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import { FormFieldWarning, FormProcessor } from '@alf-nx-workspace/eben/interfaces';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';

import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';
import moment from 'moment-es6';
import { FormProcessorService, SnackbarService } from '@alf-nx-workspace/shared/utils';
import { isEqual } from 'lodash-es';
import { ProcessInstance, ProcessInstanceVariable, ProcessService } from "@alfresco/adf-process-services";
import { ProcessError, TaskError } from "@alf-nx-workspace/shared/utils";

interface FormValueSnapShot {
  [key: string]: string;
}

@Injectable({ providedIn: 'root' })
export class EbenFormService {

  readonly processService = inject(ProcessService)
  readonly snackbarService = inject(SnackbarService);

  /**
   * information if an eben-widget changes
   */
  public readonly formFieldChangedSubject: Subject<FormFieldModel> = new Subject<FormFieldModel>();
  public readonly formFieldChanged$: Observable<FormFieldModel> = this.formFieldChangedSubject.asObservable();

  /**
   * information if an eben-widget receives focus
   */
  private readonly formFieldWidgetFocusInSubject: Subject<FormFieldModel> = new Subject<FormFieldModel>();
  public readonly formFieldWidgetFocusIn$: Observable<FormFieldModel> =
    this.formFieldWidgetFocusInSubject.asObservable();

  public form: FormModel;
  private formValueSnapshot: FormValueSnapShot = {};

  private ebenFormProcessor: FormProcessor;

  constructor(
    public formService: FormService,
    public ebenFacade: EbenFacade,
    public injector: Injector,
    public formProcessorService: FormProcessorService
  ) {


    /**
     * updates the state with the correct error fields
     */
    this.formService.validateForm
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return prev.errorsField.length === curr.errorsField.length;
        })
      )
      .subscribe((event: ValidateFormEvent) => {
        const errorFormFields: FormFieldModel[] = event.errorsField;
        const errorsField: FormFieldModel[] = errorFormFields.filter(
          (field: FormFieldModel) => field.fieldType !== 'ContainerRepresentation'
        );
        this.ebenFacade.setFormErrorFields(errorsField);
      });
  }

  public formLoaded(form: FormModel): void {
    this.form = form;

    if (form.name) {
      // This applies for the task form
      this.ebenFormProcessor = this.formProcessorService.getFormProcessor(form.name);
    } else {
      // This applies for a start form.
      // Start forms don't supply a name, therefore the processDefinitionKey is used to select the
      // appropriate FormProcessor
      this.ebenFormProcessor = this.formProcessorService.getFormProcessor(form.json['processDefinitionKey']);
    }

    if(!this.ebenFormProcessor) {
      this.ebenFormProcessor = this.formProcessorService.getBaseProcessor();
    }

    if (this.ebenFormProcessor) {
      this.ebenFormProcessor.onLoaded(form);
    }

    this.updateFormSnapshot(form);
    this.updateFormFieldWarnings(form);

    this.ebenFacade.setFormModel(form);
    this.ebenFacade.setFormModelDirty(false);
  }

  /**
   * this method is used to inform the processor of changes made to a form field value
   * it is called from the eben-widget components
   *
   * @param field
   */
  public onFieldValueChange(field: FormFieldModel): void {
    // update the visibility state if all fields and then
    // update the validation of the form
    this.updateVisibility(field.form);

    // check the form dirty state and then persist the form values
    this.updateFormDirtyState(field);
    if (this.formValueSnapshot && !(field.id in this.formValueSnapshot)) {
      this.formValueSnapshot[field.id] = field.value;
    }
    this.ebenFacade.setFormValues(field.id, field.form.values);

    if (this.ebenFormProcessor) {
      this.ebenFormProcessor.onFieldValueChange(field);
      this.updateFormFieldWarnings(field.form);
    }
  }

  /**
   * this method is used to get informed when the form-processor updates a form field
   * changes can be made to the form value or the attributes
   *
   * @param field
   */
  public onFormFieldChanged(field: FormFieldModel): void {
    this.ebenFacade.setFormValues(field.id, field.form.values);
    this.formFieldChangedSubject.next(field);
  }

  /**
   * triggers when a form-field-widget receives focus
   * it is called from the eben-widget components
   *
   * @param field
   */
  public onFormFieldWidgetFocusIn(field: FormFieldModel): void {
    if (this.ebenFormProcessor) {
      this.ebenFormProcessor.onFieldFocusIn(field);
    }
    this.updateVisibility(field.form);
  }

  /**
   * triggers when a form-field-widget looses focus
   * it is called from the eben-widget components
   *
   * @param field
   */
  public onFormFieldWidgetFocusOut(field: FormFieldModel): void {
    if (this.ebenFormProcessor) {
      this.ebenFormProcessor.onFieldFocusOut(field);
    }
    this.updateVisibility(field.form);
  }

  public startProcess(processDefinitionId: string, name: string, outcome?: string, startFormValues?: FormValues, variables?: ProcessInstanceVariable[]): Observable<ProcessInstance> {
    return this.processService.startProcess(processDefinitionId, name, outcome, startFormValues, variables)
      .pipe(
        catchError((error) => {
          return throwError(ProcessError.createFromError(error, 'START', processDefinitionId));
        })
      )
  }

  /**
   * saves the current form
   */
  public saveForm(): Observable<FormValueSnapShot> {
    return this.formService.saveTaskForm(this.form.taskId, this.form.values).pipe(
      tap(() => {
        this.updateFormSnapshot(this.form);
        this.ebenFacade.setFormModelDirty(false);
        this.ebenFacade.saveForm(this.form);
        this.snackbarService.info('SNACKBAR.FORM_SAVED');
      }),
      catchError((error) => {
        return throwError(TaskError.createFromError(error, 'SAVE', this.form.taskId));
      })
    );
  }

  public completeTaskForm(taskId: string, formValues: FormValues, outcome?: string): Observable<void> {
    return this.formService.completeTaskForm(taskId, formValues, outcome).pipe(
      tap(() => {
        this.ebenFacade.setFormModelDirty(false);
        this.ebenFacade.completeTask();
      }),
      catchError((error) => {
        return throwError(TaskError.createFromError(error, 'COMPLETE', taskId));
      })
    );
  }

  /**
   * stores the form field warnings generated by the form processor to the state
   * to show global warnings in the form
   *
   * @private
   */
  private updateFormFieldWarnings(form: FormModel) {
    if (this.ebenFormProcessor) {
      const warnings: FormFieldWarning[] = this.ebenFormProcessor.getFormFieldWarnings(form);
      this.ebenFacade.setFormFieldWarnings(warnings);
    }
  }

  /**
   *
   *
   * @param field
   */
  private updateFormDirtyState(field: FormFieldModel): void {
    let isDirty = false;
    if (field.type === 'date') {
      const formValue = moment(field.value, field.dateDisplayFormat).startOf('day');
      const snapshotValue = moment(this.formValueSnapshot[field.id]).startOf('day');

      if (!formValue.isSame(snapshotValue)) {
        isDirty = true;
      }
    } else {
      if (!isEqual(field.value, this.formValueSnapshot[field.id])) {
        isDirty = true;
      }
    }

    if (isDirty) {
      this.ebenFacade.setFormModelDirty(true);
    }
  }

  /**
   * for custom widgets the form visibility is not checked so we have to do it manually
   * logic implemented from FormFieldComponent::100
   */
  private updateVisibility(form) {
    const visibilityService: WidgetVisibilityService = this.injector.get(WidgetVisibilityService);
    if (visibilityService && form) {
      visibilityService.refreshVisibility(form);
    }
  }

  /**
   * to check the dirty state of the form we need to store a snapshot of the form values
   *
   * @param form
   * @private
   */
  private updateFormSnapshot(form: FormModel) {
    this.formValueSnapshot = {};
    for (const [key, value] of Object.entries(form.values)) {
      this.formValueSnapshot[key] = value ?? '';
    }
  }
}

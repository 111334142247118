import { Component, inject } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { DialogAuthenticationErrorComponent } from "./dialog-authentication-error/dialog-authentication-error.component";
import { FlowSessionService } from "../services/flow-session.service";

@Component({
  selector: 'authentication-error-page',
  templateUrl: './authentication-error-page.component.html',
  styleUrls: ['./authentication-error-page.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatDialogModule, DialogAuthenticationErrorComponent]
})
export class AuthenticationErrorPageComponent {
  readonly dialog = inject(MatDialog);

  ngOnInit() {
    this.dialog.open(DialogAuthenticationErrorComponent, {
      hasBackdrop: true,
      disableClose: true,
      closeOnNavigation: false,
    });
  }
}

import { Component } from '@angular/core';
import { InformationElement } from '@alf-nx-workspace/shared/interfaces';
import { ReiseAufVariables } from '../reise-auf.variables';
import { NgIf } from "@angular/common";
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";


@Component({
  selector: 'reise-auf-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent]
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = ReiseAufVariables;

  public getInformationElement(name: string): InformationElement {
    return {
      name: 'REISE-AUF.' + name.toUpperCase(),
      value: this.variableMap.get(name)
    };
  }

  public getInformationElementProcessEnded() {
    return { name: 'PROCESS.ENDED', value: this.process.ended.toString() };
  }
  public getInformationElementKostenverantwortlicher() {
    return { name: 'REISE-AUF.KOSTENVERANTWORTLICHERFULLNAME', value: this.getKostenverantwortlicherFullname() };
  }

  public getInformationElementVorgesetzterFullname() {
    return { name: 'REISE-AUF.VORGESETZTERFULLNAME', value: this.getVorgesetzterFullname() };
  }

  public getInformationElementZusaetzlich() {
    return { name: 'REISE-AUF.ZUSATZFULLNAME', value: this.getZusatzPersonFullname() };
  }

  public getVorgesetzterFullname(): string {
    const nachname = this.variableMap.get(this.fields.vorgesetzterNachname) || '';
    const vorname = this.variableMap.get(this.fields.vorgesetzterVorname) || '';
    if(nachname && vorname) {
      return nachname.toUpperCase() + ', ' + vorname;
    }
  }

  public getKostenverantwortlicherFullname(): string {
    const nachname = this.variableMap.get(this.fields.kostenverantwortlicherNachname) || '';
    const vorname = this.variableMap.get(this.fields.kostenverantwortlicherVorname) || '';
    if(nachname && vorname) {
      return nachname.toUpperCase() + ', ' + vorname;
    }
  }

  public getZusatzPersonFullname(): string {
    const nachname = this.variableMap.get(this.fields.zusatzPersonNachname) || '';
    const vorname = this.variableMap.get(this.fields.zusatzPersonVorname) || '';
    if(nachname && vorname) {
      return nachname.toUpperCase() + ', ' + vorname;
    }
  }
}

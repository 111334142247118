/*!
 * @license
 * Copyright 2018 Alfresco Software, Ltd.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { AlfrescoApi, AuthenticationApi, Oauth2Auth } from '@alfresco/js-api';

export class FlowOauth2Auth extends Oauth2Auth {

  private disableSilentRefresh = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkFragment(nonceKey?: string, externalHash?: any): any {

    if(this.disableSilentRefresh) {
      return;
    }

    // jshint ignore:line
    this.hashFragmentParams = this.getHashFragmentParams(externalHash);
    if (this.hashFragmentParams && this.hashFragmentParams.error === undefined) {
      this.useFragmentTimeLoginFix(nonceKey);
    } else {
      this.refreshBrowserLoginFix();
    }
  }

  useFragmentTimeLoginFix(nonceKey) {
    const accessToken = this.hashFragmentParams.access_token;
    const idToken = this.hashFragmentParams.id_token;
    const sessionState = this.hashFragmentParams.session_state;
    const expiresIn = this.hashFragmentParams.expires_in;

    if (!sessionState) {
      throw 'session state not present';
    }
    try {
      const jwt = this.processJWTToken(idToken, nonceKey);
      if (jwt) {
        this.storeIdTokenFix(idToken, jwt.payload.exp, jwt.payload.iat);
        this.storeAccessToken(accessToken, expiresIn);
        this.authentications.basicAuth.username = jwt.payload.preferred_username;
        this.saveUsername(jwt.payload.preferred_username);
        return accessToken;
      }
    } catch (error) {
      throw 'Validation JWT error' + error;
    }
  }

  storeIdTokenFix(idToken, exp, iat) {
    this.storage.setItem('id_token', idToken);
    this.storage.setItem('id_token_expires_at', Number(exp * 1000).toString());
    this.storage.setItem('id_token_stored_at', Date.now().toString());
    this.storage.setItem('id_token_issued_at', Number(iat * 1000).toString());
  }

  isValidToken() {
    let validToken = false;
    if (this.getIdToken()) {
      const expiresAt = this.storage.getItem('id_token_expires_at');
      const issuedAt = this.storage.getItem('id_token_issued_at');
      let now = new Date().getTime();
      if (issuedAt && parseInt(issuedAt, 10)) {
        const storedAt = this.storage.getItem('id_token_stored_at');
        const diff = storedAt - issuedAt;
        now -= diff;
      }
      if (expiresAt && parseInt(expiresAt, 10) >= now) {
        validToken = true;
      }
    }
    return validToken;
  }

  private refreshBrowserLoginFix() {
    if (this.config.oauth2.silentLogin && !this.isPublicUrl()) {
      this.implicitLogin();
    }

    if (this.isValidToken() && this.isValidAccessToken()) {
      const accessToken = this.storage.getItem('access_token');
      this.setToken(accessToken, null);
      this.silentRefresh();
    }
  }

  invalidateSession() {
    super.invalidateSession();
    this.storage.removeItem('id_token_issued_at');
    this.storage.removeItem('username');
    this.storage.removeItem('refresh_nonce');
  }

  createIframe() {
    this.disableSilentRefresh = false;
    const iframe = document.createElement('iframe');
    iframe.id = 'silent_refresh_token_iframe';
    const loginUrl = this.composeIframeLoginUrl();
    iframe.setAttribute('src', loginUrl);
    iframe.style.display = 'none';
    document.body.appendChild(iframe);
    this.iFrameHashListener = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const silentRefreshTokenIframe: any = document.getElementById('silent_refresh_token_iframe');
      const hash = silentRefreshTokenIframe.contentWindow.location.hash;
      try {
        this.checkFragment('refresh_nonce', hash);
      }
      catch (e) {
        this.disableSilentRefresh = true;
        this.emit('session_error', e);
      }
    };
    iframe.addEventListener('load', this.iFrameHashListener);
  }

  exchangeTicketListener(alfrescoApi: AlfrescoApi) {
    this.on('token_issued', async () => {
      const authContentApi: AuthenticationApi = new AuthenticationApi(alfrescoApi);
      // Legacy stuff, needs deprecation, can not be more specific here because of circular dependency
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (authContentApi.apiClient as unknown as any).authentications = this.authentications;
      try {
        const ticketEntry = await authContentApi.getTicket();
        this.config.ticketEcm = ticketEntry.entry.id;
        this.emit('ticket_exchanged');
        this.silentRefresh();
      } catch (e) {
        this.disableSilentRefresh = true;
        if(e.response && (e.response.status === 401 || e.response.status === 403)) {
          this.emit('session_error', e);
        }
      }
    });
  }
}

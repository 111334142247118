import { Component, inject, Input, SimpleChanges, Type, ViewChild, ViewContainerRef, } from '@angular/core';
import { EbenProcessInstance } from '@alf-nx-workspace/eben/interfaces';
import { ProcessVariableUtils, WorkflowService } from '@alf-nx-workspace/shared/utils';
import { AdDirective } from "../dynamic.directive";
import { WorkflowInformationTemplate, WorkflowServiceModel } from "@alf-nx-workspace/flow/interfaces";


@Component({
  selector: 'shared-ui-process-information',
  templateUrl: './process-information.component.html',
  styleUrls: ['./process-information.component.scss'],
})
export class ProcessInformationComponent {

  readonly workflowService = inject(WorkflowService);

  @ViewChild(AdDirective, {static: true, read: ViewContainerRef}) private vcr!: ViewContainerRef;

  @Input()
  process: EbenProcessInstance;

  @Input()
  showAdminInformation = false;

  ngOnChanges(changes: SimpleChanges) {
    this.loadComponent();
  }


  private loadComponent() {
    if (this.process) {
      const workflowService: WorkflowServiceModel = this.workflowService.getServiceByProcessDefinitionKey(this.process.processDefinitionKey);
      if (workflowService) {
        const variableMap = ProcessVariableUtils.getProcessInstanceVariableAsMap(this.process);
        this.vcr.clear();
        const componentType = workflowService.getInformationTemplate();
        const component = this.vcr.createComponent(componentType);

        component.instance.process = this.process;
        component.instance.workflowAdmin = this.showAdminInformation;
        component.instance.variableMap = variableMap;
      }
    }
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgIf } from "@angular/common";
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { EntsorgungAnfordernService } from "../entsorgung-anfordern.service";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";


@Component({
  selector: 'entsorgung-anfordern-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = EntsorgungAnfordernService;

  public getInformationElementProcessEnded() {
    return { name: 'PROCESS.ENDED', value: this.process.ended.toString() };
  }

}

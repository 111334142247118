import { Component, inject } from '@angular/core';

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { RxState, selectSlice } from "@rx-angular/state";
import { MatButtonModule } from "@angular/material/button";
import { BaseResultTemplateComponent } from "../../base-result-template.component";


interface ResultTemplateComponentState {
  variableMap: Map<string, string>,
  workflowResult: string
}

@Component({
  selector: 'entsorgung-anfordern-result-template',
  templateUrl: './result-template.component.html',
  styleUrls: ['./result-template.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, MatButtonModule],
  providers: [RxState]
})
export class ResultTemplateComponent extends BaseResultTemplateComponent {

  readonly rxState = inject<RxState<ResultTemplateComponentState>>(RxState);
  readonly vm$ = this.rxState.select(selectSlice(["workflowResult"]));

  ngOnInit() {
    this.connectState();
    this.sideEffects();
  }

  private connectState() {
    this.rxState.connect("workflowResult", this.workflowResultSubject);
  }

  private sideEffects() { }
}

import { Component, Input } from '@angular/core';
import { RouteModel } from '@alf-nx-workspace/shared/interfaces';

@Component({
  selector: 'shared-ui-link-list',
  templateUrl: './link-list.component.html',
  styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent {
  @Input()
  routes: RouteModel[];
}

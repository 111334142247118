import { FormFieldModel, FormModel } from '@alfresco/adf-core';
import { inject, Injectable } from '@angular/core';
import { EbenFacade } from '@alf-nx-workspace/eben/data-access';
import { BaseProcessor } from '../../base.processor';
import { ReiseAufVariables } from "../reise-auf.variables";

@Injectable({ providedIn: 'root' })
export class ReiseAufFreigabeReisenderProcessor extends BaseProcessor {
  static FORM_NAME = 'reiseAufFreigabeReisender';
  public readonly FORM_NAME = ReiseAufFreigabeReisenderProcessor.FORM_NAME;

  readonly ebenFacade = inject(EbenFacade);

  public onLoaded(form: FormModel) {
    super.onLoaded(form);

    // The "back" outcome should always be accessible, even when the form is invalid
    this.ebenFacade.setOutcomesExemptFromValidation(['back']);
  }

  public onFieldValueChange(field: FormFieldModel) {
    if (field.id === ReiseAufVariables.freigabe) {
      field.form.validateForm();
    }
  }
}

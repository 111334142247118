import { Injectable, Type } from '@angular/core';
import { InformationTemplateComponent } from './information-template/information-template.component';

import { BaseService } from "../base.service";

@Injectable({providedIn: 'root'})
export class ExampleWorkflowService extends BaseService {

  WORKFLOW_NAME = 'example-workflow';
  static WORKFLOW_NAME = 'example-workflow';

  getInformationTemplate(): Type<InformationTemplateComponent> {
    return InformationTemplateComponent;
  }
}

<div *ngIf="variableMap">
  <ng-container *ngIf='workflowAdmin'>
    <shared-ui-information-element
      *ngIf="this.process.ended"
      [information]='getInformationElementProcessEnded()'
      type='date'
      format='dd.MM.yyyy HH:mm'
    ></shared-ui-information-element>
  </ng-container>

  <shared-ui-information-element
    [information]="getInformationElement(fields.antStellName)"></shared-ui-information-element>
  <shared-ui-information-element
    [information]="getInformationElement(fields.bvAntragsart)"></shared-ui-information-element>
  <shared-ui-information-element [information]="getInformationElement(fields.bvAb)"
                                 type="date"></shared-ui-information-element>
  <shared-ui-information-element
    [information]="getInformationElementBvBis()"
    [type]="getInformationElementBvBis().value == 'HOMEOFFICE-VEREINBARUNG.BVUNBEFRISTET' ? 'text' : 'date'"
  ></shared-ui-information-element>

</div>

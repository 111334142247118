import { animate, group, query, stagger, style, transition, trigger } from '@angular/animations';

export const Animations = {
  stagger: trigger('stagger', [
    transition('* => *', [
      query(':enter', [style({opacity: 0}), stagger(150, [animate('500ms ease', style({opacity: 1}))])], {
        optional: true
      })
    ])
  ]),
  fadeIn: trigger('fadeIn', [
    transition(':enter', [
      style({opacity: 0, transform: 'translateX(5px)'}),
      animate('500ms ease', style({opacity: 1, transform: 'translateX(0)'}))
    ]),
    transition(':leave', [
      style({opacity: 1, transform: 'translateX(0)'}),
      animate('500ms ease', style({opacity: 0, transform: 'translateX(5px)'}))
    ])
  ]),
  slideIn: trigger('slideInOut', [
    transition(
      ':enter',
      [
        style({
          'max-height': '0px', 'opacity': '0', 'visibility': 'hidden'
        }),
        group([
            animate('1ms ease-in-out', style({
              'visibility': 'visible'
            })),
            animate('600ms ease-in-out', style({
              'max-height': '500px'
            })),
            animate('800ms ease-in-out', style({
              'opacity': '1'
            }))
          ]
        )]),
    transition(
      ':leave',
      [
        style({
          'max-height': '500px', 'opacity': '1', 'visibility': 'visible'
        }),
        group(
          [
            animate('400ms ease-in-out', style({
              'opacity': '0'
            })),
            animate('600ms ease-in-out', style({
              'max-height': '0px'
            })),
            animate('700ms ease-in-out', style({
              'visibility': 'hidden'
            }))
          ]
        )])
  ])
};

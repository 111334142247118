import { Injectable, Type } from '@angular/core';
import { EbenProcessInstance, EbenTaskDetail } from '@alf-nx-workspace/eben/interfaces';


import { WorkflowServiceModel } from "@alf-nx-workspace/flow/interfaces";
import { BaseInformationTemplateComponent } from "./base-information-template.component";
import { BaseResultTemplateComponent } from "./base-result-template.component";


@Injectable({ providedIn: 'root' })
export class BaseService implements WorkflowServiceModel {

  WORKFLOW_NAME = 'base';
  static WORKFLOW_NAME = 'base';

  constructor() {
  }

  isHighPriority(process: EbenProcessInstance): string {
    return '';
  }

  isLongRunning(process: EbenProcessInstance, task: EbenTaskDetail): string {
    return '';
  }

  getInformationTemplate(): Type<BaseInformationTemplateComponent> {
    return null;
  }

  getResultTemplate(): Type<BaseResultTemplateComponent> {
    return null;
  }
}

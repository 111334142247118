import { Injectable } from '@angular/core';
import { ProcessService, TaskListService } from '@alfresco/adf-process-services';
import { AlfrescoApiService, FormService } from '@alfresco/adf-core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RestVariable } from '@alfresco/js-api/src/api/activiti-rest-api/model/restVariable';
import { HttpClient } from '@angular/common/http';
import { EbenPagination, EbenTaskDetail, EbenTaskQueryRepresentation } from '@alf-nx-workspace/eben/interfaces';
import { BpmService } from '@alf-nx-workspace/shared/utils';

@Injectable({
  providedIn: 'root',
})
export class EbenService {
  constructor(
    public http: HttpClient,
    public taskListService: TaskListService,
    public formService: FormService,
  ) {}

  public getTaskDetails(taskId: string) {
    return this.taskListService.getTaskDetails(taskId).pipe(
      catchError(error => {
        throw Error(error);
      })
    );
  }
}

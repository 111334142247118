import { FormModel } from '@alfresco/adf-core';
import { EbenFormFieldModel } from '@alf-nx-workspace/eben/interfaces';
import { HomeofficeVereinbarungValidator } from '../homeoffice-vereinbarung.validator';
import { BaseProcessor } from '../../base.processor';
import { Injectable } from '@angular/core';
import { HomeofficeVereinbarungVariables } from '../homeoffice-vereinbarung.variables';

@Injectable({ providedIn: 'root' })
export class HomeofficeVereinbarungGrunddatenProcesser extends BaseProcessor {
  static FORM_NAME = 'boku-homeoffice-grunddaten';
  public readonly FORM_NAME = HomeofficeVereinbarungGrunddatenProcesser.FORM_NAME;
  public onLoaded(form: FormModel) {
    super.onLoaded(form);
    this.addFormFieldValidator(new HomeofficeVereinbarungValidator());

    this.setMinValuesOnDatesToTaskCreationDates(form);
  }

  public onFieldValueChange(field: EbenFormFieldModel) {
    if (field.id === HomeofficeVereinbarungVariables.bvAntragsart) {
      field.updateForm();
    }

    if (field.id === HomeofficeVereinbarungVariables.bvBis) {
      const bvAb: EbenFormFieldModel = this.getFormFieldByName(field.form, HomeofficeVereinbarungVariables.bvAb);
      bvAb.maxValue = field.value || null;

      this.updateField(bvAb);
    }

    if (field.id === HomeofficeVereinbarungVariables.bvUnbefristet) {
      const bvAb: EbenFormFieldModel = this.getFormFieldByName(field.form, HomeofficeVereinbarungVariables.bvAb);
      const bvBis: EbenFormFieldModel = this.getFormFieldByName(field.form, HomeofficeVereinbarungVariables.bvBis);
      if (field.value === true) {
        bvBis.value = null;
        bvAb.maxValue = null;
        this.updateField(bvAb);
        this.updateField(bvBis);
      }
    }

    // ALF-927 always set dynamic table dropdown value to string (and not object)
    if(field.id === HomeofficeVereinbarungVariables.homeofficearbeitsplatz) {
      field.value = field.value.map((value) => {
        const wohnsitz = typeof value.wohnsitz === 'string' ? value.wohnsitz : value.wohnsitz.name;
        return ({
          ...value,
          wohnsitz
        })
      })
      this.updateField(field);
    }
  }

  /**
   * ALF-769: Limits the min value from date fields in the Homeoffice-vereinbarung to the tasks created date.
   * This replaces the original min value of 01.07.2022
   *
   * @param form  Form model
   * @private
   */
  private setMinValuesOnDatesToTaskCreationDates(form: FormModel) {
    const bvAb: EbenFormFieldModel = this.getFormFieldByName(form, HomeofficeVereinbarungVariables.bvAb);
    const bvBis: EbenFormFieldModel = this.getFormFieldByName(form, HomeofficeVereinbarungVariables.bvBis);
    const taskCreationDate: EbenFormFieldModel = this.getFormFieldByName(
      form,
      HomeofficeVereinbarungVariables.taskCreationDate
    );

    if (taskCreationDate) {
      if (bvAb.minValue == taskCreationDate.value) {
        return;
      }
      bvAb.minValue = bvBis.minValue = taskCreationDate.value;
    } else {
      // ALF-769 - set default values for min values
      // existing workflows will not have the taskCreationDate field
      bvAb.minValue = bvBis.minValue = '22.03.2023';
    }

    this.updateField(bvAb);
    this.updateField(bvBis);
  }
}

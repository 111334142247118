import { NgModule } from '@angular/core';
import { WORKFLOW_SERVICE_MAPPING } from '@alf-nx-workspace/shared/utils';

import { ExampleWorkflowService } from './example-workflow.service';

@NgModule({
  providers: [

    {provide: WORKFLOW_SERVICE_MAPPING, useValue: ExampleWorkflowService, multi: true},
  ],
})
export class ExampleWorkflowModule {
}

import { Injectable } from '@angular/core';
import { BaseProcessor } from '../../base.processor';
import { FormFieldModel } from '@alfresco/adf-core';
import { EbenPeopeFormFieldModel } from '@alf-nx-workspace/eben/interfaces';
import { DokumentenFreigabeVariables } from '../dokumenten-freigabe.variables';

@Injectable({ providedIn: 'root' })
export class DokumentenFreigabeStartFormularProcessor extends BaseProcessor {
  static FORM_NAME = 'dokumenten-freigabe';
  public readonly FORM_NAME = DokumentenFreigabeStartFormularProcessor.FORM_NAME;

  onFieldValueChange(field: FormFieldModel): void {
    if (field.id === DokumentenFreigabeVariables.additionalReviewers) {
      const lastReviewerField: EbenPeopeFormFieldModel = this.getFormFieldByName(
        field.form,
        DokumentenFreigabeVariables.lastReviewer
      ) as EbenPeopeFormFieldModel;
      if (Array.isArray(field.value) && field.value.length > 0) {
        lastReviewerField.notSelectable = field.value;
      } else {
        lastReviewerField.notSelectable = [];
      }
    }

    if (field.id === DokumentenFreigabeVariables.lastReviewer) {
      const additionalReviewersField: EbenPeopeFormFieldModel = this.getFormFieldByName(
        field.form,
        DokumentenFreigabeVariables.additionalReviewers
      ) as EbenPeopeFormFieldModel;
      if (field.value) {
        additionalReviewersField.notSelectable = [field.value];
      } else {
        additionalReviewersField.notSelectable = [];
      }
    }
  }
}

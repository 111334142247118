import { FormFieldModel, FormFieldValidator } from '@alfresco/adf-core';

export class VorschussBetragValidator implements FormFieldValidator {
  isSupported(field: FormFieldModel): boolean {
    return field.id === 'vorschussBetrag';
  }

  validate(field: FormFieldModel): boolean {
    if (this.isSupported(field)) {
      if (Number.parseInt(field.value) < 75 && field.form.values.vorschuss) {
        field.validationSummary.message = 'REISE-AUF.ERROR.VORSCHUSS_BETRAG.BELOW_75';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
        return false;
      }
      if (Number.parseInt(field.value) > Number.parseInt(field.maxValue)) {
        field.validationSummary.message = 'REISE-AUF.ERROR.VORSCHUSS_BETRAG.ABOVE_GESCHATZTE_REISEKOSTEN';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
        return false;
      }
    }
    return true;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { defaultEbenTaskQueryRepresentation, EbenPagination, EbenTaskDetail, EbenTaskQueryRepresentation } from '@alf-nx-workspace/eben/interfaces';
import { PageEvent } from '@angular/material/paginator';
import { RxState } from "@rx-angular/state";
import { LightUserRepresentation } from "@alfresco/js-api";

enum AssigneeStates {
  'ASSIGNEE' = 'assignee',
  'CANDIDATE_UNASSIGNED' = 'candidate_unassigned',
  'CANDIDATE_ASSIGNED' = 'candidate_assigned'
}

@Component({
  selector: 'shared-ui-flow-task-list',
  templateUrl: './flow-task-list.component.html',
  styleUrls: ['./flow-task-list.component.scss'],
  providers: [RxState]
})
export class FlowTaskListComponent {

  @Input()
  user: LightUserRepresentation;

  @Input()
  tasks: EbenPagination<EbenTaskDetail>;

  @Input()
  page: PageEvent;

  @Input()
  assignment: string;

  @Output()
  queryChanges: EventEmitter<EbenTaskQueryRepresentation> = new EventEmitter<EbenTaskQueryRepresentation>();

  readonly AssigneeStates = AssigneeStates;

  public trackItem(index: number, item: EbenTaskDetail) {
    return item.id;
  }

  public onPageChange(event: PageEvent) {
    this.queryChanges.emit({page: event.pageSize !== this.page.pageSize ? defaultEbenTaskQueryRepresentation.page : event.pageIndex, size: event.pageSize});
  }

  public onPropertyChange(key: string, value: string) {
    this.queryChanges.emit({[key]: value, page: defaultEbenTaskQueryRepresentation.page, size: defaultEbenTaskQueryRepresentation.size})
  }

  public onQueryChipRemove(key: string) {
    this.queryChanges.emit({[key]: null, page: defaultEbenTaskQueryRepresentation.page, size: defaultEbenTaskQueryRepresentation.size})
  }
}

import { FormFieldModel, FormFieldValidator } from '@alfresco/adf-core';

export class DokumentenVerteilungValidator implements FormFieldValidator {
  isSupported(field: FormFieldModel): boolean {
    return field.id === 'documentDistribution';
  }

  validate(field: FormFieldModel): boolean {
    if (this.isSupported(field)) {
      const value = field.value;
      const result = value.filter((element: unknown) => {
        // check if organisationsrolle is the only property set in element
        return Object.keys(element).length === 1 && element["DOKUMENTEN-VERTEILUNG.VERTEILUNG.MATRIX.ORGANISATIONSROLLE"]
      });
      if(result && result.length > 0) {
        field.validationSummary.message = 'DOKUMENTEN-VERTEILUNG.VERTEILUNG.MATRIX.KEINE-ROLLEN';
        field.validationSummary.attributes = new Map<string, string>();
        field.validationSummary.attributes.set('fieldname', field.name);
        return false;
      }
    }
    return true;
  }
}

import { ResultSetPaging } from '@alfresco/js-api';

export class EbenResultSetPaging extends ResultSetPaging {
  pending?: boolean;
}

export const EMPTY_EBEN_RESULT_SET_PAGING: EbenResultSetPaging = {
  list: null,
  pending: false
};

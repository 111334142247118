import { Component } from '@angular/core';
import { InformationElement } from '@alf-nx-workspace/shared/interfaces';
import { UrlaubsfreigabeDelegierenVariables } from '../urlaubsfreigabe-delegieren.variables';
import { InformationElementComponent } from "@alf-nx-workspace/shared/ui";
import { NgIf } from "@angular/common";
import { BaseInformationTemplateComponent } from "../../base-information-template.component";



@Component({
  selector: 'urlaubsfreigabe-delegieren-information-template',
  templateUrl: './information-template.component.html',
  styleUrls: ['./information-template.component.scss'],
  standalone: true,
  imports: [NgIf, InformationElementComponent]
})
export class InformationTemplateComponent extends BaseInformationTemplateComponent {

  public readonly fields = UrlaubsfreigabeDelegierenVariables;

  public getInformationElementProcessId(): InformationElement {
    return { name: 'PROCESS.ID', value: this.process.id };
  }

  public getInformationElementProcessStarted(): InformationElement {
    return { name: 'PROCESS.STARTED', value: this.process.started.toString() };
  }

  public getInformationElementProcessEnded() {
    return { name: 'PROCESS.ENDED', value: this.process.ended.toString() };
  }

  public getInformationElementGenehmigerFullname() {
    if(this.fields.genehmigendePersonFullname) {
        return {
          name: `URLAUBSFREIGABE-DELEGIEREN.${this.fields.genehmigendePersonFullname.toUpperCase()}`,
          value: this.variableMap.get(this.fields.genehmigendePersonFullname)
        };
    }
  }

  public getInformationElementStellvertreterFullname() {
    if (this.fields.urlaubsgenehmigerStellvertretungFullname) {
      return {
        name: `URLAUBSFREIGABE-DELEGIEREN.${this.fields.urlaubsgenehmigerStellvertretungFullname.toUpperCase()}`,
        value: this.variableMap.get(this.fields.urlaubsgenehmigerStellvertretungFullname)
      };
    }
  }

  public getAntragstellerFullname() {
    if (this.fields.urlaubsantragstellendePersonenNameList) {
      return {
        name: `URLAUBSFREIGABE-DELEGIEREN.${this.fields.urlaubsantragstellendePersonenNameList.toUpperCase()}`,
        value: this.variableMap.get(this.fields.urlaubsantragstellendePersonenNameList)
      };
    }
  }
}

import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { EbenProcessInstance } from '@alf-nx-workspace/eben/interfaces';
import { LightUserRepresentation, RestVariable } from "@alfresco/js-api";
import { FullNamePipe } from "@alfresco/adf-core";
import { TranslatePipe } from "@ngx-translate/core";
import { AuthFacade } from "@alf-nx-workspace/auth/data-access";

@Component({
  selector: 'shared-ui-process-name',
  templateUrl: './process-name.component.html',
  styleUrls: ['./process-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FullNamePipe, TranslatePipe]
})
export class ProcessNameComponent {

  readonly fullNamePipe = inject(FullNamePipe);
  readonly translatePipe = inject(TranslatePipe);
  readonly authFacade = inject(AuthFacade);

  @Input()
  process: EbenProcessInstance;
  @Input()
  withId = false;

  public user: string;
  private readonly assignedToMe = 'AVATAR.MYSELF';

  ngOnInit() {
    let user = this.process.startedBy;
    if (!user) {
      const userExtIdVariable: RestVariable = this.process.variables.find((variable: RestVariable) => variable.name === "initiatorExtId");
      const userFullnameVariable: RestVariable = this.process.variables.find((variable: RestVariable) => variable.name === "initiatorFullname");
      user = {
        externalId: userExtIdVariable ? userExtIdVariable.value : "",
        firstName: userFullnameVariable ? userFullnameVariable.value : ""
      } as LightUserRepresentation;
    }
    this.user = (this.authFacade.getUser().externalId == user.externalId)
      ? this.translatePipe.transform(this.assignedToMe)
      : this.fullNamePipe.transform(user);
  }
}

/*!
 * @license
 * Copyright 2019 Alfresco Software, Ltd.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { inject, Injectable } from '@angular/core';
import { AlfrescoApi, AlfrescoApiConfig, Node } from '@alfresco/js-api';

import { ReplaySubject, Subject } from 'rxjs';
import { AppConfigService, AppConfigValues, OauthConfigModel, StorageService } from '@alfresco/adf-core';
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogSessionErrorComponent } from "../dialog-session-error/dialog-session-error.component";
import { FlowAlfrescoApi } from "./flow-alfresco-api";

@Injectable({
  providedIn: 'root',
})
export class FlowAlfrescoApiService {

  readonly dialog = inject(MatDialog);

  /**
   * Publish/subscribe to events related to node updates.
   */
  nodeUpdated = new Subject<Node>();

  alfrescoApiInitialized: ReplaySubject<boolean> = new ReplaySubject(1);
  lastConfig: AlfrescoApiConfig;
  protected alfrescoApi: AlfrescoApi;
  private excludedErrorUrl: string[] = ['api/enterprise/system/properties'];

  private dialogRef: MatDialogRef<DialogSessionErrorComponent>;

  constructor(protected appConfig: AppConfigService, protected storageService: StorageService) {
  }

  getInstance(): AlfrescoApi {
    return this.alfrescoApi;
  }

  async load() {
    await this.appConfig.load().then(() => {
      this.storageService.prefix = this.appConfig.get<string>(AppConfigValues.STORAGE_PREFIX, '');
      this.initAlfrescoApi();
      this.alfrescoApiInitialized.next(true);
    });
  }

  reset() {
    this.initAlfrescoApi();
  }

  isDifferentConfig(lastConfig: AlfrescoApiConfig, newConfig: AlfrescoApiConfig) {
    return JSON.stringify(lastConfig) !== JSON.stringify(newConfig);
  }

  isExcludedErrorListener(currentFullPath: string): boolean {
    const formattedPath = currentFullPath.replace(this.lastConfig.hostBpm + '/' + this.lastConfig.contextRootBpm, '');
    return this.excludedErrorUrl.includes(formattedPath);
  }

  protected initAlfrescoApi() {
    const oauth: OauthConfigModel = Object.assign(
      {},
      this.appConfig.get<OauthConfigModel>(AppConfigValues.OAUTHCONFIG, null)
    );
    if (oauth) {
      oauth.redirectUri = window.location.origin + window.location.pathname;
      oauth.redirectUriLogout = window.location.origin + window.location.pathname;
    }

    const config = new AlfrescoApiConfig({
      provider: this.appConfig.get<string>(AppConfigValues.PROVIDERS),
      hostEcm: this.appConfig.get<string>(AppConfigValues.ECMHOST),
      hostBpm: this.appConfig.get<string>(AppConfigValues.BPMHOST),
      authType: this.appConfig.get<string>(AppConfigValues.AUTHTYPE, 'BASIC'),
      contextRootBpm: this.appConfig.get<string>(AppConfigValues.CONTEXTROOTBPM),
      contextRoot: this.appConfig.get<string>(AppConfigValues.CONTEXTROOTECM),
      disableCsrf: this.appConfig.get<boolean>(AppConfigValues.DISABLECSRF),
      withCredentials: this.appConfig.get<boolean>(AppConfigValues.AUTH_WITH_CREDENTIALS, false),
      domainPrefix: this.appConfig.get<string>(AppConfigValues.STORAGE_PREFIX),
      oauth2: oauth,
    });

    if (this.alfrescoApi && this.isDifferentConfig(this.lastConfig, config)) {
      this.lastConfig = config;
      this.alfrescoApi.setConfig(config);
    } else {
      this.lastConfig = config;
      this.alfrescoApi = new FlowAlfrescoApi(config);
      this.alfrescoApi.oauth2Auth.on('session_error', () => this.handleSessionErrorEvent());
      this.alfrescoApi.oauth2Auth.on('ticket_exchanged', () => this.handleTickedExchangedEvent());
    }
  }

  private handleSessionErrorEvent() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(DialogSessionErrorComponent, {
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: false,
      });
    }
  }

  private handleTickedExchangedEvent() {
    if (this.dialogRef)
      this.dialogRef.close();
      this.dialogRef = null;
  }
}
